<template>
	<!-- <div class="body_functionAdd" v-loading="loading"> -->
	<div class="body_functionAdd">
		<div v-loading="loading">
			<div class="child-top">
				<div class="top_title">
					<span></span>
					基本信息
				</div>
				<div class="input-from">
					<div class="operation-button">
						<el-button class="export_btn" round @click="exportSave" v-if="$route.query.name !== 'look'">
							<span>保存</span>
						</el-button>
						<!-- <el-button class="down_btn" round v-if="$route.query.name !== 'look'">
              <i class="iconfont icon-xiazai-icon"
                style="color:#4BB3FF;"></i>
              <span>下载模板</span>
            </el-button>-->
						<!-- <el-button  class="brotherBtns importFile load room" round style="background: #35C8CB;" v-if="$route.query.name !== 'look'">
              <div class="fileInp">
                <input type="file">
              </div>
              <i class="iconfont icon-daoru-icon"
                style="color:#F5A623;"></i>
              <span>导入</span>
            </el-button>-->
						<span
							class="fanhui"
							@click="
								() => {
									$router.push({ path: '/textItem/bodyFunction', query: { type: 1, page: $route.query.page } })
								}
							"
						>
							<i class="iconfont iconfanhui"></i>返回
						</span>
					</div>
				</div>
			</div>
			<div class="body_functionAdd_top bottom_btn">
				<el-form v-if="$route.query.name !== 'look'" :model="ruleForm" :rules="rules" ref="ruleForm" label-width="70px" class="demo_ruleForm" :label-position="'left'" :inline-message="false">
					<div class="item_flex">
						<el-form-item label="标题" prop="title">
							<el-input v-model="ruleForm.title" class placeholder="请输入标题" :disabled="$route.query.name == 'look'"></el-input>
						</el-form-item>
						<el-form-item label="测试日期" prop="date">
							<el-date-picker
								v-model="ruleForm.date"
								value-format="yyyy-MM-dd"
								type="date"
								:disabled="$route.query.name == 'look'"
								placeholder="请选择日期"
								style="width: 100%"
							></el-date-picker>
						</el-form-item>
					</div>
					<el-form-item label="运动队" prop="department_uuid">
						<el-cascader
							clearable
							style="width: 100%"
							v-model="ruleForm.department_uuid"
							placeholder="请选择所属运动队"
							@change="getSportsMan(true)"
							:show-all-levels="false"
							:options="$store.state.treeMenu"
							:disabled="$route.query.name == 'look'"
							:props="{
								label: 'name',
								value: 'uuid',
								emitPath: false,
							}"
						></el-cascader>
					</el-form-item>
					<el-form-item label="运动员" prop="sportsmanId">
						<el-select v-model="ruleForm.sportsmanId" filterable multiple placeholder="请选择运动员" style="width: 100%" @change="sportsmanChange" :disabled="$route.query.name == 'look'">
							<el-option v-for="item in sportsmanList" :key="item.uuid" :label="item.name" :value="item.uuid"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="测试指标" prop="textIndex">
						<!-- <el-input v-model="ruleForm.textIndex" placeholder="请选择测试指标" @focus="selectTextIndex"></el-input> -->
						<div class="text_demo" @click="selectTextIndex" placeholder="请选择测试指标">
							<p v-if="!demoSelectData.length">{{ $route.query.name !== 'look' ? '请选择测试指标' : '' }}</p>
							<span v-else v-for="(item, index) in demoSelectData" :key="index">
								{{ item.name }}
								<i class="el-icon-close" v-show="$route.query.name !== 'look'" @click.stop="demoCancel(item.uuid, index)"></i>
							</span>
						</div>
					</el-form-item>
					<div class="item_flex">
						<el-form-item label="教练" prop="coach">
							<el-input v-model="ruleForm.coach" :placeholder="$route.query.name !== 'look' ? '请输入教练' : ''" :disabled="$route.query.name == 'look'"></el-input>
						</el-form-item>
						<el-form-item label="测试地址" prop="address">
							<el-select
								v-model="ruleForm.address"
								clearable
								:placeholder="$route.query.name !== 'look' ? '请选择测试地址' : ''"
								style="width: 80%"
								:disabled="$route.query.name == 'look'"
							>
								<el-option v-for="item in addressList" :key="item.uuid" :label="item.address" :value="item.uuid">
									<span style="float: left">{{ item.address }}</span>
									<span style="float: right" class="address_del">
										<i class="el-icon-circle-close" @click="address_del(item.uuid)"></i>
									</span>
								</el-option>
							</el-select>
							<el-button type="text" style="margin-left: 8%; color: #0055e9" @click="newAddress" v-show="$route.query.name !== 'look'">新增地址</el-button>
						</el-form-item>
					</div>
					<el-form-item label="备注" prop="demo">
						<el-input
							v-model="ruleForm.demo"
							:placeholder="$route.query.name !== 'look' ? '备注：可填写注意事项、特殊要求、修改内容等说明信息' : ''"
							:disabled="$route.query.name == 'look'"
						></el-input>
					</el-form-item>
				</el-form>

				<el-form v-else :model="ruleForm" :rules="rules" ref="ruleForm" label-width="70px" class="demo_ruleForm" :label-position="'left'" :inline-message="false">
					<div class="item_flex">
						<el-form-item label="标题" prop="title">
							<p class="look_opp">{{ ruleForm.title }}</p>
						</el-form-item>
						<el-form-item label="测试日期" prop="date">
							<p class="look_opp">{{ ruleForm.date }}</p>
						</el-form-item>
					</div>
					<el-form-item label="运动队" prop="department_uuid">
						<p class="look_opp">{{ ruleForm.department_name }}</p>
					</el-form-item>
					<el-form-item label="运动员" prop="sportsmanId">
						<p class="look_opp">{{ ruleForm.staff_name_str }}</p>
					</el-form-item>
					<el-form-item label="测试指标" prop="textIndex">
						<p class="look_opp">
							<span v-for="(value, index) in demoSelectData" :key="index">{{ index !== 0 ? '，' : '' }}{{ value.name }}</span>
						</p>
					</el-form-item>
					<div class="item_flex">
						<el-form-item label="教练" prop="coach">
							<p class="look_opp">{{ ruleForm.coach }}</p>
						</el-form-item>
						<el-form-item label="测试地址" prop="address">
							<p class="look_opp">{{ ruleForm.address_name }}</p>
						</el-form-item>
					</div>
					<el-form-item label="备注" prop="demo">
						<p class="look_opp">{{ ruleForm.demo }}</p>
					</el-form-item>
				</el-form>
			</div>
			<el-tabs v-model="activeName" @tab-click="handClick" class="text_cname">
				<el-tab-pane label="FMS" name="FMS" key="FMS" v-if="selectTextName.indexOf('FMS') !== -1">
					<div class="FMS_content" v-if="selectTextName.indexOf('FMS') !== -1">
						<!-- <div class="top_title">
              <span></span>
              FMS
            </div> -->
						<el-table :data="FMS_tableData" style="width: 100%" class="FMS_tableData" :span-method="spanMethod">
							<el-table-column label="序号" type="index" width="45" align="center" fixed>
								<template slot-scope="scope">{{ scope.$index + 1 }}</template>
							</el-table-column>
							<el-table-column prop="staff_name" label="运动员" align="center" width="60" fixed></el-table-column>
							<el-table-column prop="sex" label="性别" width="45" align="center" fixed>
								<template slot-scope="scope">{{ scope.row.sex == '1' ? '男' : '女' }}</template>
							</el-table-column>
							<el-table-column label="深蹲" align="center">
								<el-table-column label="/" width="52" align="center">
									<template slot-scope="scope">
										<span v-if="$route.query.name == 'look'">{{ scope.row.norms['深蹲'].json_value.leftValue }}</span>
										<el-input
											v-else
											v-model="scope.row.norms['深蹲'].json_value.leftValue"
											@input="ruleValueChange('深蹲', scope.$index, 'FMS_tableData', 'leftValue')"
											@blur="FMSValueChange('深蹲', scope.$index, 'FMS_tableData')"
											:disabled="scope.row.staff_name == '男子-团队平均值' || scope.row.staff_name == '女子-团队平均值' || $route.query.name == 'look'"
										></el-input>
									</template>
								</el-table-column>
								<el-table-column label="最终" width="52" align="center">
									<template slot-scope="scope">
										<!-- <span
                      v-if="$route.query.name == 'look'"
                    >{{ scope.row.norms['深蹲'].json_value.overValue }}</span> -->
										<el-input v-model="scope.row.norms['深蹲'].json_value.overValue" disabled></el-input>
									</template>
								</el-table-column>
							</el-table-column>
							<el-table-column label="跨栏步" align="center">
								<el-table-column label="右" width="62" align="center">
									<template slot-scope="scope">
										<span v-if="$route.query.name == 'look'">{{ scope.row.norms['跨栏步'].json_value.rightValue }}</span>
										<el-input
											v-else
											v-model="scope.row.norms['跨栏步'].json_value.rightValue"
											@input="ruleValueChange('跨栏步', scope.$index, 'FMS_tableData', 'rightValue')"
											@blur="FMSValueChange('跨栏步', scope.$index, 'FMS_tableData')"
											:disabled="scope.row.staff_name == '男子-团队平均值' || scope.row.staff_name == '女子-团队平均值' || $route.query.name == 'look'"
										></el-input>
									</template>
								</el-table-column>
								<el-table-column label="左" width="62" align="center">
									<template slot-scope="scope">
										<span v-if="$route.query.name == 'look'">{{ scope.row.norms['跨栏步'].json_value.leftValue }}</span>
										<el-input
											v-else
											v-model="scope.row.norms['跨栏步'].json_value.leftValue"
											@input="ruleValueChange('跨栏步', scope.$index, 'FMS_tableData', 'leftValue')"
											@blur="FMSValueChange('跨栏步', scope.$index, 'FMS_tableData')"
											:disabled="scope.row.staff_name == '男子-团队平均值' || scope.row.staff_name == '女子-团队平均值' || $route.query.name == 'look'"
										></el-input>
									</template>
								</el-table-column>
								<el-table-column label="最终" width="62" align="center">
									<template slot-scope="scope">
										<!-- <span
                      v-if="$route.query.name == 'look'"
                    >{{ scope.row.norms['跨栏步'].json_value.overValue }}</span> -->
										<el-input v-model="scope.row.norms['跨栏步'].json_value.overValue" disabled></el-input>
									</template>
								</el-table-column>
							</el-table-column>
							<el-table-column label="直线弓步蹲" align="center">
								<el-table-column label="右" width="62" align="center">
									<template slot-scope="scope">
										<span v-if="$route.query.name == 'look'">{{ scope.row.norms['直线弓步蹲'].json_value.rightValue }}</span>
										<el-input
											v-else
											v-model="scope.row.norms['直线弓步蹲'].json_value.rightValue"
											@input="ruleValueChange('直线弓步蹲', scope.$index, 'FMS_tableData', 'rightValue')"
											@blur="FMSValueChange('直线弓步蹲', scope.$index, 'FMS_tableData')"
											:disabled="scope.row.staff_name == '男子-团队平均值' || scope.row.staff_name == '女子-团队平均值' || $route.query.name == 'look'"
										></el-input>
									</template>
								</el-table-column>
								<el-table-column label="左" width="62" align="center">
									<template slot-scope="scope">
										<span v-if="$route.query.name == 'look'">{{ scope.row.norms['直线弓步蹲'].json_value.leftValue }}</span>
										<el-input
											v-else
											v-model="scope.row.norms['直线弓步蹲'].json_value.leftValue"
											@input="ruleValueChange('直线弓步蹲', scope.$index, 'FMS_tableData', 'leftValue')"
											@blur="FMSValueChange('直线弓步蹲', scope.$index, 'FMS_tableData')"
											:disabled="scope.row.staff_name == '男子-团队平均值' || scope.row.staff_name == '女子-团队平均值' || $route.query.name == 'look'"
										></el-input>
									</template>
								</el-table-column>

								<el-table-column label="踝关节排除测试-疼痛（右+/-）" width="110" align="center">
									<template slot-scope="scope">
										<span v-if="$route.query.name == 'look'">{{ scope.row.norms['直线弓步蹲'].json_value.rightPainValue }}</span>
										<el-input
											v-else
											v-model="scope.row.norms['直线弓步蹲'].json_value.rightPainValue"
											@input="ruleValueChange('直线弓步蹲', scope.$index, 'FMS_tableData', 'rightPainValue')"
											@blur="FMSValueChange('直线弓步蹲', scope.$index, 'FMS_tableData')"
											:disabled="scope.row.staff_name == '男子-团队平均值' || scope.row.staff_name == '女子-团队平均值' || $route.query.name == 'look'"
										></el-input>
									</template>
								</el-table-column>

								<el-table-column label="踝关节排除测试-疼痛（左+/-）" width="110" align="center">
									<template slot-scope="scope">
										<span v-if="$route.query.name == 'look'">{{ scope.row.norms['直线弓步蹲'].json_value.leftPainValue }}</span>
										<el-input
											v-else
											v-model="scope.row.norms['直线弓步蹲'].json_value.leftPainValue"
											@input="ruleValueChange('直线弓步蹲', scope.$index, 'FMS_tableData', 'leftPainValue')"
											@blur="FMSValueChange('直线弓步蹲', scope.$index, 'FMS_tableData')"
											:disabled="scope.row.staff_name == '男子-团队平均值' || scope.row.staff_name == '女子-团队平均值' || $route.query.name == 'look'"
										></el-input>
									</template>
								</el-table-column>

								<el-table-column label="最终" width="62" align="center">
									<template slot-scope="scope">
										<!-- <span
                      v-if="$route.query.name == 'look'"
                    >{{ scope.row.norms['直线弓步蹲'].json_value.overValue }}</span> -->
										<el-input v-model="scope.row.norms['直线弓步蹲'].json_value.overValue" disabled></el-input>
									</template>
								</el-table-column>
							</el-table-column>

							<el-table-column label="踝关节排除测试-灵活型" align="center">
								<el-table-column label="右" width="62" align="center">
									<template slot-scope="scope">
										<span v-if="$route.query.name == 'look'">{{ scope.row.norms['踝关节排除测试-灵活型'].json_value.rightValue }}</span>
										<el-input
											v-else
											v-model="scope.row.norms['踝关节排除测试-灵活型'].json_value.rightValue"
											@input="ruleValueChange('踝关节排除测试-灵活型', scope.$index, 'FMS_tableData', 'rightValue')"
											@blur="FMSValueChange('踝关节排除测试-灵活型', scope.$index, 'FMS_tableData')"
											:disabled="scope.row.staff_name == '男子-团队平均值' || scope.row.staff_name == '女子-团队平均值' || $route.query.name == 'look'"
										></el-input>
									</template>
								</el-table-column>
								<el-table-column label="左" width="62" align="center">
									<template slot-scope="scope">
										<span v-if="$route.query.name == 'look'">{{ scope.row.norms['踝关节排除测试-灵活型'].json_value.leftValue }}</span>
										<el-input
											v-else
											v-model="scope.row.norms['踝关节排除测试-灵活型'].json_value.leftValue"
											@input="ruleValueChange('踝关节排除测试-灵活型', scope.$index, 'FMS_tableData', 'leftValue')"
											@blur="FMSValueChange('踝关节排除测试-灵活型', scope.$index, 'FMS_tableData')"
											:disabled="scope.row.staff_name == '男子-团队平均值' || scope.row.staff_name == '女子-团队平均值' || $route.query.name == 'look'"
										></el-input>
									</template>
								</el-table-column>
								<el-table-column label="最终" width="62" align="center">
									<template slot-scope="scope">
										<!-- <span
                      v-if="$route.query.name == 'look'"
                    >{{ scope.row.norms['踝关节排除测试-灵活型'].json_value.overValue }}</span> -->
										<el-input v-model="scope.row.norms['踝关节排除测试-灵活型'].json_value.overValue" disabled></el-input>
									</template>
								</el-table-column>
							</el-table-column>

							<el-table-column label="肩部灵活性" align="center">
								<el-table-column label="右" width="62" align="center">
									<template slot-scope="scope">
										<span v-if="$route.query.name == 'look'">{{ scope.row.norms['肩部灵活性'].json_value.rightValue }}</span>
										<el-input
											v-else
											v-model="scope.row.norms['肩部灵活性'].json_value.rightValue"
											@input="ruleValueChange('肩部灵活性', scope.$index, 'FMS_tableData', 'rightValue')"
											@blur="FMSValueChange('肩部灵活性', scope.$index, 'FMS_tableData')"
											:disabled="scope.row.staff_name == '男子-团队平均值' || scope.row.staff_name == '女子-团队平均值' || $route.query.name == 'look'"
										></el-input>
									</template>
								</el-table-column>
								<el-table-column label="左" width="62" align="center">
									<template slot-scope="scope">
										<span v-if="$route.query.name == 'look'">{{ scope.row.norms['肩部灵活性'].json_value.leftValue }}</span>
										<el-input
											v-else
											v-model="scope.row.norms['肩部灵活性'].json_value.leftValue"
											@input="ruleValueChange('肩部灵活性', scope.$index, 'FMS_tableData', 'leftValue')"
											@blur="FMSValueChange('肩部灵活性', scope.$index, 'FMS_tableData')"
											:disabled="scope.row.staff_name == '男子-团队平均值' || scope.row.staff_name == '女子-团队平均值' || $route.query.name == 'look'"
										></el-input>
									</template>
								</el-table-column>

								<el-table-column label="肩部排除测试（右+/-）" width="110" align="center">
									<template slot-scope="scope">
										<span v-if="$route.query.name == 'look'">{{ scope.row.norms['肩部灵活性'].json_value.rightPainValue }}</span>
										<el-input
											v-else
											v-model="scope.row.norms['肩部灵活性'].json_value.rightPainValue"
											@input="ruleValueChange('肩部灵活性', scope.$index, 'FMS_tableData', 'rightPainValue')"
											@blur="FMSValueChange('肩部灵活性', scope.$index, 'FMS_tableData')"
											:disabled="scope.row.staff_name == '男子-团队平均值' || scope.row.staff_name == '女子-团队平均值' || $route.query.name == 'look'"
										></el-input>
									</template>
								</el-table-column>
								<el-table-column label="肩部排除测试（左+/-）" width="110" align="center">
									<template slot-scope="scope">
										<span v-if="$route.query.name == 'look'">{{ scope.row.norms['肩部灵活性'].json_value.leftPainValue }}</span>
										<el-input
											v-else
											v-model="scope.row.norms['肩部灵活性'].json_value.leftPainValue"
											@input="ruleValueChange('肩部灵活性', scope.$index, 'FMS_tableData', 'leftPainValue')"
											@blur="FMSValueChange('肩部灵活性', scope.$index, 'FMS_tableData')"
											:disabled="scope.row.staff_name == '男子-团队平均值' || scope.row.staff_name == '女子-团队平均值' || $route.query.name == 'look'"
										></el-input>
									</template>
								</el-table-column>

								<el-table-column label="最终" width="62" align="center">
									<template slot-scope="scope">
										<!-- <span
                      v-if="$route.query.name == 'look'"
                    >{{ scope.row.norms['肩部灵活性'].json_value.overValue }}</span> -->
										<el-input v-model="scope.row.norms['肩部灵活性'].json_value.overValue" disabled></el-input>
									</template>
								</el-table-column>
							</el-table-column>
							<el-table-column label="主动直腿上抬" align="center">
								<el-table-column label="右" width="62" align="center">
									<template slot-scope="scope">
										<span v-if="$route.query.name == 'look'">{{ scope.row.norms['主动直腿上抬'].json_value.rightValue }}</span>
										<el-input
											v-else
											v-model="scope.row.norms['主动直腿上抬'].json_value.rightValue"
											@input="ruleValueChange('主动直腿上抬', scope.$index, 'FMS_tableData', 'rightValue')"
											@blur="FMSValueChange('主动直腿上抬', scope.$index, 'FMS_tableData')"
											:disabled="scope.row.staff_name == '男子-团队平均值' || scope.row.staff_name == '女子-团队平均值' || $route.query.name == 'look'"
										></el-input>
									</template>
								</el-table-column>
								<el-table-column label="左" width="62" align="center">
									<template slot-scope="scope">
										<span v-if="$route.query.name == 'look'">{{ scope.row.norms['主动直腿上抬'].json_value.leftValue }}</span>
										<el-input
											v-else
											v-model="scope.row.norms['主动直腿上抬'].json_value.leftValue"
											@input="ruleValueChange('主动直腿上抬', scope.$index, 'FMS_tableData', 'leftValue')"
											@blur="FMSValueChange('主动直腿上抬', scope.$index, 'FMS_tableData')"
											:disabled="scope.row.staff_name == '男子-团队平均值' || scope.row.staff_name == '女子-团队平均值' || $route.query.name == 'look'"
										></el-input>
									</template>
								</el-table-column>
								<el-table-column label="最终" width="62" align="center">
									<template slot-scope="scope">
										<!-- <span
                      v-if="$route.query.name == 'look'"
                    >{{ scope.row.norms['主动直腿上抬'].json_value.overValue }}</span> -->
										<el-input v-model="scope.row.norms['主动直腿上抬'].json_value.overValue" disabled></el-input>
									</template>
								</el-table-column>
							</el-table-column>
							<el-table-column label="躯干稳定俯卧撑" align="center">
								<el-table-column label="/" width="62" align="center">
									<template slot-scope="scope">
										<span v-if="$route.query.name == 'look'">{{ scope.row.norms['躯干稳定俯卧撑'].json_value.leftValue }}</span>
										<el-input
											v-else
											v-model="scope.row.norms['躯干稳定俯卧撑'].json_value.leftValue"
											@input="ruleValueChange('躯干稳定俯卧撑', scope.$index, 'FMS_tableData', 'leftValue')"
											@blur="FMSValueChange('躯干稳定俯卧撑', scope.$index, 'FMS_tableData')"
											:disabled="scope.row.staff_name == '男子-团队平均值' || scope.row.staff_name == '女子-团队平均值' || $route.query.name == 'look'"
										></el-input>
									</template>
								</el-table-column>
								<el-table-column label="伸展排除测试（+/-）" width="110" align="center">
									<template slot-scope="scope">
										<span v-if="$route.query.name == 'look'">{{ scope.row.norms['躯干稳定俯卧撑'].json_value.leftPainValue }}</span>
										<el-input
											v-else
											v-model="scope.row.norms['躯干稳定俯卧撑'].json_value.leftPainValue"
											@input="ruleValueChange('躯干稳定俯卧撑', scope.$index, 'FMS_tableData', 'leftPainValue')"
											@blur="FMSValueChange('躯干稳定俯卧撑', scope.$index, 'FMS_tableData')"
											:disabled="scope.row.staff_name == '男子-团队平均值' || scope.row.staff_name == '女子-团队平均值' || $route.query.name == 'look'"
										></el-input>
									</template>
								</el-table-column>
								<el-table-column label="最终" width="62" align="center">
									<template slot-scope="scope">
										<!-- <span
                      v-if="$route.query.name == 'look'"
                    >{{ scope.row.norms['躯干稳定俯卧撑'].json_value.overValue }}</span> -->
										<el-input v-model="scope.row.norms['躯干稳定俯卧撑'].json_value.overValue" disabled></el-input>
									</template>
								</el-table-column>
							</el-table-column>
							<el-table-column label="旋转稳定性" align="center">
								<el-table-column label="右" width="62" align="center">
									<template slot-scope="scope">
										<span v-if="$route.query.name == 'look'">{{ scope.row.norms['旋转稳定性'].json_value.rightValue }}</span>
										<el-input
											v-else
											v-model="scope.row.norms['旋转稳定性'].json_value.rightValue"
											@input="ruleValueChange('旋转稳定性', scope.$index, 'FMS_tableData', 'rightValue')"
											@blur="FMSValueChange('旋转稳定性', scope.$index, 'FMS_tableData')"
											:disabled="scope.row.staff_name == '男子-团队平均值' || scope.row.staff_name == '女子-团队平均值' || $route.query.name == 'look'"
										></el-input>
									</template>
								</el-table-column>
								<el-table-column label="左" width="62" align="center">
									<template slot-scope="scope">
										<span v-if="$route.query.name == 'look'">{{ scope.row.norms['旋转稳定性'].json_value.leftValue }}</span>
										<el-input
											v-else
											v-model="scope.row.norms['旋转稳定性'].json_value.leftValue"
											@input="ruleValueChange('旋转稳定性', scope.$index, 'FMS_tableData', 'leftValue')"
											@blur="FMSValueChange('旋转稳定性', scope.$index, 'FMS_tableData')"
											:disabled="scope.row.staff_name == '男子-团队平均值' || scope.row.staff_name == '女子-团队平均值' || $route.query.name == 'look'"
										></el-input>
									</template>
								</el-table-column>
								<el-table-column label="屈曲排除测试（+/-）" width="110" align="center">
									<template slot-scope="scope">
										<span v-if="$route.query.name == 'look'">{{ scope.row.norms['旋转稳定性'].json_value.rightPainValue }}</span>
										<el-input
											v-else
											v-model="scope.row.norms['旋转稳定性'].json_value.rightPainValue"
											@input="ruleValueChange('旋转稳定性', scope.$index, 'FMS_tableData', 'rightPainValue')"
											@blur="FMSValueChange('旋转稳定性', scope.$index, 'FMS_tableData')"
											:disabled="scope.row.staff_name == '男子-团队平均值' || scope.row.staff_name == '女子-团队平均值' || $route.query.name == 'look'"
										></el-input>
									</template>
								</el-table-column>
								<el-table-column label="最终" width="62" align="center">
									<template slot-scope="scope">
										<!-- <span
                      v-if="$route.query.name == 'look'"
                    >{{ scope.row.norms['旋转稳定性'].json_value.overValue }}</span> -->
										<el-input v-model="scope.row.norms['旋转稳定性'].json_value.overValue" disabled></el-input>
									</template>
								</el-table-column>
							</el-table-column>
							<el-table-column label="总评分" width="62" align="center">
								<template slot-scope="scope">
									<span v-if="$route.query.name == 'look'">{{ scope.row.total_value }}</span>
									<el-input v-else v-model="scope.row.total_value" disabled></el-input>
								</template>
							</el-table-column>
						</el-table>
						<div class="FMS_echarts">
							<div class="f_e_left">
								<div class="e_left_sele">
									<el-select v-model="FMS_select" placeholder="请选择指标" clearable @change="FMS_select_change()">
										<el-option key="0010" label="总分" value></el-option>
										<el-option v-for="item in FMS_select_options" :key="item.uuid" :label="item.name" :value="item.name"></el-option>
									</el-select>
									<el-select v-model="FMS_sex" placeholder="请选择性别" clearable @change="FMS_select_change()">
										<el-option key="1" label="男" value="1" v-if="oldman == '1' || oldman == '3'"></el-option>
										<el-option key="2" label="女" value="2" v-if="oldman == '2' || oldman == '3'"></el-option>
									</el-select>
								</div>
								<div class="fms_e_line" id="fmsline"></div>
							</div>
							<div class="f_ech_right">
								<div class="fms_e_line" id="fmsPie"></div>
							</div>
						</div>
					</div>
				</el-tab-pane>
				<el-tab-pane label="MCS" name="MCS" key="MCS" v-if="selectTextName.indexOf('MCS') !== -1">
					<div class="MCS_content" v-if="selectTextName.indexOf('MCS') !== -1">
						<!-- <div class="top_title">
              <span></span>
              MCS
            </div> -->
						<el-table :data="MCS_tableData" class="YBT_tableData" header-row-class-name="YBT_headerName" :span-method="YBTSpanMethod" border style="width: 100%">
							<el-table-column label="序号" type="index" width="45" align="center">
								<template slot-scope="scope">{{ scope.$index + 1 }}</template>
							</el-table-column>
							<el-table-column prop="staff_name" label="运动员" align="center" width="60"></el-table-column>
							<el-table-column prop="sex" label="性别" align="center" width="45">
								<template slot-scope="scope">{{ scope.row.sex == '1' ? '男' : '女' }}</template>
							</el-table-column>
							<el-table-column prop="leftFront" label="足长cm" align="center" :key="Math.random()">
								<template slot-scope="scope">
									<span v-if="$route.query.name == 'look'">{{ scope.row.norms['足长'].norm_value }}</span>
									<el-input
										v-else
										v-model="scope.row.norms['足长'].norm_value"
										@input="ruleValueChange('足长', scope.$index, 'MCS_tableData', 'norm_value')"
										@blur="MCSMean('MCS_tableData')"
										:disabled="scope.row.staff_name == '男子-团队平均值' || scope.row.staff_name == '女子-团队平均值' || $route.query.name == 'look'"
									></el-input>
								</template>
							</el-table-column>
							<el-table-column prop="leftBack" label="单腿前伸测试（左）cm" align="center" :key="Math.random()">
								<template slot-scope="scope">
									<span
										v-if="$route.query.name == 'look'"
										:class="
											scope.row.staff_name == '男子-团队平均值' || scope.row.staff_name == '女子-团队平均值'
												? ''
												: (scope.row.norms['单腿前伸测试（左）'].norm_value - scope.row.norms['单腿前伸测试（右）'].norm_value > 4 ||
														scope.row.norms['单腿前伸测试（右）'].norm_value - scope.row.norms['单腿前伸测试（左）'].norm_value > 4) &&
												  scope.row.norms['单腿前伸测试（左）'].norm_value < scope.row.norms['足长'].norm_value * 2
												? 's_doubleDiffActive s_brotherDiffActive'
												: scope.row.norms['单腿前伸测试（左）'].norm_value - scope.row.norms['单腿前伸测试（右）'].norm_value > 4 ||
												  scope.row.norms['单腿前伸测试（右）'].norm_value - scope.row.norms['单腿前伸测试（左）'].norm_value > 4
												? 's_brotherDiffActive'
												: scope.row.norms['单腿前伸测试（左）'].norm_value < scope.row.norms['足长'].norm_value * 2
												? 's_doubleDiffActive'
												: ''
										"
										>{{ scope.row.norms['单腿前伸测试（左）'].norm_value }}</span
									>
									<!-- :class="doubleDiffActive(scope.row.norms['足长'].norm_value, scope.row.norms['单腿前伸测试（左）'].norm_value, scope.row.norms['单腿前伸测试（右）'].norm_value)" -->
									<el-input
										v-else
										:class="
											scope.row.staff_name == '男子-团队平均值' || scope.row.staff_name == '女子-团队平均值'
												? ''
												: (scope.row.norms['单腿前伸测试（左）'].norm_value - scope.row.norms['单腿前伸测试（右）'].norm_value > 4 ||
														scope.row.norms['单腿前伸测试（右）'].norm_value - scope.row.norms['单腿前伸测试（左）'].norm_value > 4) &&
												  scope.row.norms['单腿前伸测试（左）'].norm_value < scope.row.norms['足长'].norm_value * 2
												? 'doubleDiffActive brotherDiffActive'
												: scope.row.norms['单腿前伸测试（左）'].norm_value - scope.row.norms['单腿前伸测试（右）'].norm_value > 4 ||
												  scope.row.norms['单腿前伸测试（右）'].norm_value - scope.row.norms['单腿前伸测试（左）'].norm_value > 4
												? 'brotherDiffActive'
												: scope.row.norms['单腿前伸测试（左）'].norm_value < scope.row.norms['足长'].norm_value * 2
												? 'doubleDiffActive'
												: ''
										"
										v-model="scope.row.norms['单腿前伸测试（左）'].norm_value"
										@input="ruleValueChange('单腿前伸测试（左）', scope.$index, 'MCS_tableData', 'norm_value')"
										@blur="MCSMean('MCS_tableData')"
										:disabled="scope.row.staff_name == '男子-团队平均值' || scope.row.staff_name == '女子-团队平均值' || $route.query.name == 'look'"
									></el-input>
								</template>
							</el-table-column>
							<el-table-column prop="rightFront" label="单腿前伸测试（右）cm" align="center" :key="Math.random()">
								<template slot-scope="scope" class="aaa">
									<span
										v-if="$route.query.name == 'look'"
										:class="
											scope.row.staff_name == '男子-团队平均值' || scope.row.staff_name == '女子-团队平均值'
												? ''
												: (scope.row.norms['单腿前伸测试（左）'].norm_value - scope.row.norms['单腿前伸测试（右）'].norm_value > 4 ||
														scope.row.norms['单腿前伸测试（右）'].norm_value - scope.row.norms['单腿前伸测试（左）'].norm_value > 4) &&
												  scope.row.norms['单腿前伸测试（右）'].norm_value < scope.row.norms['足长'].norm_value * 2
												? 's_doubleDiffActive s_brotherDiffActive'
												: scope.row.norms['单腿前伸测试（左）'].norm_value - scope.row.norms['单腿前伸测试（右）'].norm_value > 4 ||
												  scope.row.norms['单腿前伸测试（右）'].norm_value - scope.row.norms['单腿前伸测试（左）'].norm_value > 4
												? 's_brotherDiffActive'
												: scope.row.norms['单腿前伸测试（右）'].norm_value < scope.row.norms['足长'].norm_value * 2
												? 's_doubleDiffActive'
												: ''
										"
										>{{ scope.row.norms['单腿前伸测试（右）'].norm_value }}</span
									>
									<el-input
										v-else
										:class="
											scope.row.staff_name == '男子-团队平均值' || scope.row.staff_name == '女子-团队平均值'
												? ''
												: (scope.row.norms['单腿前伸测试（左）'].norm_value - scope.row.norms['单腿前伸测试（右）'].norm_value > 4 ||
														scope.row.norms['单腿前伸测试（右）'].norm_value - scope.row.norms['单腿前伸测试（左）'].norm_value > 4) &&
												  scope.row.norms['单腿前伸测试（右）'].norm_value < scope.row.norms['足长'].norm_value * 2
												? 'doubleDiffActive brotherDiffActive'
												: scope.row.norms['单腿前伸测试（左）'].norm_value - scope.row.norms['单腿前伸测试（右）'].norm_value > 4 ||
												  scope.row.norms['单腿前伸测试（右）'].norm_value - scope.row.norms['单腿前伸测试（左）'].norm_value > 4
												? 'brotherDiffActive'
												: scope.row.norms['单腿前伸测试（右）'].norm_value < scope.row.norms['足长'].norm_value * 2
												? 'doubleDiffActive'
												: ''
										"
										v-model="scope.row.norms['单腿前伸测试（右）'].norm_value"
										@input="ruleValueChange('单腿前伸测试（右）', scope.$index, 'MCS_tableData', 'norm_value')"
										@blur="MCSMean('MCS_tableData')"
										:disabled="scope.row.staff_name == '男子-团队平均值' || scope.row.staff_name == '女子-团队平均值' || $route.query.name == 'look'"
									></el-input>
								</template>
							</el-table-column>
							<el-table-column prop="rightBack" label="水平侧伸测试（左）cm" align="center" :key="Math.random()">
								<template slot-scope="scope">
									<span
										v-if="$route.query.name == 'look'"
										:class="
											scope.row.staff_name == '男子-团队平均值' || scope.row.staff_name == '女子-团队平均值'
												? ''
												: (scope.row.norms['水平侧伸测试（左）'].norm_value - scope.row.norms['水平侧伸测试（右）'].norm_value > 4 ||
														scope.row.norms['水平侧伸测试（右）'].norm_value - scope.row.norms['水平侧伸测试（左）'].norm_value > 4) &&
												  scope.row.norms['水平侧伸测试（左）'].norm_value < scope.row.norms['足长'].norm_value * 2
												? 's_doubleDiffActive s_brotherDiffActive'
												: scope.row.norms['水平侧伸测试（左）'].norm_value - scope.row.norms['水平侧伸测试（右）'].norm_value > 4 ||
												  scope.row.norms['水平侧伸测试（右）'].norm_value - scope.row.norms['水平侧伸测试（左）'].norm_value > 4
												? 's_brotherDiffActive'
												: scope.row.norms['水平侧伸测试（左）'].norm_value < scope.row.norms['足长'].norm_value * 2
												? 's_doubleDiffActive'
												: ''
										"
										>{{ scope.row.norms['水平侧伸测试（左）'].norm_value }}</span
									>
									<el-input
										v-else
										:class="
											scope.row.staff_name == '男子-团队平均值' || scope.row.staff_name == '女子-团队平均值'
												? ''
												: (scope.row.norms['水平侧伸测试（左）'].norm_value - scope.row.norms['水平侧伸测试（右）'].norm_value > 4 ||
														scope.row.norms['水平侧伸测试（右）'].norm_value - scope.row.norms['水平侧伸测试（左）'].norm_value > 4) &&
												  scope.row.norms['水平侧伸测试（左）'].norm_value < scope.row.norms['足长'].norm_value * 2
												? 'doubleDiffActive brotherDiffActive'
												: scope.row.norms['水平侧伸测试（左）'].norm_value - scope.row.norms['水平侧伸测试（右）'].norm_value > 4 ||
												  scope.row.norms['水平侧伸测试（右）'].norm_value - scope.row.norms['水平侧伸测试（左）'].norm_value > 4
												? 'brotherDiffActive'
												: scope.row.norms['水平侧伸测试（左）'].norm_value < scope.row.norms['足长'].norm_value * 2
												? 'doubleDiffActive'
												: ''
										"
										v-model="scope.row.norms['水平侧伸测试（左）'].norm_value"
										@input="ruleValueChange('水平侧伸测试（左）', scope.$index, 'MCS_tableData', 'norm_value')"
										@blur="MCSMean('MCS_tableData')"
										:disabled="scope.row.staff_name == '男子-团队平均值' || scope.row.staff_name == '女子-团队平均值' || $route.query.name == 'look'"
									></el-input>
								</template>
							</el-table-column>
							<el-table-column prop="rightAfter" label="水平侧伸测试（右）cm" align="center">
								<template slot-scope="scope">
									<span
										v-if="$route.query.name == 'look'"
										:class="
											scope.row.staff_name == '男子-团队平均值' || scope.row.staff_name == '女子-团队平均值'
												? ''
												: (scope.row.norms['水平侧伸测试（左）'].norm_value - scope.row.norms['水平侧伸测试（右）'].norm_value > 4 ||
														scope.row.norms['水平侧伸测试（右）'].norm_value - scope.row.norms['水平侧伸测试（左）'].norm_value > 4) &&
												  scope.row.norms['水平侧伸测试（右）'].norm_value < scope.row.norms['足长'].norm_value * 2
												? 's_doubleDiffActive s_brotherDiffActive'
												: scope.row.norms['水平侧伸测试（左）'].norm_value - scope.row.norms['水平侧伸测试（右）'].norm_value > 4 ||
												  scope.row.norms['水平侧伸测试（右）'].norm_value - scope.row.norms['水平侧伸测试（左）'].norm_value > 4
												? 's_brotherDiffActive'
												: scope.row.norms['水平侧伸测试（右）'].norm_value < scope.row.norms['足长'].norm_value * 2
												? 's_doubleDiffActive'
												: ''
										"
										>{{ scope.row.norms['水平侧伸测试（右）'].norm_value }}</span
									>
									<el-input
										v-else
										:class="
											scope.row.staff_name == '男子-团队平均值' || scope.row.staff_name == '女子-团队平均值'
												? ''
												: (scope.row.norms['水平侧伸测试（左）'].norm_value - scope.row.norms['水平侧伸测试（右）'].norm_value > 4 ||
														scope.row.norms['水平侧伸测试（右）'].norm_value - scope.row.norms['水平侧伸测试（左）'].norm_value > 4) &&
												  scope.row.norms['水平侧伸测试（右）'].norm_value < scope.row.norms['足长'].norm_value * 2
												? 'doubleDiffActive brotherDiffActive'
												: scope.row.norms['水平侧伸测试（左）'].norm_value - scope.row.norms['水平侧伸测试（右）'].norm_value > 4 ||
												  scope.row.norms['水平侧伸测试（右）'].norm_value - scope.row.norms['水平侧伸测试（左）'].norm_value > 4
												? 'brotherDiffActive'
												: scope.row.norms['水平侧伸测试（右）'].norm_value < scope.row.norms['足长'].norm_value * 2
												? 'doubleDiffActive'
												: ''
										"
										v-model="scope.row.norms['水平侧伸测试（右）'].norm_value"
										@input="ruleValueChange('水平侧伸测试（右）', scope.$index, 'MCS_tableData', 'norm_value')"
										@blur="MCSMean('MCS_tableData')"
										:disabled="scope.row.staff_name == '男子-团队平均值' || scope.row.staff_name == '女子-团队平均值' || $route.query.name == 'look'"
									></el-input>
								</template>
							</el-table-column>
						</el-table>
						<p class="notes">注：<span></span>未达目标：指标值＜足长的2倍 <span></span>两侧不对称：两侧差距＞4cm</p>
					</div>
				</el-tab-pane>

				<el-tab-pane label="YBT" name="YBT" key="YBT" v-if="selectTextName.indexOf('YBT') !== -1 && $route.query.name !== 'look'">
					<div class="RRX_content" v-if="YBTSelctName.indexOf('YBT下肢测试') !== -1">
						<div class="top_title">
							<span></span>
							YBT下肢测试
						</div>
						<el-table :data="YBT_tableDataDown" class="YBT_tableData" header-row-class-name="YBT_headerName" border style="width: 100%; margin-top: 20px">
							<el-table-column label="序号" type="index" width="45" align="center">
								<template slot-scope="scope">{{ scope.$index + 1 }}</template>
							</el-table-column>
							<el-table-column prop="staff_name" label="运动员" align="center" width="60"></el-table-column>
							<el-table-column prop="sex" label="性别" align="center" width="45">
								<template slot-scope="scope">{{ scope.row.sex == '1' ? '男' : '女' }}</template>
							</el-table-column>
							<el-table-column v-for="(item, index) in YBTObjectDown" :key="index + 'down'" :label="`${index}(cm)`" align="center">
								<template slot-scope="scope">
									<span v-if="$route.query.name == 'look'">{{ scope.row.norms[index].norm_value }}</span>
									<el-input
										v-else
										@input="ruleValueChange(index, scope.$index, 'YBT_tableDataDown', 'norm_value')"
										@blur="YBTDownMean(index, scope.$index, 'YBT_tableDataDown', 'norm_value')"
										v-model="scope.row.norms[index].norm_value"
										:disabled="$route.query.name == 'look'"
									></el-input>

									<!-- 
										进行规则校验
										失去焦点渲染echarts图

										@blur="MCSMean(scope.row + '_tableData', scope.row)" -->
								</template>
							</el-table-column>
						</el-table>

						<div class="YBT_echartsContent">
							<div v-for="(item, index) in YBT_tableDataDown" :key="'YBT_downEcharts' + index" :id="'YBT_downEcharts' + index"></div>
						</div>
					</div>

					<div class="RRX_content" v-if="YBTSelctName.indexOf('YBT上肢测试') !== -1">
						<div class="top_title">
							<span></span>
							YBT上肢测试
						</div>
						<el-table :data="YBT_tableDataUp" class="YBT_tableData" header-row-class-name="YBT_headerName" border style="width: 100%; margin-top: 20px">
							<el-table-column label="序号" type="index" width="45" align="center">
								<template slot-scope="scope">{{ scope.$index + 1 }}</template>
							</el-table-column>
							<el-table-column prop="staff_name" label="运动员" align="center" width="60"></el-table-column>
							<el-table-column prop="sex" label="性别" align="center" width="45">
								<template slot-scope="scope">{{ scope.row.sex == '1' ? '男' : '女' }}</template>
							</el-table-column>
							<el-table-column v-for="(item, index) in YBTObjectUp" :key="index + 'up'" :label="`${index}(cm)`" align="center">
								<template slot-scope="scope">
									<span v-if="$route.query.name == 'look'">{{ scope.row.norms[index].norm_value }}</span>
									<el-input
										v-else
										@input="ruleValueChange(index, scope.$index, 'YBT_tableDataUp', 'norm_value')"
										@blur="YBTUpMean(index, scope.$index, 'YBT_tableDataUp', 'norm_value')"
										v-model="scope.row.norms[index].norm_value"
										:disabled="$route.query.name == 'look'"
									></el-input>

									<!-- 
										进行规则校验
										失去焦点渲染echarts图

										@blur="MCSMean(scope.row + '_tableData', scope.row)" -->
								</template>
							</el-table-column>
						</el-table>
						<div class="YBT_echartsContent">
							<div v-for="(item, index) in YBT_tableDataUp" :key="'YBT_upEcharts' + index" :id="'YBT_upEcharts' + index"></div>
						</div>
					</div>
				</el-tab-pane>

				<el-tab-pane label="YBT" name="YBT" key="YBT" v-if="selectTextName.indexOf('YBT') !== -1 && $route.query.name == 'look'">
					<div class="RRX_content" v-if="YBTSelctName.indexOf('YBT下肢测试') !== -1">
						<div class="top_title">
							<span></span>
							YBT下肢测试
						</div>
						<div class="YBT_down_tips">
							<el-tooltip class="item" effect="dark" placement="left">
								<div slot="content">
									<p>1.对于前侧：差异<=4cm表示通过；差异>4cm表示不通过</p>
									<p>2.对于后内外侧、后外侧：差异<=6cm表示通过；差异>6cm表示不通过</p>
									<p>3.对于总分：总分>=95表示通过；总分<95表示不通过</p>
								</div>
								<i class="el-icon-info"></i>
							</el-tooltip>
							<span></span>通过 <span></span>不通过
						</div>
						<el-table
							:data="YBTObjectDownLook.table_data"
							class="YBT_tableData YBT_tableDataLook"
							header-row-class-name="YBT_headerName"
							:span-method="YBTDownMethod"
							border
							style="width: 100%; margin-top: 20px"
						>
							<el-table-column label="序号" type="index" width="45" align="center">
								<template slot-scope="scope">{{ scope.$index / 3 + 1 }}</template>
							</el-table-column>
							<el-table-column
								:prop="itm.prop"
								:label="itm.name"
								:key="'table_head' + idx"
								align="center"
								v-for="(itm, idx) in YBTObjectDownLook.table_head"
								:width="idx == 0 ? '60' : idx == 1 ? '45' : ''"
							>
								<template slot-scope="scope">
									<div
										class="YBTValueContent"
										v-if="itm.prop == 'qc' || itm.prop == 'hwc' || itm.prop == 'hnc' || itm.prop == 'score'"
										:style="{ background: scope.row[itm.prop].color }"
									>
										{{ scope.row[itm.prop].value }}
									</div>
									<div class="YBTValueContent" v-else>{{ scope.row[itm.prop] }}</div>
								</template>
							</el-table-column>
						</el-table>

						<div key="YBT_downLookEcharts" id="YBT_downLookEcharts"></div>

						<div class="YBT_downLookContent">
							<div v-for="(itm, name, idx) in YBTObjectDownLook.chart.diff" :key="idx + '_down'" :id="'YBTDownLook' + idx"></div>
						</div>
					</div>

					<div class="RRX_content" v-if="YBTSelctName.indexOf('YBT上肢测试') !== -1">
						<div class="top_title">
							<span></span>
							YBT上肢测试
						</div>
						<div class="YBT_down_tips">
							<el-tooltip class="item" effect="dark" placement="left">
								<div slot="content">
									<p>1.每一侧：差异<=4cm表示通过；差异>4cm表示不通过</p>
									<p>2.对于总分：总分>=95表示通过；总分<95表示不通过</p>
								</div>
								<i class="el-icon-info"></i>
							</el-tooltip>
							<span></span>通过 <span></span>不通过
						</div>
						<el-table
							:data="YBTObjectUpLook.table_data"
							class="YBT_tableData YBT_tableDataLook"
							header-row-class-name="YBT_headerName"
							:span-method="YBTDownMethod"
							border
							style="width: 100%; margin-top: 20px"
						>
							<el-table-column label="序号" type="index" width="45" align="center">
								<template slot-scope="scope">{{ scope.$index / 3 + 1 }}</template>
							</el-table-column>
							<el-table-column
								:prop="itm.prop"
								:label="itm.name"
								:key="'table_head' + idx"
								align="center"
								v-for="(itm, idx) in YBTObjectUpLook.table_head"
								:width="idx == 0 ? '60' : idx == 1 ? '45' : ''"
							>
								<template slot-scope="scope">
									<div
										class="YBTValueContent"
										v-if="itm.prop == 'nc' || itm.prop == 'hwc' || itm.prop == 'qwc' || itm.prop == 'score'"
										:style="{ background: scope.row[itm.prop].color }"
									>
										{{ scope.row[itm.prop].value }}
									</div>
									<div class="YBTValueContent" v-else>{{ scope.row[itm.prop] }}</div>
								</template>
							</el-table-column>
						</el-table>

						<div key="YBT_downLookEcharts" id="YBT_upLookEcharts"></div>

						<div class="YBT_downLookContent">
							<div v-for="(itm, name, idx) in YBTObjectUpLook.chart.diff" :key="idx + '_down'" :id="'YBTUpLook' + idx"></div>
						</div>
					</div>
				</el-tab-pane>

				<el-tab-pane label="SFMA" name="SFMA" key="SFMA" v-if="selectTextName.indexOf('SFMA') !== -1">
					<div class="SFMA_content" v-if="selectTextName.indexOf('SFMA') !== -1">
						<div class="SFMA_top">
							<div class="t_title_left">
								<!-- <span></span> -->
								<!-- SFMA -->
							</div>
							<div class="t_title_right">
								<span @click="SFMASelectChange('FN')" :class="SFMAClickName['FN'] ? 'FN_name' : ''">FN (功能正常和无痛)</span>
								<span @click="SFMASelectChange('FP')" :class="SFMAClickName['FP'] ? 'FP_name' : ''">FP (功能正常和疼痛)</span>
								<span @click="SFMASelectChange('DN')" :class="SFMAClickName['DN'] ? 'DN_name' : ''">DN（功能不良和无痛）</span>
								<span @click="SFMASelectChange('DP')" :class="SFMAClickName['DP'] ? 'DP_name' : ''">DP（功能不良和疼痛）</span>
							</div>
						</div>
						<el-table :data="SFMA_tableData" class="SFMA_tableData" header-row-class-name="YBT_headerName" border style="width: 100%">
							<el-table-column label="序号" type="index" width="45" align="center">
								<template slot-scope="scope">{{ scope.$index + 1 }}</template>
							</el-table-column>
							<el-table-column prop="staff_name" label="运动员" align="center" width="60"></el-table-column>
							<el-table-column prop="sex" label="性别" align="center" width="45">
								<template slot-scope="scope">{{ scope.row.sex == '1' ? '男' : '女' }}</template>
							</el-table-column>
							<el-table-column prop="leftFront" label="颈部屈曲" align="center">
								<template slot-scope="scope">
									<el-select
										v-model="scope.row.norms['颈部屈曲'].norm_value"
										:disabled="$route.query.name == 'look'"
										@change="SFMA_SelectChange(scope.row, scope.$index, '颈部屈曲')"
										:class="
											scope.row.norms['颈部屈曲'].norm_value == 1 && SFMAClickName['FN']
												? 'FN_select_name'
												: scope.row.norms['颈部屈曲'].norm_value == 2 && SFMAClickName['FP']
												? 'FP_select_name'
												: scope.row.norms['颈部屈曲'].norm_value == 3 && SFMAClickName['DN']
												? 'DN_select_name'
												: scope.row.norms['颈部屈曲'].norm_value == 4 && SFMAClickName['DP']
												? 'DP_select_name'
												: ''
										"
									>
										<el-option v-for="(item, index) in SFMA_optionsData" :key="index" :label="item" :value="index"></el-option>
									</el-select>
								</template>
							</el-table-column>
							<el-table-column prop="leftBack" label="颈部伸展" align="center">
								<template slot-scope="scope">
									<el-select
										v-model="scope.row.norms['颈部伸展'].norm_value"
										:disabled="$route.query.name == 'look'"
										@change="SFMA_SelectChange(scope.row, scope.$index, '颈部伸展')"
										:class="
											scope.row.norms['颈部伸展'].norm_value == 1 && SFMAClickName['FN']
												? 'FN_select_name'
												: scope.row.norms['颈部伸展'].norm_value == 2 && SFMAClickName['FP']
												? 'FP_select_name'
												: scope.row.norms['颈部伸展'].norm_value == 3 && SFMAClickName['DN']
												? 'DN_select_name'
												: scope.row.norms['颈部伸展'].norm_value == 4 && SFMAClickName['DP']
												? 'DP_select_name'
												: ''
										"
									>
										<el-option v-for="(item, index) in SFMA_optionsData" :key="index" :label="item" :value="index"></el-option>
									</el-select>
								</template>
							</el-table-column>
							<el-table-column prop="leftAfter" label="颈部旋转" align="center">
								<template slot-scope="scope">
									<el-select
										v-model="scope.row.norms['颈部旋转'].norm_value"
										:disabled="$route.query.name == 'look'"
										@change="SFMA_SelectChange(scope.row, scope.$index, '颈部旋转')"
										:class="
											scope.row.norms['颈部旋转'].norm_value == 1 && SFMAClickName['FN']
												? 'FN_select_name'
												: scope.row.norms['颈部旋转'].norm_value == 2 && SFMAClickName['FP']
												? 'FP_select_name'
												: scope.row.norms['颈部旋转'].norm_value == 3 && SFMAClickName['DN']
												? 'DN_select_name'
												: scope.row.norms['颈部旋转'].norm_value == 4 && SFMAClickName['DP']
												? 'DP_select_name'
												: ''
										"
									>
										<el-option v-for="(item, index) in SFMA_optionsData" :key="index" :label="item" :value="index"></el-option>
									</el-select>
								</template>
							</el-table-column>
							<el-table-column prop="rightFront" label="肩部模式" align="center">
								<template slot-scope="scope">
									<el-select
										v-model="scope.row.norms['肩部模式'].norm_value"
										:disabled="$route.query.name == 'look'"
										@change="SFMA_SelectChange(scope.row, scope.$index, '肩部模式')"
										:class="
											scope.row.norms['肩部模式'].norm_value == 1 && SFMAClickName['FN']
												? 'FN_select_name'
												: scope.row.norms['肩部模式'].norm_value == 2 && SFMAClickName['FP']
												? 'FP_select_name'
												: scope.row.norms['肩部模式'].norm_value == 3 && SFMAClickName['DN']
												? 'DN_select_name'
												: scope.row.norms['肩部模式'].norm_value == 4 && SFMAClickName['DP']
												? 'DP_select_name'
												: ''
										"
									>
										<el-option v-for="(item, index) in SFMA_optionsData" :key="index" :label="item" :value="index"></el-option>
									</el-select>
								</template>
							</el-table-column>
							<el-table-column prop="rightBack" label="肩部模式二" align="center">
								<template slot-scope="scope">
									<el-select
										v-model="scope.row.norms['肩部模式二'].norm_value"
										:disabled="$route.query.name == 'look'"
										@change="SFMA_SelectChange(scope.row, scope.$index, '肩部模式二')"
										:class="
											scope.row.norms['肩部模式二'].norm_value == 1 && SFMAClickName['FN']
												? 'FN_select_name'
												: scope.row.norms['肩部模式二'].norm_value == 2 && SFMAClickName['FP']
												? 'FP_select_name'
												: scope.row.norms['肩部模式二'].norm_value == 3 && SFMAClickName['DN']
												? 'DN_select_name'
												: scope.row.norms['肩部模式二'].norm_value == 4 && SFMAClickName['DP']
												? 'DP_select_name'
												: ''
										"
									>
										<el-option v-for="(item, index) in SFMA_optionsData" :key="index" :label="item" :value="index"></el-option>
									</el-select>
								</template>
							</el-table-column>
							<el-table-column prop="rightAfter" label="多部位屈曲" align="center">
								<template slot-scope="scope">
									<el-select
										v-model="scope.row.norms['多部位屈曲'].norm_value"
										:disabled="$route.query.name == 'look'"
										@change="SFMA_SelectChange(scope.row, scope.$index, '多部位屈曲')"
										:class="
											scope.row.norms['多部位屈曲'].norm_value == 1 && SFMAClickName['FN']
												? 'FN_select_name'
												: scope.row.norms['多部位屈曲'].norm_value == 2 && SFMAClickName['FP']
												? 'FP_select_name'
												: scope.row.norms['多部位屈曲'].norm_value == 3 && SFMAClickName['DN']
												? 'DN_select_name'
												: scope.row.norms['多部位屈曲'].norm_value == 4 && SFMAClickName['DP']
												? 'DP_select_name'
												: ''
										"
									>
										<el-option v-for="(item, index) in SFMA_optionsData" :key="index" :label="item" :value="index"></el-option>
									</el-select>
								</template>
							</el-table-column>
							<el-table-column prop="rightAfter" label="多部位伸展" align="center">
								<template slot-scope="scope">
									<el-select
										v-model="scope.row.norms['多部位伸展'].norm_value"
										:disabled="$route.query.name == 'look'"
										@change="SFMA_SelectChange(scope.row, scope.$index, '多部位伸展')"
										:class="
											scope.row.norms['多部位伸展'].norm_value == 1 && SFMAClickName['FN']
												? 'FN_select_name'
												: scope.row.norms['多部位伸展'].norm_value == 2 && SFMAClickName['FP']
												? 'FP_select_name'
												: scope.row.norms['多部位伸展'].norm_value == 3 && SFMAClickName['DN']
												? 'DN_select_name'
												: scope.row.norms['多部位伸展'].norm_value == 4 && SFMAClickName['DP']
												? 'DP_select_name'
												: ''
										"
									>
										<el-option v-for="(item, index) in SFMA_optionsData" :key="index" :label="item" :value="index"></el-option>
									</el-select>
								</template>
							</el-table-column>
							<el-table-column prop="rightAfter" label="多部位旋转" align="center">
								<template slot-scope="scope">
									<el-select
										v-model="scope.row.norms['多部位旋转'].norm_value"
										:disabled="$route.query.name == 'look'"
										@change="SFMA_SelectChange(scope.row, scope.$index, '多部位旋转')"
										:class="
											scope.row.norms['多部位旋转'].norm_value == 1 && SFMAClickName['FN']
												? 'FN_select_name'
												: scope.row.norms['多部位旋转'].norm_value == 2 && SFMAClickName['FP']
												? 'FP_select_name'
												: scope.row.norms['多部位旋转'].norm_value == 3 && SFMAClickName['DN']
												? 'DN_select_name'
												: scope.row.norms['多部位旋转'].norm_value == 4 && SFMAClickName['DP']
												? 'DP_select_name'
												: ''
										"
									>
										<el-option v-for="(item, index) in SFMA_optionsData" :key="index" :label="item" :value="index"></el-option>
									</el-select>
								</template>
							</el-table-column>
							<el-table-column prop="rightAfter" label="单腿站立" align="center">
								<template slot-scope="scope">
									<el-select
										v-model="scope.row.norms['单腿站立'].norm_value"
										:disabled="$route.query.name == 'look'"
										@change="SFMA_SelectChange(scope.row, scope.$index, '单腿站立')"
										:class="
											scope.row.norms['单腿站立'].norm_value == 1 && SFMAClickName['FN']
												? 'FN_select_name'
												: scope.row.norms['单腿站立'].norm_value == 2 && SFMAClickName['FP']
												? 'FP_select_name'
												: scope.row.norms['单腿站立'].norm_value == 3 && SFMAClickName['DN']
												? 'DN_select_name'
												: scope.row.norms['单腿站立'].norm_value == 4 && SFMAClickName['DP']
												? 'DP_select_name'
												: ''
										"
									>
										<el-option v-for="(item, index) in SFMA_optionsData" :key="index" :label="item" :value="index"></el-option>
									</el-select>
								</template>
							</el-table-column>
							<el-table-column prop="rightAfter" label="高举深蹲" align="center">
								<template slot-scope="scope">
									<el-select
										v-model="scope.row.norms['高举深蹲'].norm_value"
										:disabled="$route.query.name == 'look'"
										@change="SFMA_SelectChange(scope.row, scope.$index, '高举深蹲')"
										:class="
											scope.row.norms['高举深蹲'].norm_value == 1 && SFMAClickName['FN']
												? 'FN_select_name'
												: scope.row.norms['高举深蹲'].norm_value == 2 && SFMAClickName['FP']
												? 'FP_select_name'
												: scope.row.norms['高举深蹲'].norm_value == 3 && SFMAClickName['DN']
												? 'DN_select_name'
												: scope.row.norms['高举深蹲'].norm_value == 4 && SFMAClickName['DP']
												? 'DP_select_name'
												: ''
										"
									>
										<el-option v-for="(item, index) in SFMA_optionsData" :key="index" :label="item" :value="index"></el-option>
									</el-select>
								</template>
							</el-table-column>
						</el-table>
					</div>
				</el-tab-pane>
				<el-tab-pane label="柔韧性测试" name="柔韧性" key="RRX" v-if="selectTextName.indexOf('柔韧性') !== -1">
					<div class="RRX_content">
						<!-- <div class="top_title">
              <span></span>
              柔韧性
            </div> -->
						<el-table :data="RRX_tableData" style="width: 100%" class="RRX_tableData" :span-method="spanMethod">
							<el-table-column label="序号" type="index" width="45" align="center" fixed>
								<template slot-scope="scope">{{ scope.$index + 1 }}</template>
							</el-table-column>
							<el-table-column prop="staff_name" label="运动员" align="center" width="60" fixed></el-table-column>
							<el-table-column prop="sex" label="性别" align="center" width="45" fixed>
								<template slot-scope="scope">{{ scope.row.sex == '1' ? '男' : '女' }}</template>
							</el-table-column>

							<el-table-column
								:label="indexs"
								align="center"
								v-if="indexs !== '全身旋转测试' && indexs !== '抓背测试' && indexs !== '抬肩测试'"
								v-for="(items, indexs) in RRXObject"
								:key="indexs"
							>
								<template slot-scope="scope">
									<span v-if="$route.query.name == 'look'">{{ scope.row.norms[indexs].norm_value }}</span>
									<el-input
										v-else
										v-model="scope.row.norms[indexs].norm_value"
										@input="ruleValueChange(indexs, scope.$index, 'RRX_tableData', 'norm_value')"
										@blur="FMSValueChange(indexs, scope.$index, 'RRX_tableData')"
										:disabled="scope.row.staff_name == '男子-团队平均值' || scope.row.staff_name == '女子-团队平均值' || $route.query.name == 'look'"
									></el-input>
								</template>
							</el-table-column>
							<el-table-column label="全身旋转测试cm" align="center" v-if="RRX_selectName.indexOf('全身旋转测试') !== -1">
								<el-table-column label="左" align="center">
									<template slot-scope="scope">
										<span v-if="$route.query.name == 'look'">{{ scope.row.norms['全身旋转测试'].json_value.leftValue }}</span>
										<el-input
											v-else
											v-model="scope.row.norms['全身旋转测试'].json_value.leftValue"
											@input="ruleValueChange('全身旋转测试', scope.$index, 'RRX_tableData', 'leftValue')"
											@blur="FMSValueChange('全身旋转测试', scope.$index, 'RRX_tableData')"
											:disabled="scope.row.staff_name == '男子-团队平均值' || scope.row.staff_name == '女子-团队平均值' || $route.query.name == 'look'"
										></el-input>
									</template>
								</el-table-column>
								<el-table-column label="右" align="center">
									<template slot-scope="scope">
										<span v-if="$route.query.name == 'look'">{{ scope.row.norms['全身旋转测试'].json_value.rightValue }}</span>
										<el-input
											v-else
											v-model="scope.row.norms['全身旋转测试'].json_value.rightValue"
											@input="ruleValueChange('全身旋转测试', scope.$index, 'RRX_tableData', 'rightValue')"
											@blur="FMSValueChange('全身旋转测试', scope.$index, 'RRX_tableData')"
											:disabled="scope.row.staff_name == '男子-团队平均值' || scope.row.staff_name == '女子-团队平均值' || $route.query.name == 'look'"
										></el-input>
									</template>
								</el-table-column>
								<el-table-column label="均值" align="center">
									<template slot-scope="scope">
										<span v-if="$route.query.name == 'look'">{{ scope.row.norms['全身旋转测试'].json_value.overValue }}</span>
										<el-input v-else v-model="scope.row.norms['全身旋转测试'].json_value.overValue" disabled></el-input>
									</template>
								</el-table-column>
							</el-table-column>

							<el-table-column label="抓背测试cm" align="center" v-if="RRX_selectName.indexOf('抓背测试') !== -1">
								<el-table-column label="左" align="center">
									<template slot-scope="scope">
										<span v-if="$route.query.name == 'look'">{{ scope.row.norms['抓背测试'].json_value.leftValue }}</span>
										<el-input
											v-else
											v-model="scope.row.norms['抓背测试'].json_value.leftValue"
											@input="ruleValueChange('抓背测试', scope.$index, 'RRX_tableData', 'leftValue')"
											@blur="FMSValueChange('抓背测试', scope.$index, 'RRX_tableData')"
											:disabled="scope.row.staff_name == '男子-团队平均值' || scope.row.staff_name == '女子-团队平均值' || $route.query.name == 'look'"
										></el-input>
									</template>
								</el-table-column>
								<el-table-column label="右" align="center">
									<template slot-scope="scope">
										<span v-if="$route.query.name == 'look'">{{ scope.row.norms['抓背测试'].json_value.rightValue }}</span>
										<el-input
											v-else
											v-model="scope.row.norms['抓背测试'].json_value.rightValue"
											@input="ruleValueChange('抓背测试', scope.$index, 'RRX_tableData', 'rightValue')"
											@blur="FMSValueChange('抓背测试', scope.$index, 'RRX_tableData')"
											:disabled="scope.row.staff_name == '男子-团队平均值' || scope.row.staff_name == '女子-团队平均值' || $route.query.name == 'look'"
										></el-input>
									</template>
								</el-table-column>
								<el-table-column label="均值" align="center">
									<template slot-scope="scope">
										<span v-if="$route.query.name == 'look'">{{ scope.row.norms['抓背测试'].json_value.overValue }}</span>
										<el-input v-else v-model="scope.row.norms['抓背测试'].json_value.overValue" disabled></el-input>
									</template>
								</el-table-column>
							</el-table-column>
							<el-table-column label="抬肩测试cm" align="center" v-if="RRX_selectName.indexOf('抬肩测试') !== -1">
								<el-table-column label="臂长" align="center">
									<template slot-scope="scope">
										<span v-if="$route.query.name == 'look'">{{ scope.row.norms['抬肩测试'].json_value.leftValue }}</span>
										<el-input
											v-else
											v-model="scope.row.norms['抬肩测试'].json_value.leftValue"
											@input="ruleValueChange('抬肩测试', scope.$index, 'RRX_tableData', 'leftValue')"
											@blur="FMSValueChange('抬肩测试', scope.$index, 'RRX_tableData')"
											:disabled="scope.row.staff_name == '男子-团队平均值' || scope.row.staff_name == '女子-团队平均值' || $route.query.name == 'look'"
										></el-input>
									</template>
								</el-table-column>
								<el-table-column label="抬肩高度" align="center">
									<template slot-scope="scope">
										<span v-if="$route.query.name == 'look'">{{ scope.row.norms['抬肩测试'].json_value.rightValue }}</span>
										<el-input
											v-else
											v-model="scope.row.norms['抬肩测试'].json_value.rightValue"
											@input="ruleValueChange('抬肩测试', scope.$index, 'RRX_tableData', 'rightValue')"
											@blur="FMSValueChange('抬肩测试', scope.$index, 'RRX_tableData')"
											:disabled="scope.row.staff_name == '男子-团队平均值' || scope.row.staff_name == '女子-团队平均值' || $route.query.name == 'look'"
										></el-input>
									</template>
								</el-table-column>
								<el-table-column label="抬肩分数" align="center">
									<template slot-scope="scope">
										<span v-if="$route.query.name == 'look'">{{ scope.row.norms['抬肩测试'].json_value.overValue }}</span>
										<el-input v-else v-model="scope.row.norms['抬肩测试'].json_value.overValue" disabled></el-input>
									</template>
								</el-table-column>
							</el-table-column>
						</el-table>
						<div class="RRX_echarts">
							<div class="e_left_sele">
								<el-select v-model="RRX_select" placeholder="请选择指标" @change="RRX_select_change()">
									<el-option v-for="item in RRX_selectName" :key="item" :label="item" :value="item"></el-option>
								</el-select>
								<el-select v-model="RRX_sex" placeholder="请选择性别" clearable @change="RRX_select_change()">
									<el-option key="1" label="男" value="1" v-if="RRXoldman == '1' || RRXoldman == '3'"></el-option>
									<el-option key="2" label="女" value="2" v-if="RRXoldman == '2' || RRXoldman == '3'"></el-option>
								</el-select>
							</div>
							<div class="rrx_e_line" id="rrxline"></div>
						</div>
					</div>
				</el-tab-pane>
				<el-tab-pane label="关节角度测试" name="关节角度" key="GJJD" v-if="selectTextName.indexOf('关节角度') !== -1">
					<div class="GJ_content" v-if="selectTextName.indexOf('关节角度') !== -1">
						<!-- <div class="top_title">
              <span></span>
              关节角度
            </div> -->
						<el-table :data="GJ_tableData" style="width: 100%" class="GJ_tableData" :span-method="spanMethod">
							<el-table-column label="序号" type="index" width="45" align="center" fixed>
								<template slot-scope="scope">{{ scope.$index + 1 }}</template>
							</el-table-column>
							<el-table-column prop="staff_name" label="运动员" align="center" width="60" fixed></el-table-column>
							<el-table-column prop="sex" label="性别" align="center" width="45" fixed>
								<template slot-scope="scope">{{ scope.row.sex == '1' ? '男' : '女' }}</template>
							</el-table-column>
							<el-table-column label="踝关节(°)" align="center" v-if="GJ_selectName.indexOf('踝关节') !== -1">
								<el-table-column label="背屈" align="center">
									<template slot-scope="scope">
										<span v-if="$route.query.name == 'look'">{{ scope.row.norms['踝关节'].json_value.leftValue }}</span>
										<el-input
											v-else
											v-model="scope.row.norms['踝关节'].json_value.leftValue"
											@input="ruleValueChange('踝关节', scope.$index, 'GJ_tableData', 'leftValue')"
											@blur="FMSValueChange('踝关节', scope.$index, 'GJ_tableData')"
											:disabled="scope.row.staff_name == '男子-团队平均值' || scope.row.staff_name == '女子-团队平均值' || $route.query.name == 'look'"
										></el-input>
									</template>
								</el-table-column>
								<el-table-column label="跎屈" align="center">
									<template slot-scope="scope">
										<span v-if="$route.query.name == 'look'">{{ scope.row.norms['踝关节'].json_value.rightValue }}</span>
										<el-input
											v-else
											v-model="scope.row.norms['踝关节'].json_value.rightValue"
											@input="ruleValueChange('踝关节', scope.$index, 'GJ_tableData', 'rightValue')"
											@blur="FMSValueChange('踝关节', scope.$index, 'GJ_tableData')"
											:disabled="scope.row.staff_name == '男子-团队平均值' || scope.row.staff_name == '女子-团队平均值' || $route.query.name == 'look'"
										></el-input>
									</template>
								</el-table-column>
							</el-table-column>
							<el-table-column label="膝关节(°)" align="center" v-if="GJ_selectName.indexOf('膝关节') !== -1">
								<el-table-column label="内旋" align="center">
									<template slot-scope="scope">
										<span v-if="$route.query.name == 'look'">{{ scope.row.norms['膝关节'].json_value.leftValue }}</span>
										<el-input
											v-else
											v-model="scope.row.norms['膝关节'].json_value.leftValue"
											@input="ruleValueChange('膝关节', scope.$index, 'GJ_tableData', 'leftValue')"
											@blur="FMSValueChange('膝关节', scope.$index, 'GJ_tableData')"
											:disabled="scope.row.staff_name == '男子-团队平均值' || scope.row.staff_name == '女子-团队平均值' || $route.query.name == 'look'"
										></el-input>
									</template>
								</el-table-column>
								<el-table-column label="外旋" align="center">
									<template slot-scope="scope">
										<span v-if="$route.query.name == 'look'">{{ scope.row.norms['膝关节'].json_value.rightValue }}</span>
										<el-input
											v-else
											v-model="scope.row.norms['膝关节'].json_value.rightValue"
											@input="ruleValueChange('膝关节', scope.$index, 'GJ_tableData', 'rightValue')"
											@blur="FMSValueChange('膝关节', scope.$index, 'GJ_tableData')"
											:disabled="scope.row.staff_name == '男子-团队平均值' || scope.row.staff_name == '女子-团队平均值' || $route.query.name == 'look'"
										></el-input>
									</template>
								</el-table-column>
								<el-table-column label="屈曲" align="center">
									<template slot-scope="scope">
										<span v-if="$route.query.name == 'look'">{{ scope.row.norms['膝关节'].json_value.overValue }}</span>
										<el-input
											v-else
											v-model="scope.row.norms['膝关节'].json_value.overValue"
											@input="ruleValueChange('膝关节', scope.$index, 'GJ_tableData', 'rightValue')"
											@blur="FMSValueChange('膝关节', scope.$index, 'GJ_tableData')"
											:disabled="scope.row.staff_name == '男子-团队平均值' || scope.row.staff_name == '女子-团队平均值' || $route.query.name == 'look'"
										></el-input>
									</template>
								</el-table-column>
							</el-table-column>
							<el-table-column label="髋关节(°)" align="center" v-if="GJ_selectName.indexOf('髋关节') !== -1">
								<el-table-column label="后伸" align="center">
									<template slot-scope="scope">
										<span v-if="$route.query.name == 'look'">{{ scope.row.norms['髋关节'].json_value.leftValue }}</span>
										<el-input
											v-else
											v-model="scope.row.norms['髋关节'].json_value.leftValue"
											@input="ruleValueChange('髋关节', scope.$index, 'GJ_tableData', 'leftValue')"
											@blur="FMSValueChange('髋关节', scope.$index, 'GJ_tableData')"
											:disabled="scope.row.staff_name == '男子-团队平均值' || scope.row.staff_name == '女子-团队平均值' || $route.query.name == 'look'"
										></el-input>
									</template>
								</el-table-column>
								<el-table-column label="前屈" align="center">
									<template slot-scope="scope">
										<span v-if="$route.query.name == 'look'">{{ scope.row.norms['髋关节'].json_value.rightValue }}</span>
										<el-input
											v-else
											v-model="scope.row.norms['髋关节'].json_value.rightValue"
											@input="ruleValueChange('髋关节', scope.$index, 'GJ_tableData', 'rightValue')"
											@blur="FMSValueChange('髋关节', scope.$index, 'GJ_tableData')"
											:disabled="scope.row.staff_name == '男子-团队平均值' || scope.row.staff_name == '女子-团队平均值' || $route.query.name == 'look'"
										></el-input>
									</template>
								</el-table-column>
								<el-table-column label="外展" align="center">
									<template slot-scope="scope">
										<span v-if="$route.query.name == 'look'">{{ scope.row.norms['髋关节'].json_value.overValue }}</span>
										<el-input
											v-else
											v-model="scope.row.norms['髋关节'].json_value.overValue"
											@input="ruleValueChange('髋关节', scope.$index, 'GJ_tableData', 'overValue')"
											@blur="FMSValueChange('髋关节', scope.$index, 'GJ_tableData')"
											:disabled="scope.row.staff_name == '男子-团队平均值' || scope.row.staff_name == '女子-团队平均值' || $route.query.name == 'look'"
										></el-input>
									</template>
								</el-table-column>
								<el-table-column label="内收" align="center">
									<template slot-scope="scope">
										<span v-if="$route.query.name == 'look'">{{ scope.row.norms['髋关节'].json_value.beforeValue }}</span>
										<el-input
											v-else
											v-model="scope.row.norms['髋关节'].json_value.beforeValue"
											@input="ruleValueChange('髋关节', scope.$index, 'GJ_tableData', 'beforeValue')"
											@blur="FMSValueChange('髋关节', scope.$index, 'GJ_tableData')"
											:disabled="scope.row.staff_name == '男子-团队平均值' || scope.row.staff_name == '女子-团队平均值' || $route.query.name == 'look'"
										></el-input>
									</template>
								</el-table-column>
							</el-table-column>
							<el-table-column label="腕关节(°)" align="center" v-if="GJ_selectName.indexOf('腕关节') !== -1">
								<el-table-column label="掌屈" align="center">
									<template slot-scope="scope">
										<span v-if="$route.query.name == 'look'">{{ scope.row.norms['腕关节'].json_value.leftValue }}</span>
										<el-input
											v-else
											v-model="scope.row.norms['腕关节'].json_value.leftValue"
											@input="ruleValueChange('腕关节', scope.$index, 'GJ_tableData', 'leftValue')"
											@blur="FMSValueChange('腕关节', scope.$index, 'GJ_tableData')"
											:disabled="scope.row.staff_name == '男子-团队平均值' || scope.row.staff_name == '女子-团队平均值' || $route.query.name == 'look'"
										></el-input>
									</template>
								</el-table-column>
								<el-table-column label="背伸" align="center">
									<template slot-scope="scope">
										<span v-if="$route.query.name == 'look'">{{ scope.row.norms['腕关节'].json_value.rightValue }}</span>
										<el-input
											v-else
											v-model="scope.row.norms['腕关节'].json_value.rightValue"
											@input="ruleValueChange('腕关节', scope.$index, 'GJ_tableData', 'rightValue')"
											@blur="FMSValueChange('腕关节', scope.$index, 'GJ_tableData')"
											:disabled="scope.row.staff_name == '男子-团队平均值' || scope.row.staff_name == '女子-团队平均值' || $route.query.name == 'look'"
										></el-input>
									</template>
								</el-table-column>
								<el-table-column label="桡屈" align="center">
									<template slot-scope="scope">
										<span v-if="$route.query.name == 'look'">{{ scope.row.norms['腕关节'].json_value.overValue }}</span>
										<el-input
											v-else
											v-model="scope.row.norms['腕关节'].json_value.overValue"
											@input="ruleValueChange('腕关节', scope.$index, 'GJ_tableData', 'overValue')"
											@blur="FMSValueChange('腕关节', scope.$index, 'GJ_tableData')"
											:disabled="scope.row.staff_name == '男子-团队平均值' || scope.row.staff_name == '女子-团队平均值' || $route.query.name == 'look'"
										></el-input>
									</template>
								</el-table-column>
								<el-table-column label="尺屈" align="center">
									<template slot-scope="scope">
										<span v-if="$route.query.name == 'look'">{{ scope.row.norms['腕关节'].json_value.beforeValue }}</span>
										<el-input
											v-else
											v-model="scope.row.norms['腕关节'].json_value.beforeValue"
											@input="ruleValueChange('腕关节', scope.$index, 'GJ_tableData', 'beforeValue')"
											@blur="FMSValueChange('腕关节', scope.$index, 'GJ_tableData')"
											:disabled="scope.row.staff_name == '男子-团队平均值' || scope.row.staff_name == '女子-团队平均值' || $route.query.name == 'look'"
										></el-input>
									</template>
								</el-table-column>
							</el-table-column>
							<el-table-column label="肘关节(°)" align="center" v-if="GJ_selectName.indexOf('肘关节') !== -1">
								<el-table-column label="屈曲" align="center">
									<template slot-scope="scope">
										<span v-if="$route.query.name == 'look'">{{ scope.row.norms['肘关节'].json_value.leftValue }}</span>
										<el-input
											v-else
											v-model="scope.row.norms['肘关节'].json_value.leftValue"
											@input="ruleValueChange('肘关节', scope.$index, 'GJ_tableData', 'leftValue')"
											@blur="FMSValueChange('肘关节', scope.$index, 'GJ_tableData')"
											:disabled="scope.row.staff_name == '男子-团队平均值' || scope.row.staff_name == '女子-团队平均值' || $route.query.name == 'look'"
										></el-input>
									</template>
								</el-table-column>
								<el-table-column label="伸展" align="center">
									<template slot-scope="scope">
										<span v-if="$route.query.name == 'look'">{{ scope.row.norms['肘关节'].json_value.rightValue }}</span>
										<el-input
											v-else
											v-model="scope.row.norms['肘关节'].json_value.rightValue"
											@input="ruleValueChange('肘关节', scope.$index, 'GJ_tableData', 'rightValue')"
											@blur="FMSValueChange('肘关节', scope.$index, 'GJ_tableData')"
											:disabled="scope.row.staff_name == '男子-团队平均值' || scope.row.staff_name == '女子-团队平均值' || $route.query.name == 'look'"
										></el-input>
									</template>
								</el-table-column>
								<el-table-column label="旋前" align="center">
									<template slot-scope="scope">
										<span v-if="$route.query.name == 'look'">{{ scope.row.norms['肘关节'].json_value.overValue }}</span>
										<el-input
											v-else
											v-model="scope.row.norms['肘关节'].json_value.overValue"
											@input="ruleValueChange('肘关节', scope.$index, 'GJ_tableData', 'overValue')"
											@blur="FMSValueChange('肘关节', scope.$index, 'GJ_tableData')"
											:disabled="scope.row.staff_name == '男子-团队平均值' || scope.row.staff_name == '女子-团队平均值' || $route.query.name == 'look'"
										></el-input>
									</template>
								</el-table-column>
								<el-table-column label="旋后" align="center">
									<template slot-scope="scope">
										<span v-if="$route.query.name == 'look'">{{ scope.row.norms['肘关节'].json_value.beforeValue }}</span>
										<el-input
											v-else
											v-model="scope.row.norms['肘关节'].json_value.beforeValue"
											@input="ruleValueChange('肘关节', scope.$index, 'GJ_tableData', 'beforeValue')"
											@blur="FMSValueChange('肘关节', scope.$index, 'GJ_tableData')"
											:disabled="scope.row.staff_name == '男子-团队平均值' || scope.row.staff_name == '女子-团队平均值' || $route.query.name == 'look'"
										></el-input>
									</template>
								</el-table-column>
							</el-table-column>
							<el-table-column label="肩关节(°)" align="center" v-if="GJ_selectName.indexOf('肩关节') !== -1">
								<el-table-column label="屈曲" align="center">
									<template slot-scope="scope">
										<span v-if="$route.query.name == 'look'">{{ scope.row.norms['肩关节'].json_value.leftValue }}</span>
										<el-input
											v-else
											v-model="scope.row.norms['肩关节'].json_value.leftValue"
											@input="ruleValueChange('肩关节', scope.$index, 'GJ_tableData', 'leftValue')"
											@blur="FMSValueChange('肩关节', scope.$index, 'GJ_tableData')"
											:disabled="scope.row.staff_name == '男子-团队平均值' || scope.row.staff_name == '女子-团队平均值' || $route.query.name == 'look'"
										></el-input>
									</template>
								</el-table-column>
								<el-table-column label="伸展" align="center">
									<template slot-scope="scope">
										<span v-if="$route.query.name == 'look'">{{ scope.row.norms['肩关节'].json_value.rightValue }}</span>
										<el-input
											v-else
											v-model="scope.row.norms['肩关节'].json_value.rightValue"
											@input="ruleValueChange('肩关节', scope.$index, 'GJ_tableData', 'rightValue')"
											@blur="FMSValueChange('肩关节', scope.$index, 'GJ_tableData')"
											:disabled="scope.row.staff_name == '男子-团队平均值' || scope.row.staff_name == '女子-团队平均值' || $route.query.name == 'look'"
										></el-input>
									</template>
								</el-table-column>
								<el-table-column label="外展" align="center">
									<template slot-scope="scope">
										<span v-if="$route.query.name == 'look'">{{ scope.row.norms['肩关节'].json_value.overValue }}</span>
										<el-input
											v-else
											v-model="scope.row.norms['肩关节'].json_value.overValue"
											@input="ruleValueChange('肩关节', scope.$index, 'GJ_tableData', 'overValue')"
											@blur="FMSValueChange('肩关节', scope.$index, 'GJ_tableData')"
											:disabled="scope.row.staff_name == '男子-团队平均值' || scope.row.staff_name == '女子-团队平均值' || $route.query.name == 'look'"
										></el-input>
									</template>
								</el-table-column>
								<el-table-column label="内旋" align="center">
									<template slot-scope="scope">
										<span v-if="$route.query.name == 'look'">{{ scope.row.norms['肩关节'].json_value.beforeValue }}</span>
										<el-input
											v-else
											v-model="scope.row.norms['肩关节'].json_value.beforeValue"
											@input="ruleValueChange('肩关节', scope.$index, 'GJ_tableData', 'beforeValue')"
											@blur="FMSValueChange('肩关节', scope.$index, 'GJ_tableData')"
											:disabled="scope.row.staff_name == '男子-团队平均值' || scope.row.staff_name == '女子-团队平均值' || $route.query.name == 'look'"
										></el-input>
									</template>
								</el-table-column>
								<el-table-column label="外旋" align="center">
									<template slot-scope="scope">
										<span v-if="$route.query.name == 'look'">{{ scope.row.norms['肩关节'].json_value.laterValue }}</span>
										<el-input
											v-else
											v-model="scope.row.norms['肩关节'].json_value.laterValue"
											@input="ruleValueChange('肩关节', scope.$index, 'GJ_tableData', 'laterValue')"
											@blur="FMSValueChange('肩关节', scope.$index, 'GJ_tableData')"
											:disabled="scope.row.staff_name == '男子-团队平均值' || scope.row.staff_name == '女子-团队平均值' || $route.query.name == 'look'"
										></el-input>
									</template>
								</el-table-column>
							</el-table-column>
							<el-table-column label="腰部(°)" align="center" v-if="GJ_selectName.indexOf('腰部') !== -1">
								<el-table-column label="前屈" align="center">
									<template slot-scope="scope">
										<span v-if="$route.query.name == 'look'">{{ scope.row.norms['腰部'].json_value.leftValue }}</span>
										<el-input
											v-else
											v-model="scope.row.norms['腰部'].json_value.leftValue"
											@input="ruleValueChange('腰部', scope.$index, 'GJ_tableData', 'leftValue')"
											@blur="FMSValueChange('腰部', scope.$index, 'GJ_tableData')"
											:disabled="scope.row.staff_name == '男子-团队平均值' || scope.row.staff_name == '女子-团队平均值' || $route.query.name == 'look'"
										></el-input>
									</template>
								</el-table-column>
								<el-table-column label="后伸" align="center">
									<template slot-scope="scope">
										<span v-if="$route.query.name == 'look'">{{ scope.row.norms['腰部'].json_value.rightValue }}</span>
										<el-input
											v-else
											v-model="scope.row.norms['腰部'].json_value.rightValue"
											@input="ruleValueChange('腰部', scope.$index, 'GJ_tableData', 'rightValue')"
											@blur="FMSValueChange('腰部', scope.$index, 'GJ_tableData')"
											:disabled="scope.row.staff_name == '男子-团队平均值' || scope.row.staff_name == '女子-团队平均值' || $route.query.name == 'look'"
										></el-input>
									</template>
								</el-table-column>
								<el-table-column label="左侧屈" align="center">
									<template slot-scope="scope">
										<span v-if="$route.query.name == 'look'">{{ scope.row.norms['腰部'].json_value.overValue }}</span>
										<el-input
											v-else
											v-model="scope.row.norms['腰部'].json_value.overValue"
											@input="ruleValueChange('腰部', scope.$index, 'GJ_tableData', 'overValue')"
											@blur="FMSValueChange('腰部', scope.$index, 'GJ_tableData')"
											:disabled="scope.row.staff_name == '男子-团队平均值' || scope.row.staff_name == '女子-团队平均值' || $route.query.name == 'look'"
										></el-input>
									</template>
								</el-table-column>
								<el-table-column label="右侧屈" align="center">
									<template slot-scope="scope">
										<span v-if="$route.query.name == 'look'">{{ scope.row.norms['腰部'].json_value.beforeValue }}</span>
										<el-input
											v-else
											v-model="scope.row.norms['腰部'].json_value.beforeValue"
											@input="ruleValueChange('腰部', scope.$index, 'GJ_tableData', 'beforeValue')"
											@blur="FMSValueChange('腰部', scope.$index, 'GJ_tableData')"
											:disabled="scope.row.staff_name == '男子-团队平均值' || scope.row.staff_name == '女子-团队平均值' || $route.query.name == 'look'"
										></el-input>
									</template>
								</el-table-column>
								<el-table-column label="左旋转" align="center">
									<template slot-scope="scope">
										<span v-if="$route.query.name == 'look'">{{ scope.row.norms['腰部'].json_value.laterValue }}</span>
										<el-input
											v-else
											v-model="scope.row.norms['腰部'].json_value.laterValue"
											@input="ruleValueChange('腰部', scope.$index, 'GJ_tableData', 'laterValue')"
											@blur="FMSValueChange('腰部', scope.$index, 'GJ_tableData')"
											:disabled="scope.row.staff_name == '男子-团队平均值' || scope.row.staff_name == '女子-团队平均值' || $route.query.name == 'look'"
										></el-input>
									</template>
								</el-table-column>
								<el-table-column label="右旋转" align="center">
									<template slot-scope="scope">
										<span v-if="$route.query.name == 'look'">{{ scope.row.norms['腰部'].json_value.downValue }}</span>
										<el-input
											v-else
											v-model="scope.row.norms['腰部'].json_value.downValue"
											@input="ruleValueChange('腰部', scope.$index, 'GJ_tableData', 'downValue')"
											@blur="FMSValueChange('腰部', scope.$index, 'GJ_tableData')"
											:disabled="scope.row.staff_name == '男子-团队平均值' || scope.row.staff_name == '女子-团队平均值' || $route.query.name == 'look'"
										></el-input>
									</template>
								</el-table-column>
							</el-table-column>
							<el-table-column label="颈部(°)" align="center" v-if="GJ_selectName.indexOf('颈部') !== -1">
								<el-table-column label="前屈" align="center">
									<template slot-scope="scope">
										<span v-if="$route.query.name == 'look'">{{ scope.row.norms['颈部'].json_value.leftValue }}</span>
										<el-input
											v-else
											v-model="scope.row.norms['颈部'].json_value.leftValue"
											@input="ruleValueChange('颈部', scope.$index, 'GJ_tableData', 'leftValue')"
											@blur="FMSValueChange('颈部', scope.$index, 'GJ_tableData')"
											:disabled="scope.row.staff_name == '男子-团队平均值' || scope.row.staff_name == '女子-团队平均值' || $route.query.name == 'look'"
										></el-input>
									</template>
								</el-table-column>
								<el-table-column label="左侧屈" align="center">
									<template slot-scope="scope">
										<span v-if="$route.query.name == 'look'">{{ scope.row.norms['颈部'].json_value.rightValue }}</span>
										<el-input
											v-else
											v-model="scope.row.norms['颈部'].json_value.rightValue"
											@input="ruleValueChange('颈部', scope.$index, 'GJ_tableData', 'rightValue')"
											@blur="FMSValueChange('颈部', scope.$index, 'GJ_tableData')"
											:disabled="scope.row.staff_name == '男子-团队平均值' || scope.row.staff_name == '女子-团队平均值' || $route.query.name == 'look'"
										></el-input>
									</template>
								</el-table-column>
								<el-table-column label="右侧屈" align="center">
									<template slot-scope="scope">
										<span v-if="$route.query.name == 'look'">{{ scope.row.norms['颈部'].json_value.overValue }}</span>
										<el-input
											v-else
											v-model="scope.row.norms['颈部'].json_value.overValue"
											@input="ruleValueChange('颈部', scope.$index, 'GJ_tableData', 'overValue')"
											@blur="FMSValueChange('颈部', scope.$index, 'GJ_tableData')"
											:disabled="scope.row.staff_name == '男子-团队平均值' || scope.row.staff_name == '女子-团队平均值' || $route.query.name == 'look'"
										></el-input>
									</template>
								</el-table-column>
								<el-table-column label="左右旋转" width="120" align="center">
									<template slot-scope="scope">
										<span v-if="$route.query.name == 'look'">{{ scope.row.norms['颈部'].json_value.beforeValue }}</span>
										<el-input
											v-else
											v-model="scope.row.norms['颈部'].json_value.beforeValue"
											@input="ruleValueChange('颈部', scope.$index, 'GJ_tableData', 'beforeValue')"
											@blur="FMSValueChange('颈部', scope.$index, 'GJ_tableData')"
											:disabled="scope.row.staff_name == '男子-团队平均值' || scope.row.staff_name == '女子-团队平均值' || $route.query.name == 'look'"
										></el-input>
									</template>
								</el-table-column>
							</el-table-column>
						</el-table>
					</div>
				</el-tab-pane>

				<el-tab-pane v-for="(o_item, o_index) in forSelectName" :key="o_index" :label="o_item" :name="o_item">
					<div class="MCS_content" v-if="forSelectName.indexOf(o_item) !== -1">
						<el-table :data="otherVariable[`${o_item}_tableData`]" class="YBT_tableData" header-row-class-name="YBT_headerName" :span-method="YBTSpanMethod" border style="width: 100%">
							<el-table-column label="序号" type="index" width="45" align="center">
								<template slot-scope="scope">{{ scope.$index + 1 }}</template>
							</el-table-column>
							<el-table-column prop="staff_name" label="运动员" align="center" width="60"></el-table-column>
							<el-table-column prop="sex" label="性别" align="center" width="45">
								<template slot-scope="scope">{{ scope.row.sex == '1' ? '男' : '女' }}</template>
							</el-table-column>
							<el-table-column :label="indexs" align="center" v-for="(items, indexs) in otherVariable[o_item + 'Object']" :key="indexs">
								<template slot-scope="scope">
									<span v-if="$route.query.name == 'look'">{{ scope.row.norms[indexs].norm_value }}</span>
									<el-input
										v-else
										v-model="scope.row.norms[indexs].norm_value"
										@input="ruleValueChange(indexs, scope.$index, o_item + '_tableData', 'norm_value')"
										@blur="MCSMean(o_item + '_tableData', o_item)"
										:disabled="scope.row.staff_name == '男子-团队平均值' || scope.row.staff_name == '女子-团队平均值' || $route.query.name == 'look'"
									></el-input>
								</template>
							</el-table-column>
						</el-table>
					</div>
				</el-tab-pane>
			</el-tabs>
			<!-- 测试评价 -->
			<div class="test_content">
				<div class="top_title">
					<span></span>
					测试评价
				</div>
				<!-- v-if="$route.query.name !== 'look'" -->
				<div class="test_editor" id="editor" ref="editorElem" style="width: 100%"></div>
				<!-- <div class="test_editor look_editor" v-else v-html="editorContent">

        </div>-->
			</div>
		</div>

		<!-- 新建地址 -->
		<el-dialog :close-on-click-modal="false" :visible.sync="dialogAddress" :before-close="address_cancel" title="新增地址" width="55%" center>
			<div class="addressForm_dialog">
				<el-form :model="addressForm" :rules="addressRules" ref="addressForm" label-width="90px" class="demo_ruleForm addressForm_dialog" :label-position="'left'" :inline-message="false">
					<div class="item_flex">
						<el-form-item label="国家" prop="country">
							<el-select v-model="addressForm.country" placeholder="请选择国家" style="width: 100%" @change="countryChange('country')">
								<el-option v-for="item in countryList" :key="item.id" :label="item.label" :value="item.id"></el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="省市" prop="city">
							<el-select v-model="addressForm.city" placeholder="请选择省市" style="width: 100%" @change="countryChange('city')">
								<el-option v-for="item in cityList" :key="item.id" :label="item.label" :value="item.id"></el-option>
							</el-select>
						</el-form-item>
					</div>
					<div class="item_flex">
						<el-form-item label="市区" prop="region">
							<el-select v-model="addressForm.region" placeholder="请选择市区" style="width: 100%">
								<el-option v-for="item in regionList" :key="item.id" :label="item.label" :value="item.id"></el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="输入详细地址" prop="address">
							<el-input v-model="addressForm.address" placeholder="请输入详细地址"></el-input>
						</el-form-item>
					</div>
				</el-form>
			</div>
			<div class="form-bottom-button">
				<el-button @click="address_save" type="primary" v-no-more-click class="save_btn" round>保存</el-button>
				<el-button @click="address_cancel" class="cancel_btn" round>取消</el-button>
			</div>
		</el-dialog>
		<!-- 测试指标 -->
		<el-dialog :close-on-click-modal="false" :visible.sync="dialogTextIndex" :before-close="text_cancel" title="选择指标" width="50%" center>
			<div class="ModifyDoalog">
				<div class="textDialog_left">
					<div class="textDialog_left_input">
						<el-input
							@keyup.enter.native="selectTextIndex"
							class="left-child-input left-child-input-content"
							clearable
							placeholder="请输入指标名称"
							suffix-icon="iconfont iconsousuo"
							v-model="inputValue"
						></el-input>
					</div>
					<h2>指标列表</h2>
					<div class="tree_demo" v-loading="load">
						<el-tree
							empty-text="暂无数据"
							:data="textTreeData"
							ref="textTreeNode"
							show-checkbox
							node-key="uuid"
							:props="defaultProps"
							:check-strictly="false"
							:check-on-click-node="false"
							:filter-node-method="filterNode"
							@check="checkChange"
						></el-tree>
					</div>
				</div>
				<div class="textDialog_right">
					<p>
						已选指标 ( {{ selectNum }}/{{ textAllNum }} )
						<span @click="clickTreeAll">清空</span>
					</p>
					<div class="tree_demo">
						<el-tree :data="selectTreeData" node-key="uuid" :props="defaultProps">
							<span class="custom-tree-node" slot-scope="{ node, data }">
								<span>{{ node.label }}</span>
								<span>
									<i class="iconfont iconshanchu-moren" @click="textTreeCancel(node, data)" v-if="data.showCheckbox"></i>
								</span>
							</span>
						</el-tree>
					</div>
				</div>
			</div>
			<p class="bz_content">*注：一个指标同属于多个分类，指标只呈现在一个分类中</p>
			<div class="form-bottom-button">
				<el-button @click="text_save" type="primary" v-no-more-click class="save_btn" round>保存</el-button>
				<el-button @click="text_cancel" class="cancel_btn" round>取消</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
import E from 'wangeditor'
export default {
	data() {
		return {
			activeName: '',
			load: true,
			editor: null,
			editorContent: '',
			loading: false,
			newAddressName: '',
			textIndex: [],
			addressList: [],
			sportsmanList: [],
			countryList: [],
			cityList: [],
			regionList: [],
			textTreeData: [],
			oldTreeData: [],
			selectTreeData: [],
			saveSelectTreeData: [],
			demoSelectData: [],
			oldAddress: '',
			inputValue: '',
			textAllNum: 0,
			selectNum: 0,
			selectTextName: [],
			tableObject: {
				深蹲: {
					norm_uuid: '',
					norm_value: '',
					value_type: '1',
					json_value: {
						leftValue: '',
						overValue: '',
					},
				},
				跨栏步: {
					norm_uuid: '',
					norm_value: '',
					value_type: '1',
					json_value: {
						leftValue: '',
						rightValue: '',
						overValue: '',
					},
				},
				直线弓步蹲: {
					norm_uuid: '',
					norm_value: '',
					value_type: '1',
					json_value: {
						leftValue: '',
						rightValue: '',
						overValue: '',
					},
				},
				'踝关节排除测试-灵活型': {
					norm_uuid: '',
					norm_value: '',
					value_type: '1',
					json_value: {
						leftValue: '',
						rightValue: '',
						overValue: '',
					},
				},
				肩部灵活性: {
					norm_uuid: '',
					norm_value: '',
					value_type: '1',
					json_value: {
						leftValue: '',
						rightValue: '',
						overValue: '',
					},
				},
				主动直腿上抬: {
					norm_uuid: '',
					norm_value: '',
					value_type: '1',
					json_value: {
						leftValue: '',
						rightValue: '',
						overValue: '',
					},
				},
				躯干稳定俯卧撑: {
					norm_uuid: '',
					norm_value: '',
					value_type: '1',
					json_value: {
						leftValue: '',
						rightValue: '',
						overValue: '',
					},
				},
				旋转稳定性: {
					norm_uuid: '',
					norm_value: '',
					value_type: '1',
					json_value: {
						leftValue: '',
						rightValue: '',
						overValue: '',
					},
				},
			},
			MCSObject: {
				足长: {
					norm_uuid: '', // 指标 id
					norm_value: '', // 指标值
					value_type: '0',
					json_value: [],
				},
				'单腿前伸测试（左）': {
					norm_uuid: '', // 指标 id
					norm_value: '', // 指标值
					value_type: '0',
					json_value: [],
				},
				'单腿前伸测试（右）': {
					norm_uuid: '', // 指标 id
					norm_value: '', // 指标值
					value_type: '0',
					json_value: [],
				},
				'水平侧伸测试（左）': {
					norm_uuid: '', // 指标 id
					norm_value: '', // 指标值
					value_type: '0',
					json_value: [],
				},
				'水平侧伸测试（右）': {
					norm_uuid: '', // 指标 id
					norm_value: '', // 指标值
					value_type: '0',
					json_value: [],
				},
			},
			YBTObject: {
				左前: {
					norm_uuid: '', // 指标 id
					norm_value: '', // 指标值
					value_type: '0',
					json_value: [],
				},
				左后外: {
					norm_uuid: '', // 指标 id
					norm_value: '', // 指标值
					value_type: '0',
					json_value: [],
				},
				左后内: {
					norm_uuid: '', // 指标 id
					norm_value: '', // 指标值
					value_type: '0',
					json_value: [],
				},
				右前: {
					norm_uuid: '', // 指标 id
					norm_value: '', // 指标值
					value_type: '0',
					json_value: [],
				},
				右后外: {
					norm_uuid: '', // 指标 id
					norm_value: '', // 指标值
					value_type: '0',
					json_value: [],
				},
				右后内: {
					norm_uuid: '', // 指标 id
					norm_value: '', // 指标值
					value_type: '0',
					json_value: [],
				},
			},
			SFMAObject: {
				颈部屈曲: {
					norm_uuid: '', // 指标 id
					norm_value: '', // 指标值
					value_type: '0',
					json_value: [],
				},
				颈部伸展: {
					norm_uuid: '', // 指标 id
					norm_value: '', // 指标值
					value_type: '0',
					json_value: [],
				},
				颈部旋转: {
					norm_uuid: '', // 指标 id
					norm_value: '', // 指标值
					value_type: '0',
					json_value: [],
				},
				肩部模式: {
					norm_uuid: '', // 指标 id
					norm_value: '', // 指标值
					value_type: '0',
					json_value: [],
				},
				肩部模式二: {
					norm_uuid: '', // 指标 id
					norm_value: '', // 指标值
					value_type: '0',
					json_value: [],
				},
				多部位屈曲: {
					norm_uuid: '', // 指标 id
					norm_value: '', // 指标值
					value_type: '0',
					json_value: [],
				},
				多部位伸展: {
					norm_uuid: '', // 指标 id
					norm_value: '', // 指标值
					value_type: '0',
					json_value: [],
				},
				多部位旋转: {
					norm_uuid: '', // 指标 id
					norm_value: '', // 指标值
					value_type: '0',
					json_value: [],
				},
				单腿站立: {
					norm_uuid: '', // 指标 id
					norm_value: '', // 指标值
					value_type: '0',
					json_value: [],
				},
				高举深蹲: {
					norm_uuid: '', // 指标 id
					norm_value: '', // 指标值
					value_type: '0',
					json_value: [],
				},
			},
			RRXObject: {},
			GJObject: {},
			XGJObject: {},
			SFMAClickName: {
				FN: true,
				FP: true,
				DN: true,
				DP: true,
			},
			FMS_tableData: [],
			YBT_tableData: [],
			SFMA_tableData: [],
			SFMA_optionsData: [],
			RRX_tableData: [],
			MCS_selectName: [],
			MCS_tableData: [],
			RRX_selectName: [],
			GJ_selectName: [],
			GJ_tableData: [],
			XGJ_selectName: [],
			XGJ_tableData: [],

			FMS_select: '',
			FMS_sex: '',
			FMS_select_options: [],

			YBT_select: '左前',
			YBT_sex: '',
			YBT_select_options: [],

			RRX_select: '',
			RRX_sex: '',
			RRX_select_options: [],

			MCS_select: '',
			MCS_sex: '',
			MCS_select_options: [],

			forSelectName: [],

			dialogAddress: false,
			dialogTextIndex: false,

			otherVariable: {
				// 例如：
				// XGJ_selectName: [],
				// XGJ_tableData: [],
				// XGJoldman: 0,
				// XGJObject: {},
				// XGJ_object: {
				//   cate_uuid: '',
				//   cate_name: '',
				//   staffs: null
				// },
			},

			ruleForm: {
				title: '',
				data: null,
				department_uuid: '',
				sportsmanId: [],
				address: '',
			},

			addressForm: {
				country: '',
				city: '',
				region: '',
				address: '',
			},

			defaultProps: {
				children: 'norms',
				label: 'name',
			},
			rules: {
				title: [{ required: true, message: '请输入标题', trigger: 'blur' }],
				date: { required: true, message: '请选择测试日期', trigger: 'blur' },
				department_uuid: { required: true, message: '请选择运动队', trigger: 'change' },
				sportsmanId: { required: true, message: '请选择运动员', trigger: 'change' },
			},
			addressRules: {
				country: { required: true, message: '请选择国家', trigger: 'blur' },
			},
			oldman: 0,
			YBToldman: 0,
			RRXoldman: 0,
			MCSoldman: 0,
			GJoldman: 0,
			XGJoldman: 0,
			FMS_object: {
				cate_uuid: '',
				cate_name: '',
				staffs: null,
			},
			YBT_object: {
				cate_uuid: '',
				cate_name: '',
				staffs: null,
			},
			SFMA_object: {
				cate_uuid: '',
				cate_name: '',
				staffs: null,
			},
			MCS_object: {
				cate_uuid: '',
				cate_name: '',
				staffs: null,
			},
			RRX_object: {
				cate_uuid: '',
				cate_name: '',
				staffs: null,
			},
			GJ_object: {
				cate_uuid: '',
				cate_name: '',
				staffs: null,
			},
			XGJ_object: {
				cate_uuid: '',
				cate_name: '',
				staffs: null,
			},
			showw: false,

			// YBT下肢表头

			YBTObjectDown: {},
			YBTObjectDown_copy: [],
			YBT_tableDataDown: [],
			YBT_DownObject: {
				cate_uuid: '',
				cate_name: '',
				staffs: null,
			},

			// 查看数据
			YBTObjectDownLook: {},
			YBT_tableDataDownLook: [],
			// 查看数据
			YBTObjectUpLook: {},
			YBT_tableDataUpLook: [],

			// YBT上肢表头
			YBTObjectUp: {},
			YBTObjectUp_copy: [],
			YBT_tableDataUp: [],
			YBT_UpObject: {
				cate_uuid: '',
				cate_name: '',
				staffs: null,
			},

			YBTSelctName: [],
		}
	},
	watch: {
		inputValue(val) {
			this.$refs.textTreeNode.filter(val)
		},
	},
	mounted() {
		this.getAddressData()
		// if(this.$route.query.name !== 'look') {
		this.editor = new E(this.$refs.editorElem)
		// 编辑器的事件，每次改变会获取其html内容
		this.editor.config.onchange = html => {
			this.editorContent = html
		}
		this.editor.config.zIndex = 1000
		// this.editor.config.uploadImgServer = this.$store.state.img_url + '/p/annex/add'
		// this.editor.config.uploadFileName = 'file'
		this.editor.config.uploadImgShowBase64 = true
		this.editor.config.menus = [
			// 菜单配置
			'head', // 标题
			'bold', // 粗体
			'fontSize', // 字号
			'fontName', // 字体
			'italic', // 斜体
			'underline', // 下划线
			'strikeThrough', // 删除线
			'foreColor', // 文字颜色
			'backColor', // 背景颜色
			'link', // 插入链接
			'list', // 列表
			'justify', // 对齐方式
			'quote', // 引用
			'image', // 插入图片
			'table', // 表格
			'code', // 插入代码
		]
		this.editor.create() // 创建富文本实例
		// }
		if (this.$route.query.name == 'look') {
			this.editor.disable()
		}

		if (this.$route.query.uuid) {
			this.loading = true
			this.selectTextIndex('编辑')

			this.$axios
				.post(
					'bodyfunc/read',
					this.$qs({
						uuid: this.$route.query.uuid,
					})
				)
				.then(res => {
					if (res.data.code == 0) {
						this.ruleForm = {
							title: res.data.data.title,
							date: res.data.data.test_date,
							department_uuid: res.data.data.department_uuid,
							department_name: res.data.data.department_name,
							coach: res.data.data.coach,
							address: res.data.data.address_uuid,
							address_name: res.data.data.address,
							demo: res.data.data.demo,
							staff_uuid_str: res.data.data.staff_uuid_str,
							staff_name_str: res.data.data.staff_name_str,
						}
						if (this.ruleForm.address_name !== '' || this.ruleForm.address !== '') {
							this.addressList = this.$dataBack(this.addressList, { address: this.ruleForm.address_name, uuid: this.ruleForm.address }, 'uuid', 'address')
						}
						this.editorContent = res.data.data.summary
						// if(this.$route.query.name !== 'look') {
						this.editor.txt.html(res.data.data.summary)
						// }
						this.getSportsMan()
						this.$set(this.ruleForm, 'sportsmanId', this.ruleForm.staff_uuid_str.split(','))
						this.ruleForm = Object.assign({}, this.ruleForm)

						var downIndex = -1,
							upIndex = -1,
							ybt_obj = {
								builtin: 1,
								edit_flag: 0,
								name: 'YBT',
								uuid: 'YBT_uuid',
								norms: [],
							}
						res.data.data.cate_norm.forEach((itm, idx) => {
							if (itm.name == 'YBT下肢测试') {
								downIndex = idx
								ybt_obj.norms.push(itm)
								this.YBTSelctName.push('YBT下肢测试')
							} else if (itm.name == 'YBT上肢测试') {
								upIndex = idx
								ybt_obj.norms.push(itm)
								this.YBTSelctName.push('YBT上肢测试')
							}
						})
						if (downIndex !== -1 && upIndex !== -1) {
							res.data.data.cate_norm.splice(downIndex, 1, ybt_obj)
							res.data.data.cate_norm.splice(upIndex, 1)
						} else if (downIndex !== -1) {
							res.data.data.cate_norm.splice(downIndex, 1, ybt_obj)
						} else if (upIndex !== -1) {
							res.data.data.cate_norm.splice(upIndex, 1, ybt_obj)
						}

						this.saveSelectTreeData = res.data.data.cate_norm
						this.selectTreeData = res.data.data.cate_norm

						this.saveSelectTreeData.forEach(item => {
							if (item.norms.length) {
								item.norms.forEach(itm => {
									this.demoSelectData.push(itm)
								})
							} else {
								this.demoSelectData.push(item)
							}
							this.selectTextName.push(item.name)
						})

						var other_name = []
						var FMStype = false,
							MCStype = false,
							YBTtype = false,
							SFMAtype = false,
							RRXtype = false,
							GJtype = false
						res.data.data.detail.forEach(item => {
							if (item.cate_name == 'FMS') {
								FMStype = true
								for (let a in item.staffs) {
									this.FMS_tableData.push(item.staffs[a])
								}
							} else if (item.cate_name == 'MCS') {
								MCStype = true
								for (let a in item.staffs) {
									this.MCS_tableData.push(item.staffs[a])
								}
							} else if (item.cate_name == 'YBT下肢测试') {
								YBTtype = true
								for (let a in item.staffs) {
									this.YBT_tableDataDown.push(item.staffs[a])
								}
							} else if (item.cate_name == 'YBT上肢测试') {
								YBTtype = true
								for (let a in item.staffs) {
									this.YBT_tableDataUp.push(item.staffs[a])
								}
							} else if (item.cate_name == 'SFMA') {
								SFMAtype = true
								for (let a in item.staffs) {
									this.SFMA_tableData.push(item.staffs[a])
								}
							} else if (item.cate_name == '柔韧性') {
								RRXtype = true
								for (let a in item.staffs) {
									this.RRX_tableData.push(item.staffs[a])
								}
							} else if (item.cate_name == '关节角度') {
								GJtype = true
								for (let a in item.staffs) {
									this.GJ_tableData.push(item.staffs[a])
								}
							}
							//  else if (item.cate_name == '膝关节损伤风险筛查') {
							//   for (let a in item.staffs) {
							//     this.XGJ_tableData.push(item.staffs[a])
							//   }
							// }
							else {
								other_name.push(item.cate_name)
								this.forSelectName.push(item.cate_name)
								this.otherVariable[item.cate_name + '_tableData'] = []
								for (let a in item.staffs) {
									this.otherVariable[item.cate_name + '_tableData'].push(item.staffs[a])
								}
								// item.staffs.forEach(items => {
								//   this.otherVariable[item.cate_name + '_tableData'].push(items)
								// })
							}
						})

						if (FMStype) {
							this.activeName = 'FMS'
						} else if (MCStype) {
							this.activeName = 'MCS'
						} else if (YBTtype) {
							this.activeName = 'YBT'
						} else if (SFMAtype) {
							this.activeName = 'SFMA'
						} else if (RRXtype) {
							this.activeName = '柔韧性'
						} else if (GJtype) {
							this.activeName = '关节角度'
						} else {
							this.activeName = this.selectTextName[0]
						}
						if (this.RRX_tableData.length) {
							for (let b in this.RRX_tableData[0].norms) {
								this.RRX_selectName.push(b)
							}
							this.RRXObject = {}
							this.RRX_select = this.RRX_selectName[0]
							this.RRX_selectName.forEach(itm => {
								if (itm !== '全身旋转测试' && itm !== '抓背测试' && itm !== '抬肩测试') {
									this.RRXObject[itm] = {
										norm_uuid: '',
										norm_value: '',
										value_type: '0',
										json_value: [],
									}
								} else {
									this.RRXObject[itm] = {
										norm_uuid: '',
										norm_value: '',
										value_type: '1',
										json_value: {
											leftValue: '',
											rightValue: '',
											overValue: '',
										},
									}
								}
							})
						}
						if (this.GJ_tableData.length) {
							for (let b in this.GJ_tableData[0].norms) {
								this.GJ_selectName.push(b)
							}
							this.GJObject = {}
							this.GJ_selectName.forEach(itm => {
								this.GJObject[itm] = {
									norm_uuid: '',
									norm_value: '',
									value_type: '1',
									json_value: {
										leftValue: '',
										rightValue: '',
										overValue: '',
										beforeValue: '',
										laterValue: '',
										downValue: '',
									},
								}
							})
						}
						// if (this.XGJ_tableData.length) {
						//   for (let b in this.XGJ_tableData[0].norms) {
						//     this.XGJ_selectName.push(b)
						//   }
						//   this.XGJObject = {}
						//   this.XGJ_selectName.forEach(itm => {
						//     this.XGJObject[itm] = {
						//       norm_uuid: '',
						//       norm_value: '',
						//       value_type: '1',
						//       json_value: []
						//     }
						//   })
						// }
						if (this.MCS_tableData.length) {
							for (let b in this.MCS_tableData[0].norms) {
								this.MCS_selectName.push(b)
							}
							this.MCSObject = {}
							this.MCS_selectName.forEach(itm => {
								this.MCSObject[itm] = {
									norm_uuid: '',
									norm_value: '',
									value_type: '1',
									json_value: [],
								}
							})
						}
						if (this.YBT_tableDataDown.length) {
							var obj = []
							for (let b in this.YBT_tableDataDown[0].norms) {
								obj.push(b)
							}
							obj.forEach(itm => {
								this.YBTObjectDown[itm] = {
									norm_uuid: this.YBT_tableDataDown[0].norms[itm].norm_uuid,
									norm_value: '',
									value_type: '1',
									json_value: [],
								}
							})
						}
						if (this.YBT_tableDataUp.length) {
							var obj = []
							for (let b in this.YBT_tableDataUp[0].norms) {
								obj.push(b)
							}
							obj.forEach(itm => {
								this.YBTObjectUp[itm] = {
									norm_uuid: this.YBT_tableDataUp[0].norms[itm].norm_uuid,
									norm_value: '',
									value_type: '1',
									json_value: [],
								}
							})
						}
						if (other_name.length) {
							other_name.forEach(item => {
								this.otherVariable[item + '_selectName'] = []
								if (this.otherVariable[item + '_tableData'].length) {
									for (let b in this.otherVariable[item + '_tableData'][0].norms) {
										this.otherVariable[item + '_selectName'].push(b)
									}
									this.otherVariable[item + 'Object'] = {}
									this.otherVariable[item + '_selectName'].forEach(itm => {
										this.otherVariable[item + 'Object'][itm] = {
											norm_uuid: '',
											norm_value: '',
											value_type: '1',
											json_value: [],
										}
									})
								}
							})
						}

						if (res.data.data.ybt_read.length) {
							res.data.data.ybt_read.forEach(itm => {
								if (itm.cate_name == 'YBT上肢测试') {
									this.YBTObjectUpLook = itm
								} else if (itm.cate_name == 'YBT下肢测试') {
									this.YBTObjectDownLook = itm
								}
							})
						}

						this.sportsmanChange()
						setTimeout(() => {
							this.loading = false
						}, 1000)
					} else {
						this.loading = false
						this.$message({
							type: 'error',
							message: res.data.message,
						})
					}
				})
				.catch(error => {
					this.loading = false
					console.log(error)
				})
		}
	},
	methods: {
		handClick() {
			if (this.activeName == '柔韧性') {
				this.$nextTick(() => {
					this.$echarts.init(document.getElementById('rrxline')).resize()
				})
			} else if (this.activeName == 'YBT') {
				if (this.$route.query.name == 'look') {
					this.$nextTick(() => {
						if (this.YBTSelctName.indexOf('YBT下肢测试') !== -1) {
							this.$echarts.init(document.getElementById('YBT_downLookEcharts')).resize()
							for (var i = 0; i < Object.keys(this.YBTObjectDownLook.chart.diff).length; i++) {
								this.$echarts.init(document.getElementById('YBTDownLook' + i)).resize()
							}
						}

						if (this.YBTSelctName.indexOf('YBT上肢测试') !== -1) {
							this.$echarts.init(document.getElementById('YBT_upLookEcharts')).resize()
							for (var i = 0; i < Object.keys(this.YBTObjectDownLook.chart.diff).length; i++) {
								this.$echarts.init(document.getElementById('YBTUpLook' + i)).resize()
							}
						}
					})
				} else {
					this.$nextTick(() => {
						if (this.YBTSelctName.indexOf('YBT下肢测试') !== -1) {
							this.YBT_tableDataDown.forEach((itm, idx) => {
								this.$echarts.init(document.getElementById('YBT_downEcharts' + idx)).resize()
							})
						}
						if (this.YBTSelctName.indexOf('YBT上肢测试') !== -1) {
							this.YBT_tableDataDown.forEach((itm, idx) => {
								this.$echarts.init(document.getElementById('YBT_upEcharts' + idx)).resize()
							})
						}
					})
				}
			} else if (this.activeName == 'FMS') {
				this.$nextTick(() => {
					this.$echarts.init(document.getElementById('fmsline')).resize()
					this.$echarts.init(document.getElementById('fmsPie')).resize()
				})
			}
		},
		filterNode(value, data) {
			if (!value) return true
			return data.name.indexOf(value) !== -1
		},
		valueInput() {
			// console.log(this.FMS_tableData)
		},
		// 获取运动员列表
		getSportsMan(type) {
			this.sportsmanList = []
			this.ruleForm.sportsmanId = []
			if (!this.ruleForm.department_uuid) {
				return false
			}
			let obj = {
				department_uuid: this.ruleForm.department_uuid,
				type: 'staff',
			}
			if (this.$route.query.uuid && !type) {
				obj.uuid = this.$route.query.uuid
				obj.area = 'test'
			}
			this.$axios
				.post('p/Staff/getStaffByStation', this.$qs(obj))
				.then(res => {
					if (res.data.code == 0) {
						this.sportsmanList = res.data.data
						this.sportsmanList.unshift({
							name: '全选',
							uuid: '0',
						})
					} else {
						this.$message({
							type: 'error',
							message: res.data.message,
						})
					}
				})
				.catch(error => {
					console.log(error)
				})
		},
		// 获取地址列表
		getAddressData(name) {
			this.$axios
				.get('/p/address/all')
				.then(res => {
					if (res.data.code == 0) {
						this.addressList = res.data.data
						if (name == 'new') {
							this.ruleForm.address = this.addressList[0].uuid
						}
					} else {
						this.$message({
							type: 'error',
							message: res.data.message,
						})
					}
				})
				.catch(error => {
					console.log(error)
				})

			this.$axios
				.get('/p/test/getSFMA')
				.then(res => {
					if (res.data.code == 0) {
						this.SFMA_optionsData = res.data.data
					} else {
						this.$message({
							type: 'error',
							message: res.data.message,
						})
					}
				})
				.catch(error => {
					console.log(error)
				})
		},
		// 获取城市列表
		getCountryData() {
			this.$axios
				.get('/p/city/all')
				.then(res => {
					if (res.data.code == 0) {
						this.countryList = res.data.data
					} else {
						this.$message({
							type: 'error',
							message: res.data.message,
						})
					}
				})
				.catch(error => {
					console.log(error)
				})
		},
		countryChange(name) {
			if (name == 'country') {
				this.countryList.map(item => {
					this.addressForm.city = ''
					this.addressForm.region = ''
					this.regionList = []
					if (item.id == this.addressForm.country) {
						this.cityList = item.children
					}
				})
			} else if (name == 'city') {
				this.addressForm.region = ''
				this.cityList.map(item => {
					if (item.id == this.addressForm.city) {
						this.regionList = item.children
					}
				})
			}
		},
		// 新建地址
		newAddress() {
			this.getCountryData()
			this.dialogAddress = true
		},
		address_save() {
			this.$refs['addressForm'].validate(valid => {
				if (valid) {
					this.$axios
						.post(
							'/p/address/add',
							this.$qs({
								country_id: this.addressForm.country,
								province_id: this.addressForm.city,
								city_id: this.addressForm.region,
								address: this.addressForm.address,
							})
						)
						.then(res => {
							if (res.data.code == 0) {
								this.$message({
									type: 'success',
									message: res.data.message,
								})
								this.address_cancel()
								this.getAddressData('new')
							} else {
								this.$message({
									type: 'error',
									message: res.data.message,
								})
							}
						})
						.catch(error => {
							console.log(error)
						})
				} else {
					return false
				}
			})
		},
		address_cancel() {
			this.dialogAddress = false
			this.$refs.addressForm.resetFields()
		},
		address_del(uuid) {
			setTimeout(() => {
				this.$set(this.ruleForm, 'address', '')
			}, 0)
			this.$axios
				.post(
					'/p/address/del',
					this.$qs({
						uuid: uuid,
					})
				)
				.then(res => {
					if (res.data.code == 0) {
						this.$message({
							type: 'success',
							message: res.data.message,
						})
						this.getAddressData()
					} else {
						this.$message({
							type: 'error',
							message: res.data.message,
						})
					}
				})
				.catch(error => {
					console.log(error)
				})
		},
		// 选择测试指标
		selectTextIndex(name) {
			this.selectTreeData = JSON.parse(JSON.stringify(this.saveSelectTreeData))
			if (name !== '编辑' && this.$route.query.name !== 'look') {
				this.dialogTextIndex = true
			}
			let obj = {
				type: '1',
				keyword: this.inputValue,
			}
			if (this.$route.query.uuid) {
				obj.test_uuid = this.$route.query.uuid
			}
			this.$axios
				.post(
					'p/cate/structNormCount',
					this.$qs({
						type: '1',
					})
				)
				.then(res => {
					this.textAllNum = res.data.data
				})
			this.$axios
				.post('p/cate/structNorm', this.$qs(obj))
				.then(res => {
					if (res.data.code == 0) {
						this.load = false
						res.data.data.forEach(item => {
							if (item.builtin == 1) {
								item.norms.forEach(itm => {
									itm.builtin = 1
									itm.showCheckbox = false
								})
								item.builtin = 0
								item.showCheckbox = true
							} else {
								item.showCheckbox = true
								item.norms.forEach(itm => {
									itm.showCheckbox = true
								})
							}
						})

						var ybt_obj = {
								builtin: 1,
								edit_flag: 0,
								name: 'YBT',
								uuid: 'YBT_uuid',
								norms: [],
							},
							downIndex = -1,
							upIndex = -1
						this.oldTreeData = JSON.parse(JSON.stringify(res.data.data))

						res.data.data.forEach((itm, idx) => {
							if (itm.name == 'YBT下肢测试') {
								this.YBT_DownObject.cate_uuid = itm.uuid
								this.YBT_DownObject.cate_name = itm.name
								downIndex = idx
								ybt_obj.norms.push(itm)
								this.YBTObjectDown_copy = JSON.parse(JSON.stringify(itm.norms))
								itm.norms = []
							} else if (itm.name == 'YBT上肢测试') {
								this.YBT_UpObject.cate_uuid = itm.uuid
								this.YBT_UpObject.cate_name = itm.name
								upIndex = idx
								ybt_obj.norms.push(itm)
								this.YBTObjectUp_copy = JSON.parse(JSON.stringify(itm.norms))
								itm.norms = []
							}
						})
						if (downIndex !== -1 && upIndex !== -1) {
							res.data.data.splice(downIndex, 1, ybt_obj)
							res.data.data.splice(upIndex, 1)
						} else if (downIndex !== -1) {
							res.data.data.splice(downIndex, 1, ybt_obj)
						} else if (upIndex !== -1) {
							res.data.data.splice(upIndex, 1, ybt_obj)
						}
						// res.data.data.splice(downIndex, 1, ybt_obj)
						// res.data.data.splice(upIndex, 1)

						this.oldTreeData.forEach(item => {
							if (item.name == 'FMS') {
								// this.FMS_select_options = item.norms
								this.FMS_select_options = []
								this.FMS_object.cate_uuid = item.uuid
								this.FMS_object.cate_name = item.name
								item.norms.forEach(itm => {
									this.tableObject[itm.name].norm_uuid = itm.uuid
								})
								for (let a in this.tableObject) {
									this.FMS_select_options.push({
										name: a,
										uuid: this.tableObject[a].norm_uuid,
									})
								}
							}
							if (item.name == 'MCS') {
								this.MCS_select_options = []
								this.MCS_object.cate_uuid = item.uuid
								this.MCS_object.cate_name = item.name
								item.norms.forEach(itm => {
									this.MCSObject[itm.name].norm_uuid = itm.uuid
								})
								for (let a in this.MCSObject) {
									this.MCS_select_options.push({
										name: a,
										uuid: this.MCSObject[a].norm_uuid,
									})
								}
							}

							if (item.name == 'SFMA') {
								this.SFMA_object.cate_uuid = item.uuid
								this.SFMA_object.cate_name = item.name
								item.norms.forEach(itm => {
									this.SFMAObject[itm.name].norm_uuid = itm.uuid
								})
							}
							// 操作一
							if (item.name !== 'FMS' && item.name !== 'MCS' && item.name !== 'YBT' && item.name !== 'SFMA' && item.name !== '柔韧性' && item.name !== '关节角度') {
								this.otherVariable[item.name + '_select_options'] = []
								this.otherVariable[item.name + '_object'] = {
									cate_uuid: item.uuid,
									cate_name: item.name,
									staffs: null,
								}
								// this.otherVariable[item.name + 'Object'] = item.norms
							}
						})

						this.textTreeData = JSON.parse(JSON.stringify(res.data.data))
						this.textTreeData.forEach(item => {
							if (item.name == 'FMS' || item.name == 'SFMA' || item.name == 'MCS') {
								item.norms = []
							}
						})
						if (name !== '编辑') {
							this.$nextTick(() => {
								this.selectTreeData.forEach(item => {
									this.$refs.textTreeNode.setChecked(item.uuid, true)
									item.norms.forEach(itm => {
										this.$refs.textTreeNode.setChecked(itm.uuid, true)
									})
								})
							})
						}
					} else {
						this.$message({
							type: 'error',
							message: res.data.message,
						})
					}
				})
				.catch(error => {
					console.log(error)
				})
		},
		text_save() {
			this.dialogTextIndex = false
			this.demoSelectData = []
			this.forSelectName = []
			this.saveSelectTreeData = JSON.parse(JSON.stringify(this.selectTreeData))
			if (!this.saveSelectTreeData.length) {
				this.FMS_tableData = []
				this.YBT_tableData = []
				this.YBT_tableDataDown = []
				this.YBT_tableDataUP = []
				this.SFMA_tableData = []
				this.RRX_tableData = []
				this.GJ_tableData = []
				this.XGJ_tableData = []
				this.MCS_tableData = []
				// 操作一
				this.forSelectName.forEach((item, index) => {
					this.otherVariable[item.name + '_tableData'] = []
				})
				this.forSelectName = []
				return
			}
			this.saveSelectTreeData.forEach(item => {
				if (item.norms.length) {
					item.norms.forEach(itm => {
						this.demoSelectData.push(itm)
					})
				} else {
					this.demoSelectData.push(item)
				}
				if (item.name == '柔韧性') {
					this.RRX_selectName = []
					this.RRXObject = {}
					item.norms.forEach(itm => {
						this.RRX_selectName.push(itm.name)
						this.RRX_select = this.RRX_selectName[0]
						if (itm.name !== '全身旋转测试' && itm.name !== '抓背测试' && itm.name !== '抬肩测试') {
							this.RRXObject[itm.name] = {
								norm_uuid: '',
								norm_value: '',
								value_type: '0',
								json_value: [],
							}
						} else {
							this.RRXObject[itm.name] = {
								norm_uuid: '',
								norm_value: '',
								value_type: '1',
								json_value: {
									leftValue: '',
									rightValue: '',
									overValue: '',
								},
							}
						}
					})
				}
				if (item.name == '关节角度') {
					this.GJ_selectName = []
					this.GJObject = {}
					item.norms.forEach(itm => {
						this.GJ_selectName.push(itm.name)
						this.GJObject[itm.name] = {
							norm_uuid: '',
							norm_value: '',
							value_type: '1',
							json_value: {
								leftValue: '',
								rightValue: '',
								overValue: '',
								beforeValue: '',
								laterValue: '',
								downValue: '',
							},
						}
					})
				}
				if (item.name == 'YBT') {
					item.norms.forEach(itm => {
						var data = {}
						if (itm.name == 'YBT上肢测试') {
							this.YBTSelctName.push('YBT上肢测试')
							this.YBTObjectUp_copy.forEach(im => {
								data[im.name] = {
									norm_uuid: im.uuid,
									norm_value: '',
									value_type: '0',
									json_value: [],
								}
							})
							this.YBTObjectUp = data
						} else if (itm.name == 'YBT下肢测试') {
							this.YBTSelctName.push('YBT下肢测试')
							this.YBTObjectDown_copy.forEach(im => {
								data[im.name] = {
									norm_uuid: im.uuid,
									norm_value: '',
									value_type: '0',
									json_value: [],
								}
							})
							this.YBTObjectDown = data
						}
					})
				}
				if (item.name == 'MCS') {
					this.MCS_selectName = []
					item.norms.forEach(itm => {
						this.MCS_selectName.push(itm.name)
						this.MCSObject[itm.name] = {
							norm_uuid: '',
							norm_value: '',
							value_type: '1',
							json_value: [],
						}
					})
				}
				//   操作一
				if (item.name !== 'FMS' && item.name !== 'MCS' && item.name !== 'YBT' && item.name !== 'SFMA' && item.name !== '柔韧性' && item.name !== '关节角度') {
					this.forSelectName.push(item.name)
					this.otherVariable[item.name + '_selectName'] = []
					this.otherVariable[item.name + 'Object'] = {}
					item.norms.forEach(itm => {
						this.otherVariable[item.name + '_selectName'].push(itm.name)
						this.otherVariable[item.name + 'Object'][itm.name] = {
							norm_uuid: '',
							norm_value: '',
							value_type: '1',
							json_value: [],
						}
					})
				}
			})
			if (this.RRX_tableData.length) {
				if (this.RRX_selectName.length) {
					this.RRX_selectName.forEach(im => {
						let type = true
						for (let a in this.RRX_tableData[0].norms) {
							if (im == a) {
								type = false
							}
						}
						if (type) {
							this.RRX_tableData.forEach(itm => {
								if (im !== '全身旋转测试' && im !== '抓背测试' && im !== '抬肩测试') {
									this.$set(itm.norms, im, {
										norm_uuid: '',
										norm_value: '',
										value_type: '0',
										json_value: [],
									})
								} else {
									this.$set(itm.norms, im, {
										norm_uuid: '',
										norm_value: '',
										value_type: '1',
										json_value: {
											leftValue: '',
											rightValue: '',
											overValue: '',
										},
									})
								}
							})
						}
					})

					for (let a in this.RRX_tableData[0].norms) {
						let type = true
						this.RRX_selectName.forEach(im => {
							if (a == im) {
								type = false
							}
						})
						if (type) {
							this.RRX_tableData.forEach(i => {
								delete i.norms[a]
							})
						}
					}
				}
			}

			if (this.GJ_tableData.length) {
				if (this.GJ_selectName.length) {
					this.GJ_selectName.forEach(im => {
						let type = true
						for (let a in this.GJ_tableData[0].norms) {
							if (im == a) {
								type = false
							}
						}
						if (type) {
							this.GJ_tableData.forEach(itm => {
								this.$set(itm.norms, im, {
									norm_uuid: '',
									norm_value: '',
									value_type: '1',
									json_value: {
										leftValue: '',
										rightValue: '',
										overValue: '',
									},
								})
							})
						}
					})

					for (let a in this.GJ_tableData[0].norms) {
						let type = true
						this.GJ_selectName.forEach(im => {
							if (a == im) {
								type = false
							}
						})
						if (type) {
							this.GJ_tableData.forEach(i => {
								delete i.norms[a]
							})
						}
					}
				}
			}

			if (this.MCS_tableData.length) {
				if (this.MCS_selectName.length) {
					this.MCS_selectName.forEach(im => {
						let type = true
						for (let a in this.MCS_tableData[0].norms) {
							if (im == a) {
								type = false
							}
						}
						if (type) {
							this.MCS_tableData.forEach(itm => {
								this.$set(itm.norms, im, {
									norm_uuid: '',
									norm_value: '',
									value_type: '1',
									json_value: [],
								})
							})
						}
					})

					for (let a in this.MCS_tableData[0].norms) {
						let type = true
						this.MCS_selectName.forEach(im => {
							if (a == im) {
								type = false
							}
						})
						if (type) {
							this.MCS_tableData.forEach(i => {
								delete i.norms[a]
							})
						}
					}
				}
			}

			let nameData = []
			var that = this
			this.saveSelectTreeData.forEach((item, index) => {
				nameData.push(item.name)
				//   操作一
				if (item.name !== 'FMS' && item.name !== 'MCS' && item.name !== 'YBT' && item.name !== 'SFMA' && item.name !== '柔韧性' && item.name !== '关节角度') {
					if (that.otherVariable[item.name + '_tableData'] && that.otherVariable[item.name + '_tableData'].length) {
						if (that.otherVariable[item.name + '_selectName'].length) {
							that.otherVariable[item.name + '_selectName'].forEach(im => {
								let type = true
								for (let a in that.otherVariable[item.name + '_tableData'][0].norms) {
									if (im == a) {
										type = false
									}
								}
								if (type) {
									that.otherVariable[item.name + '_tableData'].forEach(itm => {
										that.$set(itm.norms, im, {
											norm_uuid: '',
											norm_value: '',
											value_type: '1',
											json_value: [],
										})
									})
								}
							})

							for (let a in that.otherVariable[item.name + '_tableData'][0].norms) {
								let type = true
								that.otherVariable[item.name + '_selectName'].forEach(im => {
									if (a == im) {
										type = false
									}
								})
								if (type) {
									that.otherVariable[item.name + '_tableData'].forEach(i => {
										delete i.norms[a]
									})
								}
							}
						}
					}
				}
			})
			this.selectTextName = nameData
			this.activeName = this.selectTextName[0]
			this.$forceUpdate()
			this.sportsmanChange()
		},
		demoCancel(uid, index) {
			this.demoSelectData.splice(index, 1)
			this.saveSelectTreeData.forEach((item, idx) => {
				if (item.uuid == uid) {
					this.saveSelectTreeData.splice(idx, 1)
				} else if (item.norms.length) {
					item.norms.forEach((im, ix) => {
						if (im.uuid == uid) {
							item.norms.splice(ix, 1)
						}
					})
					if (!item.norms.length) {
						this.saveSelectTreeData.splice(idx, 1)
					}
				}
			})
			if (this.saveSelectTreeData == []) {
				this.RRX_selectName = []
				this.GJ_selectName = []
				this.XGJ_selectName = []
				this.forSelectName.forEach(item => {
					this.otherVariable[item + '_selectName'] = []
				})
			}

			let nameData = []
			this.saveSelectTreeData.forEach((item, index) => {
				nameData.push(item.name)
				if (item.name == '柔韧性') {
					this.RRX_selectName = []
					item.norms.forEach(itm => {
						this.RRX_selectName.push(itm.name)
					})
				}
				if (item.name == '关节角度') {
					this.GJ_selectName = []
					item.norms.forEach(itm => {
						this.GJ_selectName.push(itm.name)
					})
				}
				if (item.name == 'YBT') {
					this.YBTSelctName = []
					item.norms.forEach(itm => {
						this.YBTSelctName.push(itm.name)
					})
				}
				if (this.forSelectName.indexOf(item.name) !== -1) {
					this.otherVariable[item.name + '_selectName'] = []
					item.norms.forEach(itm => {
						this.otherVariable[item.name + '_selectName'].push(itm.name)
					})
				}
			})
			if (this.RRX_selectName.length) {
				for (let a in this.RRX_tableData[0].norms) {
					let type = true
					this.RRX_selectName.forEach(im => {
						if (a == im) {
							type = false
						}
					})
					if (type) {
						this.RRX_tableData.forEach(i => {
							delete i.norms[a]
						})
					}
				}
			}

			if (this.GJ_selectName.length) {
				for (let a in this.GJ_tableData[0].norms) {
					let type = true
					this.GJ_selectName.forEach(im => {
						if (a == im) {
							type = false
						}
					})
					if (type) {
						this.GJ_tableData.forEach(i => {
							delete i.norms[a]
						})
					}
				}
			}

			this.selectTextName = nameData
			this.activeName = this.selectTextName[0]

			this.forSelectName = []
			// 全部的name
			this.selectTextName.map((item, index) => {
				if (item !== 'FMS' && item !== 'MCS' && item !== 'YBT' && item !== 'SFMA' && item !== '柔韧性' && item !== '关节角度') {
					// 动态的name
					this.forSelectName.push(item)
				}
			})
			// 删除动态里面指标
			this.forSelectName.forEach(item => {
				if (this.otherVariable[item + '_selectName'].length) {
					for (let a in this.otherVariable[item + '_tableData'][0].norms) {
						let type = true
						this.otherVariable[item + '_selectName'].forEach(im => {
							if (a == im) {
								type = false
							}
						})
						if (type) {
							this.otherVariable[item + '_tableData'].forEach(i => {
								delete i.norms[a]
							})
							delete this.otherVariable[item + 'Object'][a]
						}
					}
				}
			})

			this.selectTreeData = JSON.parse(JSON.stringify(this.saveSelectTreeData))
		},
		text_cancel() {
			this.dialogTextIndex = false
			this.selectTreeData = JSON.parse(JSON.stringify(this.saveSelectTreeData))
		},
		clickTreeAll() {
			if (this.selectTreeData == []) {
			}
			this.selectTreeData.forEach((item, index) => {
				this.$refs.textTreeNode.setChecked(item.uuid, false)
				item.norms.forEach(itm => {
					this.$refs.textTreeNode.setChecked(itm.uuid, false)
				})
			})
			this.selectTreeData = []
			this.selectNum = 0
		},
		textTreeCancel(node, data) {
			this.$refs.textTreeNode.setChecked(data.uuid, false)

			this.selectTreeData.forEach((item, index) => {
				if (item.uuid == data.uuid) {
					item.norms.forEach(itm => {
						this.$refs.textTreeNode.setChecked(itm.uuid, false)
					})
					this.selectTreeData.splice(index, 1)
				} else if (item.norms !== []) {
					item.norms.forEach((itm, idx) => {
						if (itm.uuid == data.uuid) {
							item.norms.splice(idx, 1)
							if (item.norms.length == 0) {
								this.selectTreeData.splice(index, 1)
							}
						}
					})
				}
			})
			this.selectNum = 0
			this.selectTreeData.forEach(item => {
				if (item.norms.length) {
					this.selectNum += item.norms.length
				} else {
					this.selectNum += 1
				}
			})
		},
		checkChange(node, data) {
			let is_fit = true

			var selectData = JSON.parse(JSON.stringify(this.selectTreeData))
			selectData.forEach((item, index) => {
				if (item.uuid == node.uuid) {
					is_fit = false
					if (item.norms.length == node.norms.length) {
						selectData.splice(index, 1)
					} else {
						item.norms = node.norms
					}
				} else if (item.norms !== []) {
					item.norms.forEach((itm, idx) => {
						if (itm.uuid == node.uuid) {
							is_fit = false
							selectData[index].norms.splice(idx, 1)
							if (item.norms.length == 0) {
								selectData.splice(index, 1)
							}
						}
					})
				}
			})
			this.selectTreeData = selectData
			if (is_fit) {
				var treeData = JSON.parse(JSON.stringify(this.textTreeData))
				treeData.forEach((im, ix) => {
					var ims = JSON.parse(JSON.stringify(im))
					if (ims.uuid == node.uuid) {
						this.selectTreeData.push(ims)
					} else if (ims.norms !== []) {
						ims.norms.forEach((i, x) => {
							if (i.uuid == node.uuid) {
								let type = true
								this.selectTreeData.forEach((o, m) => {
									if (o.uuid == ims.uuid) {
										type = false
										o.norms.push(i)
									}
								})
								if (type) {
									ims.norms = []
									ims.norms.push(i)
									this.selectTreeData.push(ims)
								}
							}
						})
					}
				})
			}
			this.selectNum = 0
			this.selectTreeData.forEach(item => {
				if (item.norms.length) {
					this.selectNum += item.norms.length
				} else {
					this.selectNum += 1
				}
			})
		},
		checkdisabled(a, b) {
			// console.log(a, b)
		},
		exportSave() {
			let detailData = []
			let a_data = JSON.parse(JSON.stringify(this.FMS_tableData))
			if (a_data.length) {
				if (this.oldman !== 3) {
					a_data.pop()
				} else {
					a_data.pop()
					a_data.pop()
				}
				this.FMS_object.staffs = a_data
				detailData.push(this.FMS_object)
			}
			// let b_data = JSON.parse(JSON.stringify(this.YBT_tableData))
			// if (b_data.length) {
			// 	if (this.YBToldman !== 3) {
			// 		b_data.pop()
			// 	} else {
			// 		b_data.pop()
			// 		b_data.pop()
			// 	}
			// 	this.YBT_object.staffs = b_data
			// 	detailData.push(this.YBT_object)
			// }
			if (this.YBT_tableDataDown.length) {
				this.YBT_DownObject.staffs = this.YBT_tableDataDown
				detailData.push(this.YBT_DownObject)
			}
			if (this.YBT_tableDataUp.length) {
				this.YBT_UpObject.staffs = this.YBT_tableDataUp
				detailData.push(this.YBT_UpObject)
			}
			let m_data = JSON.parse(JSON.stringify(this.MCS_tableData))
			if (m_data.length) {
				if (this.MCSoldman !== 3) {
					m_data.pop()
				} else {
					m_data.pop()
					m_data.pop()
				}
				this.MCS_object.staffs = m_data
				detailData.push(this.MCS_object)
			}
			if (this.SFMA_tableData.length) {
				this.SFMA_object.staffs = this.SFMA_tableData
				detailData.push(this.SFMA_object)
			}
			let c_data = JSON.parse(JSON.stringify(this.RRX_tableData))
			if (c_data.length) {
				if (this.RRXoldman !== 3) {
					c_data.pop()
				} else {
					c_data.pop()
					c_data.pop()
				}
				this.oldTreeData.forEach(item => {
					if (item.name == '柔韧性') {
						item.norms.forEach(itm => {
							this.RRX_object.cate_uuid = item.uuid
							this.RRX_object.cate_name = item.name
							c_data.forEach(im => {
								for (let a in im.norms) {
									if (a == itm.name) {
										im.norms[a].norm_uuid = itm.uuid
									}
								}
							})
						})
					}
				})
				this.RRX_object.staffs = c_data
				detailData.push(this.RRX_object)
			}
			let d_data = JSON.parse(JSON.stringify(this.GJ_tableData))
			if (d_data.length) {
				if (this.GJoldman !== 3) {
					d_data.pop()
				} else {
					d_data.pop()
					d_data.pop()
				}
				this.oldTreeData.forEach(item => {
					if (item.name == '关节角度') {
						item.norms.forEach(itm => {
							this.GJ_object.cate_uuid = item.uuid
							this.GJ_object.cate_name = item.name
							d_data.forEach(im => {
								for (let a in im.norms) {
									if (a == itm.name) {
										im.norms[a].norm_uuid = itm.uuid
									}
								}
							})
						})
					}
				})
				this.GJ_object.staffs = d_data
				detailData.push(this.GJ_object)
			}

			this.forSelectName.forEach(item => {
				let new_data = this.otherVariable[item + '_tableData']
				if (new_data.length) {
					if (this.otherVariable[item + 'oldman'] !== 3) {
						new_data.pop()
					} else {
						new_data.pop()
						new_data.pop()
					}
					this.oldTreeData.forEach(c_item => {
						if (c_item.name == item) {
							c_item.norms.forEach(itm => {
								this.otherVariable[item + '_object'].cate_uuid = c_item.uuid
								this.otherVariable[item + '_object'].cate_name = c_item.name
								new_data.forEach(im => {
									for (let a in im.norms) {
										if (a == itm.name) {
											im.norms[a].norm_uuid = itm.uuid
										}
									}
								})
							})
						}
					})
					this.otherVariable[item + '_object'].staffs = new_data
					detailData.push(this.otherVariable[item + '_object'])
				}
			})

			// 过滤YBT分类格式
			var YBT_Cate = []
			this.selectTreeData.forEach((im, ix) => {
				if (im.name == 'YBT') {
					YBT_Cate = im.norms
					this.selectTreeData.splice(ix, 1)
				}
			})
			this.selectTreeData.push(...YBT_Cate)

			// 过滤
			var forDedtailData = JSON.parse(JSON.stringify(detailData))
			detailData.forEach((item, index) => {
				var ftype = true,
					cname = item.cate_name
				this.selectTreeData.forEach((itm, idx) => {
					if (item.cate_name == itm.name) {
						ftype = false
					}
				})
				if (ftype) {
					forDedtailData.forEach((item, ix) => {
						if (item.cate_name == cname) {
							forDedtailData.splice(ix, 1)
						}
					})
				}
			})
			detailData = JSON.parse(JSON.stringify(forDedtailData))
			let url = 'bodyfunc/add'
			let data = {
				title: this.ruleForm.title,
				test_date: this.ruleForm.date,
				department_uuid: this.ruleForm.department_uuid,
				staff_uuid_str: this.ruleForm.sportsmanId.toString(','),
				coach: this.ruleForm.coach,
				address_uuid: this.ruleForm.address,
				type: '1',
				demo: this.ruleForm.demo,
				cate_norm: JSON.stringify(this.selectTreeData),
				detail: JSON.stringify(detailData),
				summary: this.editorContent,
			}
			if (this.$route.query.uuid) {
				url = 'bodyfunc/update'
				data.uuid = this.$route.query.uuid
			}
			this.$axios
				.post(url, this.$qs(data))
				.then(res => {
					if (res.data.code == 0) {
						this.$message({
							type: 'success',
							message: res.data.message,
						})
						this.$router.push({ path: '/textItem/bodyFunction', query: { type: 1, page: this.$route.query.page } })
					} else {
						if (res.data.msg !== undefined) {
							this.$message({
								message: res.data.msg.message,
								type: 'warning',
							})
						} else {
							this.$utils.message({
								type: 'error',
								message: res.data.message,
							})
						}
					}
				})
				.catch(error => {
					console.log(error)
				})
		},

		delterCates(name, index, ix) {
			if (name == 'f') {
				this.GJ_tableData.forEach(item => {
					item.cates.splice(index, 1)
				})
			} else {
				this.GJ_tableData.forEach(item => {
					item.cates[index].norms.splice(ix, 1)
					if (!item.cates[index].norms.length) {
						item.cates.splice(index, 1)
					}
				})
			}
		},

		// FMS
		spanMethod({ row, column, rowIndex, columnIndex }) {
			if (row.staff_name == '男子-团队平均值' || row.staff_name == '女子-团队平均值') {
				if (columnIndex === 0) {
					return [0, 0]
				} else if (columnIndex === 1) {
					return [1, 3]
				} else if (columnIndex === 2) {
					return [0, 0]
				}
			}
		},
		FMSValueChange(name, index, tableName, o_name) {
			if (tableName !== 'GJ_tableData') {
				if (
					tableName !== 'FMS_tableData' &&
					tableName !== 'RRX_tableData' &&
					tableName !== 'GJ_tableData' &&
					tableName !== 'XGJ_tableData' &&
					tableName !== 'MCS_tableData' &&
					tableName !== 'SFMA_tableData' &&
					tableName !== 'YBT_tableData'
				) {
					let leftValue = this.otherVariable[tableName][index].norms[name].json_value.leftValue == '' ? 0 : this.otherVariable[tableName][index].norms[name].json_value.leftValue
					let rightValue = this.otherVariable[tableName][index].norms[name].json_value.rightValue == '' ? 0 : this.otherVariable[tableName][index].norms[name].json_value.rightValue
					if (Number(leftValue) < Number(rightValue)) {
						this.otherVariable[tableName][index].norms[name].json_value.overValue = leftValue
					} else {
						this.otherVariable[tableName][index].norms[name].json_value.overValue = rightValue
					}
				} else {
					if (name == '深蹲') {
						this[tableName][index].norms[name].json_value.overValue = this[tableName][index].norms[name].json_value.leftValue
					} else if (name == '躯干稳定俯卧撑') {
						let leftPainValue = this[tableName][index].norms[name].json_value.leftPainValue == '' ? 0 : this[tableName][index].norms[name].json_value.leftPainValue
						if (leftPainValue == '+') {
							this[tableName][index].norms[name].json_value.overValue = 0
						} else {
							this[tableName][index].norms[name].json_value.overValue = this[tableName][index].norms[name].json_value.leftValue
						}
					} else if (name == '直线弓步蹲' || name == '肩部灵活性' || name == '旋转稳定性') {
						let leftValue = this[tableName][index].norms[name].json_value.leftValue == '' ? 0 : this[tableName][index].norms[name].json_value.leftValue
						let rightValue =
							this[tableName][index].norms[name] && this[tableName][index].norms[name].json_value.rightValue == '' ? 0 : this[tableName][index].norms[name].json_value.rightValue
						let leftPainValue = this[tableName][index].norms[name].json_value.leftPainValue == '' ? 0 : this[tableName][index].norms[name].json_value.leftPainValue
						let rightPainValue =
							this[tableName][index].norms[name] && this[tableName][index].norms[name].json_value.rightPainValue == '' ? 0 : this[tableName][index].norms[name].json_value.rightPainValue
						if (leftPainValue == '+' || rightPainValue == '+') {
							this[tableName][index].norms[name].json_value.overValue = 0
						} else if (leftPainValue == '-' || !leftPainValue || !rightPainValue) {
							if (Number(leftValue) < Number(rightValue)) {
								this[tableName][index].norms[name].json_value.overValue = leftValue
							} else {
								this[tableName][index].norms[name].json_value.overValue = rightValue
							}
						}
					} else if (name == '踝关节排除测试-灵活型') {
						let arr = ['G', 'Y', 'R']
						let leftValue = this[tableName][index].norms[name].json_value.leftValue == '' ? 0 : this[tableName][index].norms[name].json_value.leftValue
						let rightValue =
							this[tableName][index].norms[name] && this[tableName][index].norms[name].json_value.rightValue == '' ? 0 : this[tableName][index].norms[name].json_value.rightValue
						if (arr.indexOf(leftValue) < arr.indexOf(rightValue)) {
							this[tableName][index].norms[name].json_value.overValue = rightValue
						} else {
							this[tableName][index].norms[name].json_value.overValue = leftValue
						}
					} else if (tableName == 'RRX_tableData') {
						if (name == '抬肩测试') {
							let leftValue = this[tableName][index].norms[name].json_value.leftValue == '' ? 0 : this[tableName][index].norms[name].json_value.leftValue
							let rightValue = this[tableName][index].norms[name].json_value.rightValue == '' ? 0 : this[tableName][index].norms[name].json_value.rightValue
							this[tableName][index].norms[name].json_value.overValue = ((Number(rightValue) / Number(leftValue)) * 100).toFixed(1)
						} else if (name !== '全身旋转测试' && name !== '抓背测试' && name !== '抬肩测试') {
						} else {
							let leftValue = this[tableName][index].norms[name].json_value.leftValue == '' ? 0 : this[tableName][index].norms[name].json_value.leftValue
							let rightValue = this[tableName][index].norms[name].json_value.rightValue == '' ? 0 : this[tableName][index].norms[name].json_value.rightValue
							this[tableName][index].norms[name].json_value.overValue = (Number(leftValue) + Number(rightValue)) / 2
						}
					} else {
						let leftValue = this[tableName][index].norms[name].json_value.leftValue == '' ? 0 : this[tableName][index].norms[name].json_value.leftValue
						let rightValue = this[tableName][index].norms[name].json_value.rightValue == '' ? 0 : this[tableName][index].norms[name].json_value.rightValue
						if (Number(leftValue) < Number(rightValue)) {
							this[tableName][index].norms[name].json_value.overValue = leftValue
						} else {
							this[tableName][index].norms[name].json_value.overValue = rightValue
						}
					}
					if (tableName == 'FMS_tableData') {
						this[tableName][index].total_value = 0
						for (let obj in this[tableName][index].norms) {
							var value = Number(this[tableName][index].norms[obj].json_value.overValue)
							if (value) {
								this[tableName][index].total_value = Number(Number(this[tableName][index].total_value) + Number(this[tableName][index].norms[obj].json_value.overValue))
							}
						}
					}
				}
			}
			setTimeout(() => {
				this.ALLMean(tableName, o_name)
			}, 0)
			if (tableName == 'FMS_tableData') {
				this.FMSLine()
			} else if (tableName == 'RRX_tableData') {
				if (this.RRX_select == '坐位体前屈') {
					this.$echarts.init(document.getElementById('rrxline')).dispose()
					this.RRXLine()
				} else if (this.RRX_select == '抬肩测试') {
					this.$echarts.init(document.getElementById('rrxline')).dispose()
					this.RRXLineTai()
				} else {
					this.$echarts.init(document.getElementById('rrxline')).dispose()
					this.RRXLineElse()
				}
			}
		},
		ruleValueChange(name, index, tableName, valueName) {
			// 获取到了 YBT 输入框的内容
			// 进行规则校验
			let type = ''
			this.oldTreeData.forEach(item => {
				item.norms.forEach(itm => {
					if (itm.name == name) {
						type = itm.value_type
						return false
					}
				})
			})
			if (tableName == 'FMS_tableData' || tableName == 'RRX_tableData' || tableName == 'GJ_tableData') {
				if (name !== '全身旋转测试' && name !== '抓背测试' && name !== '抬肩测试' && tableName == 'RRX_tableData') {
					let value = this[tableName][index].norms[name].norm_value
					if (type == '1') {
						var regExp = /^[0-9][0-9]*[.]?([0-9]+)?$/
						if (!regExp.test(value)) {
							this[tableName][index].norms[name].norm_value = ''
						}
					}
				} else if (valueName == 'rightPainValue' || valueName == 'leftPainValue') {
					var value = this[tableName][index].norms[name].json_value[valueName]
					if (type == '1') {
						var regExp = /[+|-]/
						if (!regExp.test(value)) {
							this[tableName][index].norms[name].json_value[valueName] = ''
						}
					}
				} else if (name == '踝关节排除测试-灵活型') {
					var value = this[tableName][index].norms[name].json_value[valueName]
					var regExp = /[GYRgyr]/
					if (!regExp.test(value)) {
						this[tableName][index].norms[name].json_value[valueName] = ''
					} else {
						this[tableName][index].norms[name].json_value[valueName] = value.toUpperCase()
					}
				} else {
					let value = this[tableName][index].norms[name].json_value[valueName]
					if (type == '1') {
						var regExp = /^[0-9][0-9]*[.]?([0-9]+)?$/
						if (!regExp.test(value)) {
							this[tableName][index].norms[name].json_value[valueName] = ''
						}
					}
				}
			} else if (tableName == 'YBT_tableDataDown' || tableName == 'YBT_tableDataUp') {
				var value = this[tableName][index].norms[name].norm_value
				if (type == '1') {
					var regExp = /^[0-9][0-9]*[.]?([0-9])?$/
					if (!regExp.test(value)) {
						this[tableName][index].norms[name].norm_value = ''
					}
				}
			}
		},
		sportsmanChange() {
			let index = this.ruleForm.sportsmanId.indexOf('0')
			if (index !== -1) {
				let data = []
				this.sportsmanList.forEach(itm => {
					data.push(itm.uuid)
				})
				this.ruleForm.sportsmanId = data
				this.ruleForm.sportsmanId.splice(0, 1)
			}

			if (this.selectTextName.indexOf('FMS') !== -1) {
				/*
          @sportsmanList           运动员数据 [uuid, uuid, uuid]
          @ruleForm.sportsmanId    选中的运动员数据 [{}, {}, {}]
          @FMS_tableData           FMS 表格数据 []
        */
				if (this.oldman == 1 || this.oldman == 2) {
					this.FMS_tableData.pop()
				} else if (this.oldman == 3) {
					this.FMS_tableData.pop()
					this.FMS_tableData.pop()
				}
				this.ruleForm.sportsmanId.forEach(item => {
					this.sportsmanList.forEach(itm => {
						if (item == itm.uuid) {
							let type = true
							this.FMS_tableData.forEach((im, ix) => {
								if (im.staff_uuid == item) {
									type = false
								}
							})
							if (type) {
								this.FMS_tableData.push({
									staff_uuid: itm.uuid,
									staff_name: itm.name,
									sex: itm.sex,
									total_value: '',
									norms: JSON.parse(JSON.stringify(this.tableObject)),
								})
							}
						}
					})
				})
				this.FMS_tableData.forEach((item, index) => {
					let o_type = true
					this.ruleForm.sportsmanId.forEach(itm => {
						if (item.staff_uuid == itm) {
							o_type = false
						}
					})
					if (o_type) {
						this.FMS_tableData.splice(index, 1)
					}
				})
				let man = 0
				this.FMS_tableData.forEach((item, index) => {
					if (!man) {
						man = item.sex
					}
					if (item.sex !== man) {
						man = 3
					}
				})
				if (man == 1) {
					this.FMS_tableData.push({
						staff_name: '男子-团队平均值',
						sex: '1',
						total_value: '',
						norms: JSON.parse(JSON.stringify(this.tableObject)),
					})
				} else if (man == 2) {
					this.FMS_tableData.push({
						staff_name: '女子-团队平均值',
						sex: '2',
						total_value: '',
						norms: JSON.parse(JSON.stringify(this.tableObject)),
					})
				} else if (man == 3) {
					this.FMS_tableData.push({
						staff_name: '男子-团队平均值',
						sex: '1',
						total_value: '',
						norms: JSON.parse(JSON.stringify(this.tableObject)),
					})
					this.FMS_tableData.push({
						staff_name: '女子-团队平均值',
						sex: '2',
						total_value: '',
						norms: JSON.parse(JSON.stringify(this.tableObject)),
					})
				}
				this.oldman = man
				setTimeout(() => {
					this.ALLMean('FMS_tableData')
				}, 0)
				this.$nextTick(() => {
					this.FMS_select = ''
					this.FMS_sex = ''
					this.FMSLine()
				})
			}
			if (this.selectTextName.indexOf('YBT') !== -1) {
				/*
          @sportsmanList           运动员数据 [uuid, uuid, uuid]
          @ruleForm.sportsmanId    选中的运动员数据 [{}, {}, {}]
          @YBT_tableData           YBT 表格数据 []
        */
				// if (this.YBToldman == 1 || this.YBToldman == 2) {
				// 	this.YBT_tableData.pop()
				// } else if (this.YBToldman == 3) {
				// 	this.YBT_tableData.pop()
				// 	this.YBT_tableData.pop()
				// }
				if (this.YBTSelctName.indexOf('YBT上肢测试') !== -1) {
					this.ruleForm.sportsmanId.forEach(item => {
						this.sportsmanList.forEach(itm => {
							if (item == itm.uuid) {
								let type = true
								this.YBT_tableDataUp.forEach((im, ix) => {
									if (im.staff_uuid == item) {
										type = false
									}
								})
								if (type) {
									this.YBT_tableDataUp.push({
										staff_uuid: itm.uuid,
										staff_name: itm.name,
										sex: itm.sex,
										norms: JSON.parse(JSON.stringify(this.YBTObjectUp)),
									})
								}
							}
						})
					})
					this.$nextTick(() => {
						this.YBT_tableDataUp.forEach((item, index) => {
							let o_type = true
							this.ruleForm.sportsmanId.forEach(itm => {
								if (item.staff_uuid == itm) {
									o_type = false
								}
							})
							if (o_type) {
								this.YBT_tableDataUp.splice(index, 1)
							}

							if (this.$route.query.name == 'look') {
								if (this.YBTSelctName.indexOf('YBT上肢测试') !== -1) {
									this.YBTUpLineLook('', index)

									var idx = -1
									for (var i in this.YBTObjectUpLook.chart.diff) {
										++idx
										this.YBTUpBarLook(idx, i, this.YBTObjectUpLook.chart.diff[i])
									}
								}
							} else {
								this.$nextTick(() => {
									this.YBTUpLine('', index)
								})
							}
						})
					})
				}

				if (this.YBTSelctName.indexOf('YBT下肢测试') !== -1) {
					this.ruleForm.sportsmanId.forEach(item => {
						this.sportsmanList.forEach(itm => {
							if (item == itm.uuid) {
								let type = true
								this.YBT_tableDataDown.forEach((im, ix) => {
									if (im.staff_uuid == item) {
										type = false
									}
								})
								if (type) {
									this.YBT_tableDataDown.push({
										staff_uuid: itm.uuid,
										staff_name: itm.name,
										sex: itm.sex,
										norms: JSON.parse(JSON.stringify(this.YBTObjectDown)),
									})
								}
							}
						})
					})
					this.$nextTick(() => {
						this.YBT_tableDataDown.forEach((item, index) => {
							let o_type = true
							this.ruleForm.sportsmanId.forEach(itm => {
								if (item.staff_uuid == itm) {
									o_type = false
								}
							})
							if (o_type) {
								this.YBT_tableDataDown.splice(index, 1)
							}
							if (this.$route.query.name == 'look') {
								if (this.YBTSelctName.indexOf('YBT下肢测试') !== -1) {
									this.$nextTick(() => {
										this.YBTDownLineLook('', index)
									})

									var idx = -1
									for (var i in this.YBTObjectDownLook.chart.diff) {
										idx++
										this.YBTDownBarLook(idx, i, this.YBTObjectDownLook.chart.diff[i])
									}
								}
							} else {
								this.$nextTick(() => {
									this.YBTDownLine('', index)
								})
							}
						})
					})
				}
			}
			if (this.selectTextName.indexOf('MCS') !== -1) {
				/*
          @sportsmanList           运动员数据 [uuid, uuid, uuid]
          @ruleForm.sportsmanId    选中的运动员数据 [{}, {}, {}]
          @YBT_tableData           YBT 表格数据 []
        */
				if (this.MCSoldman == 1 || this.MCSoldman == 2) {
					this.MCS_tableData.pop()
				} else if (this.MCSoldman == 3) {
					this.MCS_tableData.pop()
					this.MCS_tableData.pop()
				}
				this.ruleForm.sportsmanId.forEach(item => {
					this.sportsmanList.forEach(itm => {
						if (item == itm.uuid) {
							let type = true
							this.MCS_tableData.forEach((im, ix) => {
								if (im.staff_uuid == item) {
									type = false
								}
							})
							if (type) {
								this.MCS_tableData.push({
									staff_uuid: itm.uuid,
									staff_name: itm.name,
									sex: itm.sex,
									norms: JSON.parse(JSON.stringify(this.MCSObject)),
								})
							}
						}
					})
				})
				this.MCS_tableData.forEach((item, index) => {
					let o_type = true
					this.ruleForm.sportsmanId.forEach(itm => {
						if (item.staff_uuid == itm) {
							o_type = false
						}
					})
					if (o_type) {
						this.MCS_tableData.splice(index, 1)
					}
				})
				let man = 0
				this.MCS_tableData.forEach((item, index) => {
					if (!man) {
						man = item.sex
					}
					if (item.sex !== man) {
						man = 3
					}
				})

				if (man == 1) {
					this.MCS_tableData.push({
						staff_name: '男子-团队平均值',
						sex: '1',
						norms: JSON.parse(JSON.stringify(this.MCSObject)),
					})
				} else if (man == 2) {
					this.MCS_tableData.push({
						staff_name: '女子-团队平均值',
						sex: '2',
						norms: JSON.parse(JSON.stringify(this.MCSObject)),
					})
				} else if (man == 3) {
					this.MCS_tableData.push({
						staff_name: '男子-团队平均值',
						sex: '1',
						norms: JSON.parse(JSON.stringify(this.MCSObject)),
					})
					this.MCS_tableData.push({
						staff_name: '女子-团队平均值',
						sex: '2',
						norms: JSON.parse(JSON.stringify(this.MCSObject)),
					})
				}
				this.MCSoldman = man
				setTimeout(() => {
					this.MCSMean('MCS_tableData')
				}, 0)
				// this.$nextTick(() => {
				//   this.MCS_sex = ''
				//   this.MCSLine()
				// })
			}
			if (this.selectTextName.indexOf('SFMA') !== -1) {
				this.ruleForm.sportsmanId.forEach(item => {
					this.sportsmanList.forEach(itm => {
						if (item == itm.uuid) {
							let type = true
							this.SFMA_tableData.forEach((im, ix) => {
								if (im.staff_uuid == item) {
									type = false
								}
							})
							if (type) {
								this.SFMA_tableData.push({
									staff_uuid: itm.uuid,
									staff_name: itm.name,
									sex: itm.sex,
									norms: JSON.parse(JSON.stringify(this.SFMAObject)),
								})
							}
						}
					})
				})
				this.SFMA_tableData.forEach((item, index) => {
					let o_type = true
					this.ruleForm.sportsmanId.forEach(itm => {
						if (item.staff_uuid == itm) {
							o_type = false
						}
					})
					if (o_type) {
						this.SFMA_tableData.splice(index, 1)
					}
				})
			}
			if (this.selectTextName.indexOf('柔韧性') !== -1) {
				if (this.RRXoldman == 1 || this.RRXoldman == 2) {
					this.RRX_tableData.pop()
				} else if (this.RRXoldman == 3) {
					this.RRX_tableData.pop()
					this.RRX_tableData.pop()
				}
				this.ruleForm.sportsmanId.forEach(item => {
					this.sportsmanList.forEach(itm => {
						if (item == itm.uuid) {
							let type = true
							this.RRX_tableData.forEach((im, ix) => {
								if (im.staff_uuid == item) {
									type = false
								}
							})
							if (type) {
								this.RRX_tableData.push({
									staff_uuid: itm.uuid,
									staff_name: itm.name,
									sex: itm.sex,
									total_value: '',
									norms: JSON.parse(JSON.stringify(this.RRXObject)),
								})
							}
						}
					})
				})
				this.RRX_tableData.forEach((item, index) => {
					let o_type = true
					this.ruleForm.sportsmanId.forEach(itm => {
						if (item.staff_uuid == itm) {
							o_type = false
						}
					})
					if (o_type) {
						this.RRX_tableData.splice(index, 1)
					}
				})
				var man = 0
				this.RRX_tableData.forEach((item, index) => {
					if (!man) {
						man = item.sex
					}
					if (item.sex !== man) {
						man = 3
					}
				})
				if (man == 1) {
					this.RRX_tableData.push({
						staff_name: '男子-团队平均值',
						sex: '1',
						total_value: '',
						norms: JSON.parse(JSON.stringify(this.RRXObject)),
					})
				} else if (man == 2) {
					this.RRX_tableData.push({
						staff_name: '女子-团队平均值',
						sex: '2',
						total_value: '',
						norms: JSON.parse(JSON.stringify(this.RRXObject)),
					})
				} else if (man == 3) {
					this.RRX_tableData.push({
						staff_name: '男子-团队平均值',
						sex: '1',
						total_value: '',
						norms: JSON.parse(JSON.stringify(this.RRXObject)),
					})
					this.RRX_tableData.push({
						staff_name: '女子-团队平均值',
						sex: '2',
						total_value: '',
						norms: JSON.parse(JSON.stringify(this.RRXObject)),
					})
				}
				this.RRXoldman = man
				setTimeout(() => {
					this.ALLMean('RRX_tableData')
				}, 0)
				this.$nextTick(() => {
					this.RRX_sex = ''
					if (this.RRX_select == '坐位体前屈') {
						this.RRXLine()
					} else if (this.RRX_select == '抬肩测试') {
						this.RRXLineTai()
					} else {
						this.RRXLineElse()
					}
				})
			}
			if (this.selectTextName.indexOf('关节角度') !== -1) {
				if (this.GJoldman == 1 || this.GJoldman == 2) {
					this.GJ_tableData.pop()
				} else if (this.GJoldman == 3) {
					this.GJ_tableData.pop()
					this.GJ_tableData.pop()
				}
				this.ruleForm.sportsmanId.forEach(item => {
					this.sportsmanList.forEach(itm => {
						if (item == itm.uuid) {
							let type = true
							this.GJ_tableData.forEach((im, ix) => {
								if (im.staff_uuid == item) {
									type = false
								}
							})
							if (type) {
								this.GJ_tableData.push({
									staff_uuid: itm.uuid,
									staff_name: itm.name,
									sex: itm.sex,
									total_value: '',
									norms: JSON.parse(JSON.stringify(this.GJObject)),
								})
							}
						}
					})
				})
				this.GJ_tableData.forEach((item, index) => {
					let o_type = true
					this.ruleForm.sportsmanId.forEach(itm => {
						if (item.staff_uuid == itm) {
							o_type = false
						}
					})
					if (o_type) {
						this.GJ_tableData.splice(index, 1)
					}
				})
				var man = 0
				this.GJ_tableData.forEach((item, index) => {
					if (!man) {
						man = item.sex
					}
					if (item.sex !== man) {
						man = 3
					}
				})
				if (man == 1) {
					this.GJ_tableData.push({
						staff_name: '男子-团队平均值',
						sex: '1',
						total_value: '',
						norms: JSON.parse(JSON.stringify(this.GJObject)),
					})
				} else if (man == 2) {
					this.GJ_tableData.push({
						staff_name: '女子-团队平均值',
						sex: '2',
						total_value: '',
						norms: JSON.parse(JSON.stringify(this.GJObject)),
					})
				} else if (man == 3) {
					this.GJ_tableData.push({
						staff_name: '男子-团队平均值',
						sex: '1',
						total_value: '',
						norms: JSON.parse(JSON.stringify(this.GJObject)),
					})
					this.GJ_tableData.push({
						staff_name: '女子-团队平均值',
						sex: '2',
						total_value: '',
						norms: JSON.parse(JSON.stringify(this.GJObject)),
					})
				}
				this.GJoldman = man
				setTimeout(() => {
					this.ALLMean('GJ_tableData')
				}, 0)
			}

			this.forSelectName.length &&
				this.forSelectName.forEach(n_item => {
					if (this.otherVariable[n_item + 'oldman'] == 1 || this.otherVariable[n_item + 'oldman'] == 2) {
						this.otherVariable[n_item + '_tableData'].pop()
					} else if (this.otherVariable[n_item + 'oldman'] == 3) {
						this.otherVariable[n_item + '_tableData'].pop()
						this.otherVariable[n_item + '_tableData'].pop()
					}
					this.ruleForm.sportsmanId.forEach(item => {
						if (!this.otherVariable[n_item + '_tableData']) {
							this.otherVariable[n_item + '_tableData'] = []
						}
						this.sportsmanList.forEach(itm => {
							if (item == itm.uuid) {
								let type = true

								this.otherVariable[n_item + '_tableData'].forEach((im, ix) => {
									if (im.staff_uuid == item) {
										type = false
									}
								})
								if (type) {
									this.otherVariable[n_item + '_tableData'].push({
										staff_uuid: itm.uuid,
										staff_name: itm.name,
										sex: itm.sex,
										total_value: '',
										norms: JSON.parse(JSON.stringify(this.otherVariable[n_item + 'Object'])),
									})
								}
							}
						})
					})
					this.otherVariable[n_item + '_tableData'].forEach((item, index) => {
						let o_type = true
						this.ruleForm.sportsmanId.forEach(itm => {
							if (item.staff_uuid == itm) {
								o_type = false
							}
						})
						if (o_type) {
							this.otherVariable[n_item + '_tableData'].splice(index, 1)
						}
					})
					var man = 0
					this.otherVariable[n_item + '_tableData'].forEach((item, index) => {
						if (!man) {
							man = item.sex
						}
						if (item.sex !== man) {
							man = 3
						}
					})
					if (man == 1) {
						this.otherVariable[n_item + '_tableData'].push({
							staff_name: '男子-团队平均值',
							sex: '1',
							total_value: '',
							norms: JSON.parse(JSON.stringify(this.otherVariable[n_item + 'Object'])),
						})
					} else if (man == 2) {
						this.otherVariable[n_item + '_tableData'].push({
							staff_name: '女子-团队平均值',
							sex: '2',
							total_value: '',
							norms: JSON.parse(JSON.stringify(this.otherVariable[n_item + 'Object'])),
						})
					} else if (man == 3) {
						this.otherVariable[n_item + '_tableData'].push({
							staff_name: '男子-团队平均值',
							sex: '1',
							total_value: '',
							norms: JSON.parse(JSON.stringify(this.otherVariable[n_item + 'Object'])),
						})
						this.otherVariable[n_item + '_tableData'].push({
							staff_name: '女子-团队平均值',
							sex: '2',
							total_value: '',
							norms: JSON.parse(JSON.stringify(this.otherVariable[n_item + 'Object'])),
						})
					}
					this.otherVariable[n_item + 'oldman'] = man
					setTimeout(() => {
						this.MCSMean(n_item + '_tableData', n_item)
					}, 0)
				})
		},
		ALLMean(tableName, c_name) {
			var nameData = []
			var new_data = []

			if (
				tableName !== 'FMS_tableData' &&
				tableName !== 'RRX_tableData' &&
				tableName !== 'GJ_tableData' &&
				tableName !== 'XGJ_tableData' &&
				tableName !== 'MCS_tableData' &&
				tableName !== 'SFMA_tableData' &&
				tableName !== 'YBT_tableData'
			) {
				new_data = this.otherVariable[tableName]
				nameData = this.otherVariable[c_name + '_selectName']
			} else {
				new_data = this[tableName]
			}
			if (tableName == 'FMS_tableData') {
				nameData = ['深蹲', '跨栏步', '直线弓步蹲', '肩部灵活性', '踝关节排除测试-灵活型', '主动直腿上抬', '躯干稳定俯卧撑', '旋转稳定性']
			} else if (tableName == 'RRX_tableData') {
				nameData = this.RRX_selectName
			} else if (tableName == 'GJ_tableData') {
				nameData = this.GJ_selectName
			} else if (tableName == 'XGJ_tableData') {
				nameData = this.XGJ_selectName
			}

			let manIndex = -1,
				womanIndex = -1
			new_data.forEach((item, index) => {
				if (item.staff_name == '男子-团队平均值') {
					manIndex = index
					item.total_value = ''
				}
				if (item.staff_name == '女子-团队平均值') {
					womanIndex = index
					item.total_value = ''
				}
			})
			nameData.forEach(item => {
				if (
					item !== '全身旋转测试' &&
					item !== '抓背测试' &&
					item !== '抬肩测试' &&
					item !== '主动直腿上抬' &&
					item !== '旋转稳定性' &&
					item !== '直线弓步蹲' &&
					item !== '肩部灵活性' &&
					item !== '跨栏步' &&
					item !== '踝关节排除测试-灵活型' &&
					item !== '躯干稳定俯卧撑' &&
					item !== '深蹲'
				) {
					var manNormNum = 0,
						womanNormNum = 0
					if (manIndex != -1) {
						new_data[manIndex].norms[item].norm_value = ''
					}
					if (womanIndex != -1) {
						new_data[womanIndex].norms[item].norm_value = ''
					}
					new_data.forEach(itm => {
						if (itm.sex == '1' && itm.staff_name !== '男子-团队平均值') {
							if (itm.norms[item].norm_value !== '' && itm.norms[item].norm_value !== null && itm.norms[item].norm_value !== 'null') {
								manNormNum++
								new_data[manIndex].norms[item].norm_value = Number(new_data[manIndex].norms[item].norm_value) + Number(itm.norms[item].norm_value)
							}
						}
						if (itm.sex == '2' && itm.staff_name !== '女子-团队平均值') {
							if (itm.norms[item].norm_value !== '' && itm.norms[item].norm_value !== null && itm.norms[item].norm_value !== 'null') {
								womanNormNum++
								new_data[womanIndex].norms[item].norm_value = Number(new_data[womanIndex].norms[item].norm_value) + Number(itm.norms[item].norm_value)
							}
						}
					})
					if (manNormNum) {
						new_data[manIndex].norms[item].norm_value = (new_data[manIndex].norms[item].norm_value / manNormNum).toFixed(1)
					}
					if (womanNormNum) {
						this.$set(new_data[womanIndex].norms[item], 'norm_value', (new_data[womanIndex].norms[item].norm_value / womanNormNum).toFixed(1))
						// new_data[womanIndex].norms[item].norm_value = (new_data[womanIndex].norms[item].norm_value / womanNormNum).toFixed(1)
					}
				} else {
					var manLeftNum = 0,
						manRightNum = 0,
						manOverNum = 0,
						womanLeftNum = 0,
						womanRightNum = 0,
						womanOverNum = 0
					// GJ_tableData
					var manBeforeNum = 0,
						manLaterNum = 0,
						manDownNum = 0,
						womanBeforeNum = 0,
						womanLaterNum = 0,
						womanDownNum = 0
					if (manIndex !== -1) {
						new_data[manIndex].norms[item].json_value.leftValue = ''
						new_data[manIndex].norms[item].json_value.rightValue = ''
						new_data[manIndex].norms[item].json_value.overValue = ''
						if (tableName == 'GJ_tableData') {
							new_data[manIndex].norms[item].json_value.beforeValue = ''
							new_data[manIndex].norms[item].json_value.laterValue = ''
							new_data[manIndex].norms[item].json_value.downValue = ''
						}
					}
					if (womanIndex !== -1) {
						new_data[womanIndex].norms[item].json_value.leftValue = ''
						new_data[womanIndex].norms[item].json_value.rightValue = ''
						new_data[womanIndex].norms[item].json_value.overValue = ''
						if (tableName == 'GJ_tableData') {
							new_data[womanIndex].norms[item].json_value.beforeValue = ''
							new_data[womanIndex].norms[item].json_value.laterValue = ''
							new_data[womanIndex].norms[item].json_value.downValue = ''
						}
					}
					new_data.forEach(itm => {
						if (item == '踝关节排除测试-灵活型') return false
						if (itm.sex == '1' && itm.staff_name !== '男子-团队平均值') {
							if (itm.norms[item].json_value.leftValue !== '' && itm.norms[item].json_value.leftValue !== null) {
								manLeftNum++
								new_data[manIndex].norms[item].json_value.leftValue = Number(new_data[manIndex].norms[item].json_value.leftValue) + Number(itm.norms[item].json_value.leftValue)
							}
							if (itm.norms[item].json_value.rightValue !== '' && itm.norms[item].json_value.rightValue !== null) {
								manRightNum++
								new_data[manIndex].norms[item].json_value.rightValue = Number(new_data[manIndex].norms[item].json_value.rightValue) + Number(itm.norms[item].json_value.rightValue)
							}
							if (itm.norms[item].json_value.overValue !== '' && itm.norms[item].json_value.overValue !== null) {
								manOverNum++
								new_data[manIndex].norms[item].json_value.overValue = Number(new_data[manIndex].norms[item].json_value.overValue) + Number(itm.norms[item].json_value.overValue)
							}
							if (tableName == 'GJ_tableData') {
								if (itm.norms[item].json_value.beforeValue !== '' && itm.norms[item].json_value.beforeValue !== null && itm.norms[item].json_value.beforeValue >= 0) {
									manBeforeNum++
									new_data[manIndex].norms[item].json_value.beforeValue =
										Number(new_data[manIndex].norms[item].json_value.beforeValue) + Number(itm.norms[item].json_value.beforeValue)
								}
								if (itm.norms[item].json_value.laterValue !== '' && itm.norms[item].json_value.laterValue !== null && itm.norms[item].json_value.beforeValue >= 0) {
									manLaterNum++
									new_data[manIndex].norms[item].json_value.laterValue = Number(new_data[manIndex].norms[item].json_value.laterValue) + Number(itm.norms[item].json_value.laterValue)
								}
								if (itm.norms[item].json_value.downValue !== '' && itm.norms[item].json_value.downValue !== null && itm.norms[item].json_value.beforeValue >= 0) {
									manDownNum++
									new_data[manIndex].norms[item].json_value.downValue = Number(new_data[manIndex].norms[item].json_value.downValue) + Number(itm.norms[item].json_value.downValue)
								}
							}
						} else if (itm.sex == '2' && itm.staff_name !== '女子-团队平均值') {
							if (itm.norms[item].json_value.leftValue !== '' && itm.norms[item].json_value.leftValue !== null) {
								womanLeftNum++
								new_data[womanIndex].norms[item].json_value.leftValue = Number(new_data[womanIndex].norms[item].json_value.leftValue) + Number(itm.norms[item].json_value.leftValue)
							}
							if (itm.norms[item].json_value.rightValue !== '' && itm.norms[item].json_value.rightValue !== null) {
								womanRightNum++
								new_data[womanIndex].norms[item].json_value.rightValue = Number(new_data[womanIndex].norms[item].json_value.rightValue) + Number(itm.norms[item].json_value.rightValue)
							}
							if (itm.norms[item].json_value.overValue !== '' && itm.norms[item].json_value.overValue !== null) {
								womanOverNum++
								new_data[womanIndex].norms[item].json_value.overValue = Number(new_data[womanIndex].norms[item].json_value.overValue) + Number(itm.norms[item].json_value.overValue)
							}
							if (tableName == 'GJ_tableData') {
								if (itm.norms[item].json_value.beforeValue !== '' && itm.norms[item].json_value.beforeValue !== null && itm.norms[item].json_value.beforeValue >= 0) {
									womanBeforeNum++
									new_data[womanIndex].norms[item].json_value.beforeValue =
										Number(new_data[womanIndex].norms[item].json_value.beforeValue) + Number(itm.norms[item].json_value.beforeValue)
								}
								if (itm.norms[item].json_value.laterValue !== '' && itm.norms[item].json_value.laterValue !== null && itm.norms[item].json_value.beforeValue >= 0) {
									womanLaterNum++
									new_data[womanIndex].norms[item].json_value.laterValue =
										Number(new_data[womanIndex].norms[item].json_value.laterValue) + Number(itm.norms[item].json_value.laterValue)
								}
								if (itm.norms[item].json_value.downValue !== '' && itm.norms[item].json_value.downValue !== null && itm.norms[item].json_value.beforeValue >= 0) {
									womanDownNum++
									new_data[womanIndex].norms[item].json_value.downValue = Number(new_data[womanIndex].norms[item].json_value.downValue) + Number(itm.norms[item].json_value.downValue)
								}
							}
						}
					})
					if (manLeftNum) {
						new_data[manIndex].norms[item].json_value.leftValue = (new_data[manIndex].norms[item].json_value.leftValue / manLeftNum).toFixed(1)
					}
					if (manRightNum) {
						new_data[manIndex].norms[item].json_value.rightValue = (new_data[manIndex].norms[item].json_value.rightValue / manRightNum).toFixed(1)
					}
					if (manOverNum) {
						new_data[manIndex].norms[item].json_value.overValue = (new_data[manIndex].norms[item].json_value.overValue / manOverNum).toFixed(1)
					}
					if (womanLeftNum) {
						new_data[womanIndex].norms[item].json_value.leftValue = (new_data[womanIndex].norms[item].json_value.leftValue / womanLeftNum).toFixed(1)
					}
					if (womanRightNum) {
						new_data[womanIndex].norms[item].json_value.rightValue = (new_data[womanIndex].norms[item].json_value.rightValue / womanRightNum).toFixed(1)
					}
					if (womanOverNum) {
						new_data[womanIndex].norms[item].json_value.overValue = (new_data[womanIndex].norms[item].json_value.overValue / womanOverNum).toFixed(1)
					}
					if (tableName == 'GJ_tableData') {
						if (manBeforeNum) {
							new_data[manIndex].norms[item].json_value.beforeValue = (new_data[manIndex].norms[item].json_value.beforeValue / manBeforeNum).toFixed(1)
						}
						if (manLaterNum) {
							new_data[manIndex].norms[item].json_value.laterValue = (new_data[manIndex].norms[item].json_value.laterValue / manLaterNum).toFixed(1)
						}
						if (manDownNum) {
							new_data[manIndex].norms[item].json_value.downValue = (new_data[manIndex].norms[item].json_value.downValue / manDownNum).toFixed(1)
						}
						if (womanBeforeNum) {
							new_data[womanIndex].norms[item].json_value.beforeValue = (new_data[womanIndex].norms[item].json_value.beforeValue / womanBeforeNum).toFixed(1)
						}
						if (womanLaterNum) {
							new_data[womanIndex].norms[item].json_value.laterValue = (new_data[womanIndex].norms[item].json_value.laterValue / womanLaterNum).toFixed(1)
						}
						if (womanDownNum) {
							new_data[womanIndex].norms[item].json_value.downValue = (new_data[womanIndex].norms[item].json_value.downValue / womanDownNum).toFixed(1)
						}
					}
				}
			})
			if (tableName == 'FMS_tableData' || tableName == 'GJ_tableData') {
				if (manIndex !== -1) {
					for (let obj in new_data[manIndex].norms) {
						var value = Number(new_data[manIndex].norms[obj].json_value.overValue)
						if (value) {
							// new_data[manIndex].total_value = Number(new_data[manIndex].total_value) + Number(new_data[manIndex].norms[obj].json_value.overValue)
							new_data[manIndex].total_value = (Number(new_data[manIndex].total_value) + Number(new_data[manIndex].norms[obj].json_value.overValue)).toFixed(1)
						}
					}
				}
				if (womanIndex !== -1) {
					for (var obj in new_data[womanIndex].norms) {
						var value = Number(new_data[womanIndex].norms[obj].json_value.overValue)
						if (value) {
							// new_data[womanIndex].total_value = Number(new_data[womanIndex].total_value) + Number(new_data[womanIndex].norms[obj].json_value.overValue)
							new_data[womanIndex].total_value = (Number(new_data[womanIndex].total_value) + Number(new_data[womanIndex].norms[obj].json_value.overValue)).toFixed(1)
						}
					}
				}
			}
		},
		YBTMean() {
			var nameData = ['左前', '左后外', '左后内', '右前', '右后外', '右后内']
			let manIndex = -1,
				womanIndex = -1
			this.YBT_tableData.forEach((item, index) => {
				if (item.staff_name == '男子-团队平均值') {
					manIndex = index
				}
				if (item.staff_name == '女子-团队平均值') {
					womanIndex = index
				}
			})
			nameData.forEach(item => {
				let manNum = 0,
					womanNum = 0
				if (manIndex !== -1) {
					this.YBT_tableData[manIndex].norms[item].norm_value = ''
				}
				if (womanIndex !== -1) {
					this.YBT_tableData[womanIndex].norms[item].norm_value = ''
				}
				this.YBT_tableData.forEach(itm => {
					if (itm.sex == 1 && itm.staff_name !== '男子-团队平均值') {
						if (itm.norms[item].norm_value && itm.norms[item].norm_value !== null) {
							manNum++
							this.YBT_tableData[manIndex].norms[item].norm_value = Number(this.YBT_tableData[manIndex].norms[item].norm_value) + Number(itm.norms[item].norm_value)
						}
					}
					if (itm.sex == 2 && itm.staff_name !== '女子-团队平均值') {
						if (itm.norms[item].norm_value && itm.norms[item].norm_value !== null) {
							womanNum++
							this.YBT_tableData[womanIndex].norms[item].norm_value = Number(this.YBT_tableData[womanIndex].norms[item].norm_value) + Number(itm.norms[item].norm_value)
						}
					}
				})
				if (manNum) {
					this.YBT_tableData[manIndex].norms[item].norm_value = (this.YBT_tableData[manIndex].norms[item].norm_value / manNum).toFixed(1)
				}
				if (womanNum) {
					this.YBT_tableData[womanIndex].norms[item].norm_value = (this.YBT_tableData[womanIndex].norms[item].norm_value / womanNum).toFixed(1)
				}
			})
			this.YBTLine()
		},
		YBTDownMean(name, index, tableName, valueName) {
			this.YBTDownLine(name, index, tableName, valueName)
		},
		YBTUpMean(name, index, tableName, valueName) {
			this.YBTUpLine(name, index, tableName, valueName)
		},
		MCSMean(tableName, o_name) {
			var new_data = [],
				nameData = []
			if (tableName == 'MCS_tableData') {
				new_data = this.MCS_tableData
				nameData = ['足长', '单腿前伸测试（左）', '单腿前伸测试（右）', '水平侧伸测试（左）', '水平侧伸测试（右）']
			} else {
				new_data = this.otherVariable[tableName]
				nameData = this.otherVariable[o_name + '_selectName']
			}

			let manIndex = -1,
				womanIndex = -1
			new_data.forEach((item, index) => {
				if (item.staff_name == '男子-团队平均值') {
					manIndex = index
				}
				if (item.staff_name == '女子-团队平均值') {
					womanIndex = index
				}
			})
			nameData.forEach(item => {
				let manNum = 0,
					womanNum = 0
				if (manIndex !== -1) {
					new_data[manIndex].norms[item].norm_value = ''
				}
				if (womanIndex !== -1) {
					new_data[womanIndex].norms[item].norm_value = ''
				}
				new_data.forEach(itm => {
					if (itm.sex == 1 && itm.staff_name !== '男子-团队平均值') {
						if (itm.norms[item].norm_value && itm.norms[item].norm_value !== null) {
							manNum++
							new_data[manIndex].norms[item].norm_value = Number(new_data[manIndex].norms[item].norm_value) + Number(itm.norms[item].norm_value)
						}
					}
					if (itm.sex == 2 && itm.staff_name !== '女子-团队平均值') {
						if (itm.norms[item].norm_value && itm.norms[item].norm_value !== null) {
							womanNum++
							new_data[womanIndex].norms[item].norm_value = Number(new_data[womanIndex].norms[item].norm_value) + Number(itm.norms[item].norm_value)
						}
					}
				})
				if (manNum) {
					new_data[manIndex].norms[item].norm_value = (new_data[manIndex].norms[item].norm_value / manNum).toFixed(1)
				}
				if (womanNum) {
					new_data[womanIndex].norms[item].norm_value = (new_data[womanIndex].norms[item].norm_value / womanNum).toFixed(1)
				}
			})
		},
		FMS_select_change() {
			this.FMSLine()
		},
		YBT_select_change() {
			this.YBTLine()
		},
		RRX_select_change() {
			if (this.RRX_select !== '全身旋转测试' && this.RRX_select !== '抓背测试' && this.RRX_select !== '抬肩测试') {
				this.$echarts.init(document.getElementById('rrxline')).dispose()
				this.RRXLine()
			} else if (this.RRX_select == '抬肩测试') {
				this.$echarts.init(document.getElementById('rrxline')).dispose()
				this.RRXLineTai()
			} else {
				this.$echarts.init(document.getElementById('rrxline')).dispose()
				this.RRXLineElse()
			}
		},
		/*
        @module: YBT
        @methods: this.sportsmanChange
                  this.YBTMean
    */
		YBTSpanMethod({ row, column, rowIndex, columnIndex }) {
			if (row.staff_name == '男子-团队平均值' || row.staff_name == '女子-团队平均值') {
				if (columnIndex === 0) {
					return [0, 0]
				} else if (columnIndex === 1) {
					return [1, 3]
				} else if (columnIndex === 2) {
					return [0, 0]
				}
			}
		},
		YBTDownMethod({ row, column, rowIndex, columnIndex }) {
			if (Number.isInteger(rowIndex / 3)) {
				if (columnIndex == 0 || columnIndex == 1 || columnIndex == 2 || columnIndex == 7) {
					return [3, 1]
				}
			} else if (!Number.isInteger(rowIndex / 3)) {
				if (columnIndex == 0 || columnIndex == 1 || columnIndex == 2 || columnIndex == 7) {
					return [0, 0]
				}
			}
		},
		/*
      @module  SFMA
      @methods  SFMA_SelectChange

    */
		SFMA_SelectChange(row, index, name) {
			//  this.SFMA_tableData
		},
		SFMASelectChange(name) {
			this.SFMAClickName[name] = !this.SFMAClickName[name]
		},

		setSequce(property, norm_name) {
			return function (a, b) {
				return b.total_value - a.total_value
			}
		},
		newSetSequce(name) {
			return function (a, b) {
				var value1 = a.norms[name].json_value['overValue']
				var value2 = b.norms[name].json_value['overValue']
				return value2 - value1
			}
		},
		selectSort(arr, name) {
			if (name == 'FMS_select') {
				if (Array.isArray(arr)) {
					for (var i = 0; i < arr.length - 1; i++) {
						var flag = false
						var tmp = arr[i]
						var value = arr[i].norms[this[name]].json_value.overValue
						for (var j = i + 1; j < arr.length; j++) {
							if (value < arr[j].norms[this[name]].json_value.overValue) {
								arr[i] = arr[j]
								arr[j] = tmp
								flag = true
							}
							// minIdex = arr[j].norms[this[name]].json_value.overValue > arr[minIdex].norms[this[name]].json_value.overValue ? j : minIdex;
							// [arr[i], arr[minIdex]] = [arr[minIdex], arr[i]];
						}
						if (!flag) {
							// break;
						}
					}
					return arr
				}
			} else if (name == 'RRX_selectElse') {
				if (Array.isArray(arr)) {
					for (var i = 0; i < arr.length - 1; i++) {
						var minIdex = i
						for (var j = i + 1; j < arr.length; j++) {
							minIdex = arr[j].norms[this.RRX_select].json_value.overValue > arr[minIdex].norms[this.RRX_select].json_value.overValue ? j : minIdex
						}
						;[arr[i], arr[minIdex]] = [arr[minIdex], arr[i]]
					}
					return arr
				}
			} else {
				// if (Array.isArray(arr)) {
				//   for (var i = 0; i < arr.length - 1; i++) {
				//     var minIdex = i;
				//     for (var j = i + 1; j < arr.length; j++) {
				//       minIdex = arr[j].norms[this[name]].norm_value > arr[minIdex].norms[this[name]].norm_value ? j : minIdex
				//     }
				//     [arr[i], arr[minIdex]] = [arr[minIdex], arr[i]];
				//   }
				//   return arr
				// }

				return function (a, b) {
					var value1 = a.norms[this[name]].norm_value
					var value2 = b.norms[this[name]].norm_value
					return value2 - value1
				}
			}
		},

		// -------- Echarts ------------------
		// FMS  柱状图
		FMSLine() {
			let edata = JSON.parse(JSON.stringify(this.FMS_tableData))
			if (this.oldman !== 3) {
				edata.pop()
			} else {
				edata.pop()
				edata.pop()
			}

			if (this.FMS_sex != '') {
				let data = []
				edata.forEach(item => {
					if (item.sex == this.FMS_sex) {
						data.push(item)
					}
				})
				edata = data
			}
			if (this.FMS_select == '') {
				edata = edata.sort(this.setSequce())
			} else {
				edata = edata.sort(this.newSetSequce(this.FMS_select))
			}
			let num = 0,
				sum = 0
			let obj = {
				'18-21分': {
					num: 0,
				},
				'13-17分': {
					num: 0,
				},
				'8-12分': {
					num: 0,
				},
				'0-7分': {
					num: 0,
				},
			}

			let pieData = [
				{
					name: '18-21分',
					value: 0,
				},
				{
					name: '13-17分',
					value: 0,
				},
				{
					name: '8-12分',
					value: 0,
				},
				{
					name: '0-7分',
					value: 0,
				},
			]
			function aNum(num) {
				if ((num == '' || num >= 0) && num < 8) {
					obj['0-7分'].num++
					pieData[3].value++
				} else if (num >= 8 && num < 13) {
					obj['8-12分'].num++
					pieData[2].value++
				} else if (num >= 13 && num < 18) {
					obj['13-17分'].num++
					pieData[1].value++
				} else if (num >= 18 && num <= 21) {
					obj['18-21分'].num++
					pieData[0].value++
				}
			}
			if (this.FMS_select == '') {
				if (this.FMS_sex !== '') {
					edata.forEach(item => {
						if (item.sex == this.FMS_sex) {
							aNum(Number(item.total_value))
						}
						if (item.total_value !== '' && item.sex == this.FMS_sex) {
							num++
							sum += Number(item.total_value)
						}
					})
				} else {
					edata.forEach(item => {
						aNum(Number(item.total_value))
						if (item.total_value !== '') {
							num++
							sum += Number(item.total_value)
						}
					})
				}
			} else {
				if (this.FMS_sex !== '') {
					edata.forEach(item => {
						if (item.sex == this.FMS_sex) {
							aNum(Number(item.norms[this.FMS_select].json_value.overValue))
						}
						if (item.norms[this.FMS_select].json_value.overValue !== '' && item.sex == this.FMS_sex) {
							num++
							sum += Number(item.norms[this.FMS_select].json_value.overValue)
						}
					})
				} else {
					edata.forEach(item => {
						aNum(Number(item.norms[this.FMS_select].json_value.overValue))
						if (item.norms[this.FMS_select].json_value.overValue !== '') {
							num++
							sum += Number(item.norms[this.FMS_select].json_value.overValue)
						}
					})
				}
			}
			let serum = this.$echarts.init(document.getElementById('fmsline'))
			let c_name = this.FMS_select == '' ? '总分' : this.FMS_select
			let optionS = {
				title: {
					x: 'center',
					text: c_name,
					textStyle: {
						//标题内容的样式
						color: '#333333', //京东红
						fontStyle: 'normal', //主标题文字字体风格，默认normal，有italic(斜体),oblique(斜体)
						fontWeight: 'normal', //可选normal(正常)，bold(加粗)，bolder(加粗)，lighter(变细)，100|200|300|400|500...
						fontFamily: 'MicrosoftYaHei', //主题文字字体，默认微软雅黑
						fontSize: 16, //主题文字字体大小，默认为18px
					},
				},
				tooltip: {
					trigger: 'axis',
					axisPointer: {
						// 坐标轴指示器，坐标轴触发有效
						type: 'shadow', // 默认为直线，可选为：'line' | 'shadow'
					},
					formatter: this.oldman == '3' && this.FMS_sex == '' ? '{b}<br />' + c_name + ': {c}{c1}' : '{b}<br />' + c_name + ': {c}',
				},
				legend: {
					data: ['男', '女'],
					// right: 40,
					top: 50,
					left: 'center',
					icon: 'rect', //  这个字段控制形状  类型包括 circle，rect ，roundRect，triangle，diamond，pin，arrow，none
					itemWidth: 10, // 设置宽度
					itemHeight: 10, // 设置高度
					itemGap: 20, // 设置间距
					itemStyle: {},
				},
				color: ['#1790FF', '#F79645'],
				grid: {
					left: '6%',
					right: '0%',
					bottom: '3%',
					top: '29%',
					containLabel: true,
				},
				xAxis: {
					type: 'category',
					data: edata.map(item => {
						return item.staff_name
					}),
					axisLabel: {
						color: '#333',
					},
					axisTick: {
						show: false,
					},
					axisLine: {
						lineStyle: {
							color: '#E1E1E1',
						},
					},
				},
				yAxis: {
					show: true,
					name: '单位: 分',
					type: 'value',
					splitLine: {
						//网格线
						show: true,
						lineStyle: {
							color: '#E1E1E1',
							type: 'dashed',
						},
					},
					axisLine: { show: false, lineStyle: { color: '#333' } },
					axisTick: {
						show: false,
					},
					nameTextStyle: {
						padding: [0, 0, 10, 0],
					},
				},
				series: [
					{
						name: '辅助',
						type: 'bar',
						stack: '总量',
						itemStyle: {
							barBorderColor: 'rgba(0,0,0,0)',
							color: 'rgba(0,0,0,0)',
						},
						emphasis: {
							itemStyle: {
								barBorderColor: 'rgba(0,0,0,0)',
								color: 'rgba(0,0,0,0)',
							},
						},
						barWidth: '16',
					},
					{
						name: '男',
						type: 'bar',
						stack: '总量',
						label: {
							show: this.FMS_sex == '1' || this.FMS_sex == '' ? true : false,
							position: 'top',
						},
						markLine: {
							silent: true, //true 去掉鼠标悬浮该线上的动画
							symbol: 'none', //该线无样式
							label: {
								width: '1',
								show: true, //该线上的值去掉,
								position: 'insideEndTop',
							},
							lineStyle: {
								//设置该线样式
								normal: {
									width: '1',
									type: 'dashed',
									color: '#2FC25B',
									formatter: '全队平均值 : 100',
								},
							},
							data: [
								{
									yAxis: (sum / num).toFixed(1), //线的值
									name: this.FMS_sex == '1' ? '男子平均值' : this.FMS_sex == '2' ? '女子平均值' : '全队平均值',
									label: {
										show: true,
										formatter: '{b}: {c}',
									},
								},
							],
						},
						// data: [900, 345, 393, '-', '-', 135, 178, 286, '-', '-', '-']
						data: edata.map(item => {
							if (item.sex == '1') {
								if (this.FMS_select == '') {
									return item.total_value
								} else {
									return item.norms[this.FMS_select].json_value.overValue
								}
							}
						}),
					},
					{
						name: '女',
						type: 'bar',
						stack: '总量',
						label: {
							show: true,
							position: 'top',
						},
						markLine: {
							silent: true, //true 去掉鼠标悬浮该线上的动画
							symbol: 'none', //该线无样式
							label: {
								width: '1',
								show: true, //该线上的值去掉,
								position: 'insideEndTop',
							},
							lineStyle: {
								//设置该线样式
								normal: {
									width: '1',
									type: 'dashed',
									color: '#2FC25B',
									width: this.FMS_sex == '2' ? 1 : 0,
								},
							},
							data: [
								{
									yAxis: (sum / num).toFixed(1), //线的值
									name: this.FMS_sex == '1' ? '男子平均值' : this.FMS_sex == '2' ? '女子平均值' : '全队平均值',
									label: {
										show: true,
										formatter: '{b}: {c}',
									},
								},
							],
						},
						// data: ['-', '-', '-', 108, 154, '-', '-', '-', 119, 361, 203]
						data: edata.map(item => {
							if (item.sex == '2') {
								if (this.FMS_select == '') {
									return item.total_value
								} else {
									return item.norms[this.FMS_select].json_value.overValue
								}
							}
						}),
					},
				],
			}
			serum.setOption(optionS)
			this.$echartsResize(serum)

			let fmspie = this.$echarts.init(document.getElementById('fmsPie'))
			let optionP = {
				title: {
					text: '全队总分分布',
					x: 'center',
					textStyle: {
						//标题内容的样式
						color: '#333333', //京东红
						fontStyle: 'normal', //主标题文字字体风格，默认normal，有italic(斜体),oblique(斜体)
						fontWeight: 'normal', //可选normal(正常)，bold(加粗)，bolder(加粗)，lighter(变细)，100|200|300|400|500...
						fontFamily: 'MicrosoftYaHei', //主题文字字体，默认微软雅黑
						fontSize: 16, //主题文字字体大小，默认为18px
					},
				},
				tooltip: {
					trigger: 'item',
					formatter: '{b}: {c}人',
				},

				legend: {
					width: 'auto',
					icon: 'circle',
					trigger: 'item',
					top: 37,
					data: pieData,
					itemHeight: 10,
					formatter: function (name) {
						if (!name) return
						return '{a|' + name + ':' + '  }{b|' + (obj[name] ? obj[name].num : '--') + '人' + '}'
					},
					textStyle: {
						rich: {
							a: {
								fontFamily: 'MicrosoftYaHei',
								color: 'rgba(102, 102, 102, 1)',
								lineHeight: 27,
								width: 50,
							},
							b: {
								fontFamily: 'MicrosoftYaHei',
								color: 'rgba(136,136,136,1)',
								lineHeight: 27,
								textAlign: 'right',
								width: 30,
							},
							c: {
								fontFamily: 'MicrosoftYaHei',
								color: 'rgba(51,51,51,1)',
								lineHeight: 27,
								textAlign: 'right',
								width: 30,
							},
						},
					},
				},
				//设置边框颜色
				color: ['#5B9BD5', '#ED7D39', '#A5A5A5', '#F8C045'],
				grid: {
					// right: '5%', // 与容器右侧的距离
					top: '50%', // 与容器顶部的距离
					bottom: '2%', // 与容器底部的距离
					left: '10%',
				},
				series: [
					{
						name: '',
						type: 'pie',
						radius: ['0%', '65%'],
						center: ['50%', '55%'], //设置饼图位置
						label: {
							show: false,
						},
						labelLine: {
							show: false,
						},
						data: pieData,
					},
				],
			}
			fmspie.setOption(optionP)
			this.$echartsResize(fmspie)
		},
		// YBT  柱状图
		YBTLine() {
			let edata = JSON.parse(JSON.stringify(this.YBT_tableData))
			if (this.YBToldman !== 3) {
				edata.pop()
			} else {
				edata.pop()
				edata.pop()
			}
			edata = edata.sort(this.setSort('YBT_select'))

			if (this.YBT_sex != '') {
				let data = []
				edata.forEach(item => {
					if (item.sex == this.YBT_sex) {
						data.push(item)
					}
				})
				edata = data
			}

			let num = 0,
				sum = 0
			if (this.YBT_sex !== '') {
				edata.forEach(item => {
					if (item.norms[this.YBT_select].norm_value !== '' && item.sex == this.YBT_sex) {
						num++
						sum += Number(item.norms[this.YBT_select].norm_value)
					}
				})
			} else {
				edata.forEach(item => {
					if (item.norms[this.YBT_select].norm_value !== '') {
						num++
						sum += Number(item.norms[this.YBT_select].norm_value)
					}
				})
			}
			let unit = ''
			this.oldTreeData.forEach(item => {
				if (item.name == 'YBT') {
					item.norms.forEach(itm => {
						if (itm.name == this.YBT_select) {
							unit = itm.unit_name
						}
					})
				}
			})
			let serum = this.$echarts.init(document.getElementById('ybtline'))
			let optionS = {
				title: {
					x: 'center',
					text: this.YBT_select,
					textStyle: {
						//标题内容的样式
						color: '#333333', //京东红
						fontStyle: 'normal', //主标题文字字体风格，默认normal，有italic(斜体),oblique(斜体)
						fontWeight: 'normal', //可选normal(正常)，bold(加粗)，bolder(加粗)，lighter(变细)，100|200|300|400|500...
						fontFamily: 'MicrosoftYaHei', //主题文字字体，默认微软雅黑
						fontSize: 16, //主题文字字体大小，默认为18px
					},
				},
				tooltip: {
					trigger: 'axis',
					axisPointer: {
						// 坐标轴指示器，坐标轴触发有效
						type: 'shadow', // 默认为直线，可选为：'line' | 'shadow'
					},
					formatter: this.YBToldman == '3' && this.YBT_sex == '' ? '{b}<br />' + this.YBT_select + ': {c}{c1}' : '{b}<br />' + this.YBT_select + ': {c}',
				},
				legend: {
					data: ['男', '女'],
					// right: 40,
					top: 50,
					left: 'center',
					icon: 'rect', //  这个字段控制形状  类型包括 circle，rect ，roundRect，triangle，diamond，pin，arrow，none
					itemWidth: 10, // 设置宽度
					itemHeight: 10, // 设置高度
					itemGap: 20, // 设置间距
					itemStyle: {},
				},
				color: ['#1790FF', '#F79645'],
				grid: {
					left: '2%',
					right: '0%',
					bottom: '3%',
					top: '29%',
					containLabel: true,
				},
				xAxis: {
					type: 'category',
					data: edata.map(item => {
						return item.staff_name
					}),
					axisLabel: {
						color: '#333',
					},
					axisTick: {
						show: false,
					},
					axisLine: {
						lineStyle: {
							color: '#E1E1E1',
						},
					},
				},
				yAxis: {
					show: true,
					name: '单位: ' + unit,
					type: 'value',
					splitLine: {
						//网格线
						show: true,
						lineStyle: {
							color: '#E1E1E1',
							type: 'dashed',
						},
					},
					axisLine: { show: false, lineStyle: { color: '#333' } },
					axisTick: {
						show: false,
					},
					nameTextStyle: {
						padding: [0, 0, 10, 0],
					},
				},
				series: [
					{
						name: '辅助',
						type: 'bar',
						stack: '总量',
						itemStyle: {
							barBorderColor: 'rgba(0,0,0,0)',
							color: 'rgba(0,0,0,0)',
						},
						emphasis: {
							itemStyle: {
								barBorderColor: 'rgba(0,0,0,0)',
								color: 'rgba(0,0,0,0)',
							},
						},
						barWidth: '16',
					},
					{
						name: '男',
						type: 'bar',
						stack: '总量',
						label: {
							show: this.YBT_sex == '1' || this.YBT_sex == '' ? true : false,
							position: 'top',
						},
						markLine: {
							silent: true, //true 去掉鼠标悬浮该线上的动画
							symbol: 'none', //该线无样式
							label: {
								width: '1',
								show: true, //该线上的值去掉,
								position: 'insideEndTop',
							},
							lineStyle: {
								//设置该线样式
								normal: {
									type: 'dashed',
									color: '#2FC25B',
								},
							},
							data: [
								{
									yAxis: (sum / num).toFixed(1), //线的值
									name: this.YBT_sex == '1' ? '男子平均值' : this.YBT_sex == '2' ? '女子平均值' : '全队平均值',
									label: {
										show: true,
										formatter: '{b}: {c}',
									},
								},
							],
						},
						// data: [900, 345, 393, '-', '-', 135, 178, 286, '-', '-', '-']
						data: edata.map(item => {
							if (item.sex == '1') {
								return item.norms[this.YBT_select].norm_value
							}
						}),
					},
					{
						name: '女',
						type: 'bar',
						stack: '总量',
						label: {
							show: true,
							position: 'top',
						},
						markLine: {
							silent: true, //true 去掉鼠标悬浮该线上的动画
							symbol: 'none', //该线无样式
							label: {
								width: '1',
								show: true, //该线上的值去掉,
								position: 'insideEndTop',
							},
							lineStyle: {
								//设置该线样式
								normal: {
									width: '1',
									type: 'dashed',
									color: '#2FC25B',
									width: this.YBT_sex == '2' ? 1 : 0,
								},
							},
							data: [
								{
									yAxis: (sum / num).toFixed(1), //线的值
									name: this.YBT_sex == '1' ? '男子平均值' : this.YBT_sex == '2' ? '女子平均值' : '全队平均值',
									label: {
										show: true,
										formatter: '{b}: {c}',
									},
								},
							],
						},
						// data: ['-', '-', '-', 108, 154, '-', '-', '-', 119, 361, 203]
						data: edata.map(item => {
							if (item.sex == '2') {
								return item.norms[this.YBT_select].norm_value
							}
						}),
					},
				],
			}
			serum.setOption(optionS)
			this.$echartsResize(serum)
		},

		YBTDownLine(name, index, tableName, valueName) {
			var cname = 'YBT_downEcharts' + index
			let serum = this.$echarts.init(document.getElementById(cname))
			var xdata = [],
				ydata = []
			for (var i in this.YBT_tableDataDown[index].norms) {
				xdata.push(i)
				ydata.push(this.YBT_tableDataDown[index].norms[i].norm_value)
			}
			let optionS = {
				title: {
					x: 'center',
					text: this.YBT_tableDataDown[index].staff_name + '下肢YBT测试结果',
					top: '10',
					textStyle: {
						//标题内容的样式
						color: '#333333', //京东红
						fontStyle: 'normal', //主标题文字字体风格，默认normal，有italic(斜体),oblique(斜体)
						fontWeight: 'normal', //可选normal(正常)，bold(加粗)，bolder(加粗)，lighter(变细)，100|200|300|400|500...
						fontFamily: 'MicrosoftYaHei', //主题文字字体，默认微软雅黑
						fontSize: 16, //主题文字字体大小，默认为18px
						// padding: [20, 0, 0, 0]
					},
				},
				tooltip: {
					trigger: 'axis',
					axisPointer: {
						// 坐标轴指示器，坐标轴触发有效
						type: 'shadow', // 默认为直线，可选为：'line' | 'shadow'
					},
				},
				color: ['#1790FF', '#F79645'],
				grid: {
					left: '6%',
					right: '5%',
					bottom: '5%',
					top: '30%',
					containLabel: true,
				},
				xAxis: {
					type: 'category',
					data: xdata,
					axisLabel: {
						color: '#333',
					},
					axisTick: {
						show: false,
					},
					axisLine: {
						lineStyle: {
							color: '#E1E1E1',
						},
					},
				},
				yAxis: {
					show: true,
					name: '单位: cm',
					type: 'value',
					splitLine: {
						//网格线
						show: true,
						lineStyle: {
							color: '#E1E1E1',
							type: 'dashed',
						},
					},
					axisLine: { show: false, lineStyle: { color: '#333' } },
					axisTick: {
						show: false,
					},
					nameTextStyle: {
						padding: [0, 0, 10, 0],
					},
				},
				series: [
					{
						type: 'bar',
						stack: '总量',
						data: ydata,
						barWidth: 20,
						label: {
							show: true,
							position: 'top',
						},
					},
				],
			}
			serum.setOption(optionS, false)
			this.$echartsResize(serum)
		},
		YBTUpLine(name, index) {
			var cname = 'YBT_upEcharts' + index
			let serum = this.$echarts.init(document.getElementById(cname))
			var xdata = [],
				ydata = []
			for (var i in this.YBT_tableDataUp[index].norms) {
				xdata.push(i)
				ydata.push(this.YBT_tableDataUp[index].norms[i].norm_value)
			}
			let optionS = {
				title: {
					x: 'center',
					text: this.YBT_tableDataUp[index].staff_name + '上肢YBT测试结果',
					top: '10',
					textStyle: {
						//标题内容的样式
						color: '#333333', //京东红
						fontStyle: 'normal', //主标题文字字体风格，默认normal，有italic(斜体),oblique(斜体)
						fontWeight: 'normal', //可选normal(正常)，bold(加粗)，bolder(加粗)，lighter(变细)，100|200|300|400|500...
						fontFamily: 'MicrosoftYaHei', //主题文字字体，默认微软雅黑
						fontSize: 16, //主题文字字体大小，默认为18px
						// padding: [20, 0, 0, 0]
					},
				},
				tooltip: {
					trigger: 'axis',
					axisPointer: {
						// 坐标轴指示器，坐标轴触发有效
						type: 'shadow', // 默认为直线，可选为：'line' | 'shadow'
					},
				},
				color: ['#1790FF', '#F79645'],
				grid: {
					left: '6%',
					right: '5%',
					bottom: '5%',
					top: '30%',
					containLabel: true,
				},
				xAxis: {
					type: 'category',
					data: xdata,
					axisLabel: {
						color: '#333',
					},
					axisTick: {
						show: false,
					},
					axisLine: {
						lineStyle: {
							color: '#E1E1E1',
						},
					},
				},
				yAxis: {
					show: true,
					name: '单位: cm',
					type: 'value',
					splitLine: {
						//网格线
						show: true,
						lineStyle: {
							color: '#E1E1E1',
							type: 'dashed',
						},
					},
					axisLine: { show: false, lineStyle: { color: '#333' } },
					axisTick: {
						show: false,
					},
					nameTextStyle: {
						padding: [0, 0, 10, 0],
					},
				},
				series: [
					{
						type: 'bar',
						stack: '总量',
						data: ydata,
						barWidth: 20,
						label: {
							show: true,
							position: 'top',
						},
					},
				],
			}
			serum.setOption(optionS, false)
			this.$echartsResize(serum)
		},
		YBTDownLineLook(name, index) {
			let serum = this.$echarts.init(document.getElementById('YBT_downLookEcharts'))
			var xdata = [],
				passData = [],
				failData = []
			for (var i in this.YBTObjectDownLook.chart.score) {
				xdata.push(i)
				if (this.YBTObjectDownLook.chart.score[i].color == '#9BFBA1') {
					passData.push(this.YBTObjectDownLook.chart.score[i].value)
					failData.push('')
				} else if (this.YBTObjectDownLook.chart.score[i].color == '#FFCB00') {
					passData.push('')
					failData.push(this.YBTObjectDownLook.chart.score[i].value)
				}
			}

			let optionS = {
				title: {
					x: 'center',
					text: 'YBT下肢测试总分',
					top: '10',
					textStyle: {
						//标题内容的样式
						color: '#333333', //京东红
						fontStyle: 'normal', //主标题文字字体风格，默认normal，有italic(斜体),oblique(斜体)
						fontWeight: 'normal', //可选normal(正常)，bold(加粗)，bolder(加粗)，lighter(变细)，100|200|300|400|500...
						fontFamily: 'MicrosoftYaHei', //主题文字字体，默认微软雅黑
						fontSize: 16, //主题文字字体大小，默认为18px
						// padding: [20, 0, 0, 0]
					},
				},
				legend: {
					top: '15%',
					data: ['通过', '不通过'],
				},
				tooltip: {
					show: true,
					trigger: 'axis',
					axisPointer: {
						// 坐标轴指示器，坐标轴触发有效
						type: 'shadow', // 默认为直线，可选为：'line' | 'shadow'
					},
					formatter: function (params) {
						var name = ''
						params.forEach(item => {
							if (item.value !== '') {
								return (name = item.marker + ' ' + item.name + ':' + item.value)
							}
						})
						return name
					},
				},
				color: ['#1790FF', '#F79645'],
				dataZoom: [
					{
						type: 'inside',
						maxValueSpan: 9,
					},
					{
						type: 'slider',
						height: 20,
						bottom: 10,
					},
				],
				grid: {
					left: '3%',
					right: '5%',
					bottom: '10%',
					top: '30%',
					containLabel: true,
				},
				xAxis: {
					type: 'category',
					data: xdata,
					axisLabel: {
						color: '#333',
					},
					axisTick: {
						show: false,
					},
					axisLine: {
						lineStyle: {
							color: '#E1E1E1',
						},
					},
				},
				yAxis: {
					show: true,
					name: '',
					type: 'value',
					splitLine: {
						//网格线
						show: true,
						lineStyle: {
							color: '#E1E1E1',
							type: 'dashed',
						},
					},
					axisLine: { show: false, lineStyle: { color: '#333' } },
					axisTick: {
						show: false,
					},
					nameTextStyle: {
						padding: [0, 0, 10, 0],
					},
				},
				series: [
					{
						name: '通过',
						type: 'bar',
						stack: 'Total',
						data: passData,
						color: '#00C60C',
						barWidth: 20,
						label: {
							show: true,
							position: 'top',
						},
					},
					{
						name: '不通过',
						type: 'bar',
						stack: 'Total',
						data: failData,
						color: '#FFCB00',
						barWidth: 20,
						label: {
							show: true,
							position: 'top',
						},
					},
				],
			}
			serum.setOption(optionS, false)
			this.$echartsResize(serum)
		},
		YBTDownBarLook(index, name, item) {
			var c_name = 'YBTDownLook' + index
			let serum = this.$echarts.init(document.getElementById(c_name))
			var xdata = [],
				passData = [],
				failData = []

			for (var i in item) {
				xdata.push(i)
				if (item[i].color == '#9BFBA1') {
					passData.push(item[i].value)
					failData.push('')
				} else if (item[i].color == '#FFCB00') {
					passData.push('')
					failData.push(item[i].value)
				}
			}

			let optionS = {
				title: {
					x: 'center',
					text: name + '下肢YBT对称性分析',
					top: '10',
					textStyle: {
						//标题内容的样式
						color: '#333333', //京东红
						fontStyle: 'normal', //主标题文字字体风格，默认normal，有italic(斜体),oblique(斜体)
						fontWeight: 'normal', //可选normal(正常)，bold(加粗)，bolder(加粗)，lighter(变细)，100|200|300|400|500...
						fontFamily: 'MicrosoftYaHei', //主题文字字体，默认微软雅黑
						fontSize: 16, //主题文字字体大小，默认为18px
						// padding: [20, 0, 0, 0]
					},
				},
				legend: {
					top: '15%',
					data: ['通过', '不通过'],
				},
				tooltip: {
					show: true,
					trigger: 'axis',
					axisPointer: {
						// 坐标轴指示器，坐标轴触发有效
						type: 'shadow', // 默认为直线，可选为：'line' | 'shadow'
					},
					formatter: function (params) {
						var name = ''
						params.forEach(item => {
							if (item.value !== '') {
								return (name = item.marker + ' ' + item.name + ':' + item.value)
							}
						})
						return name
					},
				},
				color: ['#1790FF', '#F79645'],
				grid: {
					left: '8%',
					right: '5%',
					bottom: '5%',
					top: '30%',
					containLabel: true,
				},
				xAxis: {
					type: 'category',
					data: xdata,
					axisLabel: {
						color: '#333',
					},
					axisTick: {
						show: false,
					},
					axisLine: {
						lineStyle: {
							color: '#E1E1E1',
						},
					},
				},
				yAxis: {
					show: true,
					name: '单位：cm',
					type: 'value',
					splitLine: {
						//网格线
						show: true,
						lineStyle: {
							color: '#E1E1E1',
							type: 'dashed',
						},
					},
					axisLine: { show: false, lineStyle: { color: '#333' } },
					axisTick: {
						show: false,
					},
					nameTextStyle: {
						padding: [0, 0, 10, 0],
					},
				},
				series: [
					{
						name: '通过',
						type: 'bar',
						stack: 'Total',
						data: passData,
						color: '#00C60C',
						barWidth: 20,
						label: {
							show: true,
							position: 'top',
						},
					},
					{
						name: '不通过',
						type: 'bar',
						stack: 'Total',
						data: failData,
						color: '#FFCB00',
						barWidth: 20,
						label: {
							show: true,
							position: 'top',
						},
					},
				],
			}
			serum.setOption(optionS, false)
			this.$echartsResize(serum)
		},
		YBTUpLineLook(name, index) {
			let serum = this.$echarts.init(document.getElementById('YBT_upLookEcharts'))
			var xdata = [],
				passData = [],
				failData = []
			for (var i in this.YBTObjectUpLook.chart.score) {
				xdata.push(i)
				if (this.YBTObjectUpLook.chart.score[i].color == '#9BFBA1') {
					passData.push(this.YBTObjectUpLook.chart.score[i].value)
					failData.push('')
				} else if (this.YBTObjectUpLook.chart.score[i].color == '#FFCB00') {
					passData.push('')
					failData.push(this.YBTObjectUpLook.chart.score[i].value)
				}
			}
			let optionS = {
				title: {
					x: 'center',
					text: 'YBT上肢测试总分',
					top: '10',
					textStyle: {
						//标题内容的样式
						color: '#333333', //京东红
						fontStyle: 'normal', //主标题文字字体风格，默认normal，有italic(斜体),oblique(斜体)
						fontWeight: 'normal', //可选normal(正常)，bold(加粗)，bolder(加粗)，lighter(变细)，100|200|300|400|500...
						fontFamily: 'MicrosoftYaHei', //主题文字字体，默认微软雅黑
						fontSize: 16, //主题文字字体大小，默认为18px
						// padding: [20, 0, 0, 0]
					},
				},
				legend: {
					top: '15%',
					data: ['通过', '不通过'],
				},
				tooltip: {
					show: true,
					trigger: 'axis',
					axisPointer: {
						// 坐标轴指示器，坐标轴触发有效
						type: 'shadow', // 默认为直线，可选为：'line' | 'shadow'
					},
					formatter: function (params) {
						var name = ''
						params.forEach(item => {
							if (item.value !== '') {
								return (name = item.marker + ' ' + item.name + ':' + item.value)
							}
						})
						return name
					},
				},
				color: ['#1790FF', '#F79645'],
				dataZoom: [
					{
						type: 'inside',
						maxValueSpan: 9,
					},
					{
						type: 'slider',
						height: 20,
						bottom: 10,
					},
				],
				grid: {
					left: '3%',
					right: '5%',
					bottom: '10%',
					top: '30%',
					containLabel: true,
				},
				xAxis: {
					type: 'category',
					data: xdata,
					axisLabel: {
						color: '#333',
						interval: 0,
					},
					axisTick: {
						show: false,
					},
					axisLine: {
						lineStyle: {
							color: '#E1E1E1',
						},
					},
				},
				yAxis: {
					show: true,
					name: '',
					type: 'value',
					splitLine: {
						//网格线
						show: true,
						lineStyle: {
							color: '#E1E1E1',
							type: 'dashed',
						},
					},
					axisLine: { show: false, lineStyle: { color: '#333' } },
					axisTick: {
						show: false,
					},
					nameTextStyle: {
						padding: [0, 0, 10, 0],
					},
				},
				series: [
					{
						name: '通过',
						type: 'bar',
						stack: 'Total',
						data: passData,
						color: '#00C60C',
						barWidth: 20,
						label: {
							show: true,
							position: 'top',
						},
					},
					{
						name: '不通过',
						type: 'bar',
						stack: 'Total',
						data: failData,
						color: '#FFCB00',
						barWidth: 20,
						label: {
							show: true,
							position: 'top',
						},
					},
				],
			}
			serum.setOption(optionS, false)
			this.$echartsResize(serum)
		},
		YBTUpBarLook(index, name, item) {
			var c_name = 'YBTUpLook' + index
			let serum = this.$echarts.init(document.getElementById(c_name))
			var xdata = [],
				passData = [],
				failData = []
			for (var i in item) {
				xdata.push(i)
				if (item[i].color == '#9BFBA1') {
					passData.push(item[i].value)
					failData.push('')
				} else if (item[i].color == '#FFCB00') {
					passData.push('')
					failData.push(item[i].value)
				}
			}

			let optionS = {
				title: {
					x: 'center',
					text: name + '上肢YBT对称性分析',
					top: '10',
					textStyle: {
						//标题内容的样式
						color: '#333333', //京东红
						fontStyle: 'normal', //主标题文字字体风格，默认normal，有italic(斜体),oblique(斜体)
						fontWeight: 'normal', //可选normal(正常)，bold(加粗)，bolder(加粗)，lighter(变细)，100|200|300|400|500...
						fontFamily: 'MicrosoftYaHei', //主题文字字体，默认微软雅黑
						fontSize: 16, //主题文字字体大小，默认为18px
						// padding: [20, 0, 0, 0]
					},
				},
				legend: {
					top: '15%',
					data: ['通过', '不通过'],
				},
				tooltip: {
					show: true,
					trigger: 'axis',
					axisPointer: {
						// 坐标轴指示器，坐标轴触发有效
						type: 'shadow', // 默认为直线，可选为：'line' | 'shadow'
					},
					formatter: function (params) {
						var name = ''
						params.forEach(item => {
							if (item.value !== '') {
								return (name = item.marker + ' ' + item.name + ':' + item.value)
							}
						})
						return name
					},
				},
				color: ['#1790FF', '#F79645'],
				grid: {
					left: '8%',
					right: '5%',
					bottom: '5%',
					top: '30%',
					containLabel: true,
				},
				xAxis: {
					type: 'category',
					data: xdata,
					axisLabel: {
						color: '#333',
					},
					axisTick: {
						show: false,
					},
					axisLine: {
						lineStyle: {
							color: '#E1E1E1',
						},
					},
				},
				yAxis: {
					show: true,
					name: '单位：cm',
					type: 'value',
					splitLine: {
						//网格线
						show: true,
						lineStyle: {
							color: '#E1E1E1',
							type: 'dashed',
						},
					},
					axisLine: { show: false, lineStyle: { color: '#333' } },
					axisTick: {
						show: false,
					},
					nameTextStyle: {
						padding: [0, 0, 10, 0],
					},
				},
				series: [
					{
						name: '通过',
						type: 'bar',
						stack: 'Total',
						data: passData,
						color: '#00C60C',
						barWidth: 20,
						label: {
							show: true,
							position: 'top',
						},
					},
					{
						name: '不通过',
						type: 'bar',
						stack: 'Total',
						data: failData,
						color: '#FFCB00',
						barWidth: 20,
						label: {
							show: true,
							position: 'top',
						},
					},
				],
			}
			serum.setOption(optionS, false)
			this.$echartsResize(serum)
		},
		setSort(name) {
			var that = this
			return function (a, b) {
				var value1 = a.norms[that[name]].norm_value
				var value2 = b.norms[that[name]].norm_value
				return value2 - value1
			}
		},
		// 柔韧性
		RRXLine() {
			let edata = JSON.parse(JSON.stringify(this.RRX_tableData))
			if (this.RRXoldman !== 3) {
				edata.pop()
			} else {
				edata.pop()
				edata.pop()
			}
			edata = edata.sort(this.setSort('RRX_select'))
			if (this.RRX_sex != '') {
				let data = []
				edata.forEach(item => {
					if (item.sex == this.RRX_sex) {
						data.push(item)
					}
				})
				edata = data
			}
			let num = 0,
				sum = 0
			if (this.RRX_sex !== '') {
				edata.forEach(item => {
					if (item.norms[this.RRX_select].norm_value !== '' && item.sex == this.RRX_sex) {
						num++
						sum += Number(item.norms[this.RRX_select].norm_value)
					}
				})
			} else {
				edata.forEach(item => {
					if (item.norms[this.RRX_select].norm_value !== '') {
						num++
						sum += Number(item.norms[this.RRX_select].norm_value)
					}
				})
			}
			let unit = ''
			this.oldTreeData.forEach(item => {
				if (item.name == '柔韧性') {
					item.norms.forEach(itm => {
						if (itm.name == this.RRX_select) {
							unit = itm.unit_name
						}
					})
				}
			})
			let serum = this.$echarts.init(document.getElementById('rrxline'))
			let optionS = {
				title: {
					x: 'center',
					text: this.RRX_select,
					textStyle: {
						//标题内容的样式
						color: '#333333', //京东红
						fontStyle: 'normal', //主标题文字字体风格，默认normal，有italic(斜体),oblique(斜体)
						fontWeight: 'normal', //可选normal(正常)，bold(加粗)，bolder(加粗)，lighter(变细)，100|200|300|400|500...
						fontFamily: 'MicrosoftYaHei', //主题文字字体，默认微软雅黑
						fontSize: 16, //主题文字字体大小，默认为18px
					},
				},
				tooltip: {
					trigger: 'axis',
					axisPointer: {
						// 坐标轴指示器，坐标轴触发有效
						type: 'shadow', // 默认为直线，可选为：'line' | 'shadow'
					},
					formatter: this.RRXoldman == '3' && this.RRX_sex == '' ? '{b}<br />' + this.RRX_select + ': {c}{c1}' : '{b}<br />' + this.RRX_select + ': {c}',
				},
				legend: {
					data: ['男', '女'],
					// right: 40,
					top: 50,
					left: 'center',
					icon: 'rect', //  这个字段控制形状  类型包括 circle，rect ，roundRect，triangle，diamond，pin，arrow，none
					itemWidth: 10, // 设置宽度
					itemHeight: 10, // 设置高度
					itemGap: 20, // 设置间距
					itemStyle: {},
				},
				color: ['#1790FF', '#F79645'],
				grid: {
					left: '2%',
					right: '0%',
					bottom: '3%',
					top: '29%',
					containLabel: true,
				},
				xAxis: {
					type: 'category',
					data: edata.map(item => {
						return item.staff_name
					}),
					axisLabel: {
						color: '#333',
					},
					axisTick: {
						show: false,
					},
					axisLine: {
						lineStyle: {
							color: '#E1E1E1',
						},
					},
				},
				yAxis: {
					show: true,
					name: '单位: ' + unit,
					type: 'value',
					splitLine: {
						//网格线
						show: true,
						lineStyle: {
							color: '#E1E1E1',
							type: 'dashed',
						},
					},
					axisLine: { show: false, lineStyle: { color: '#333' } },
					axisTick: {
						show: false,
					},
					nameTextStyle: {
						padding: [0, 0, 10, 0],
					},
				},
				series: [
					{
						name: '辅助',
						type: 'bar',
						stack: '总量',
						itemStyle: {
							barBorderColor: 'rgba(0,0,0,0)',
							color: 'rgba(0,0,0,0)',
						},
						emphasis: {
							itemStyle: {
								barBorderColor: 'rgba(0,0,0,0)',
								color: 'rgba(0,0,0,0)',
							},
						},
						barWidth: '16',
					},
					{
						name: '男',
						type: 'bar',
						stack: '总量',
						label: {
							show: this.RRX_sex == '1' || this.RRX_sex == '' ? true : false,
							position: 'top',
						},
						markLine: {
							silent: true, //true 去掉鼠标悬浮该线上的动画
							symbol: 'none', //该线无样式
							label: {
								width: '1',
								show: true, //该线上的值去掉,
								position: 'insideEndTop',
							},
							lineStyle: {
								//设置该线样式
								normal: {
									width: '1',
									type: 'dashed',
									color: '#2FC25B',
								},
							},
							data: [
								{
									yAxis: (sum / num).toFixed(1), //线的值
									name: this.RRX_sex == '1' ? '男子平均值' : this.RRX_sex == '2' ? '女子平均值' : '全队平均值',
									label: {
										show: true,
										formatter: '{b}: {c}',
									},
								},
							],
						},
						// data: [900, 345, 393, '-', '-', 135, 178, 286, '-', '-', '-']
						data: edata.map(item => {
							if (item.sex == '1') {
								return item.norms[this.RRX_select].norm_value
							}
						}),
					},
					{
						name: '女',
						type: 'bar',
						stack: '总量',
						label: {
							show: true,
							position: 'top',
						},
						markLine: {
							silent: true, //true 去掉鼠标悬浮该线上的动画
							symbol: 'none', //该线无样式
							label: {
								width: '1',
								show: true, //该线上的值去掉,
								position: 'insideEndTop',
							},
							lineStyle: {
								//设置该线样式
								normal: {
									width: '1',
									type: 'dashed',
									color: '#2FC25B',
									width: this.RRX_sex == '2' ? 1 : 0,
								},
							},
							data: [
								{
									yAxis: (sum / num).toFixed(1), //线的值
									name: this.RRX_sex == '1' ? '男子平均值' : this.RRX_sex == '2' ? '女子平均值' : '全队平均值',
									label: {
										show: true,
										formatter: '{b}: {c}',
									},
								},
							],
						},
						// data: ['-', '-', '-', 108, 154, '-', '-', '-', 119, 361, 203]
						data: edata.map(item => {
							if (item.sex == '2') {
								return item.norms[this.RRX_select].norm_value
							}
						}),
					},
				],
			}
			serum.setOption(optionS)
			this.$echartsResize(serum)
		},
		RRXLineElse() {
			let edata = JSON.parse(JSON.stringify(this.RRX_tableData))
			if (this.RRXoldman !== 3) {
				edata.pop()
			} else {
				edata.pop()
				edata.pop()
			}
			edata = this.selectSort(edata, 'RRX_selectElse')

			if (this.RRX_sex != '') {
				let data = []
				edata.forEach(item => {
					if (item.sex == this.RRX_sex) {
						data.push(item)
					}
				})
				edata = data
			}
			let serum = this.$echarts.init(document.getElementById('rrxline'))
			let optionS = {
				title: {
					x: 'center',
					text: this.RRX_select,
					textStyle: {
						//标题内容的样式
						color: '#333333', //京东红
						fontStyle: 'normal', //主标题文字字体风格，默认normal，有italic(斜体),oblique(斜体)
						fontWeight: 'normal', //可选normal(正常)，bold(加粗)，bolder(加粗)，lighter(变细)，100|200|300|400|500...
						fontFamily: 'MicrosoftYaHei', //主题文字字体，默认微软雅黑
						fontSize: 16, //主题文字字体大小，默认为18px
					},
				},
				tooltip: {
					trigger: 'axis',
					axisPointer: {
						// 坐标轴指示器，坐标轴触发有效
						type: 'shadow', // 默认为直线，可选为：'line' | 'shadow'
					},
				},
				legend: {
					data: ['左', '右', '均值'],
					// right: 40,
					top: 50,
					left: 'center',
					icon: 'rect', //  这个字段控制形状  类型包括 circle，rect ，roundRect，triangle，diamond，pin，arrow，none
					itemWidth: 10, // 设置宽度
					itemHeight: 10, // 设置高度
					itemGap: 20, // 设置间距
					itemStyle: {},
				},
				color: ['#1790FF', '#F79645', '#2FC25B'],
				grid: {
					left: '2%',
					right: '0%',
					bottom: '3%',
					top: '29%',
					containLabel: true,
				},
				xAxis: {
					type: 'category',
					data: edata.map(item => {
						return item.staff_name
					}),
					axisLabel: {
						color: '#333',
					},
					axisTick: {
						show: false,
					},
					axisLine: {
						lineStyle: {
							color: '#E1E1E1',
						},
					},
				},
				yAxis: {
					show: true,
					name: '单位: cm',
					type: 'value',
					splitLine: {
						//网格线
						show: true,
						lineStyle: {
							color: '#E1E1E1',
							type: 'dashed',
						},
					},
					axisLine: { show: false, lineStyle: { color: '#333' } },
					axisTick: {
						show: false,
					},
					nameTextStyle: {
						padding: [0, 0, 10, 0],
					},
				},
				series: [
					{
						name: '左',
						type: 'bar',
						barWidth: '16',
						label: {
							show: true,
							position: 'top',
						},
						data: edata.map(item => {
							return item.norms[this.RRX_select].json_value.leftValue
						}),
					},
					{
						name: '右',
						type: 'bar',
						barWidth: '16',
						label: {
							show: true,
							position: 'top',
						},
						data: edata.map(item => {
							return item.norms[this.RRX_select].json_value.rightValue
						}),
					},
					{
						name: '均值',
						type: 'line',
						data: edata.map(item => {
							return ((Number(item.norms[this.RRX_select].json_value.leftValue) + Number(item.norms[this.RRX_select].json_value.rightValue)) / 2).toFixed(1)
						}),
					},
				],
			}
			serum.setOption(optionS)
			this.$echartsResize(serum)
		},
		getSequence(property, norm_name) {
			return function (a, b) {
				var value1 = a.norms['抬肩测试'].json_value.overValue
				var value2 = b.norms['抬肩测试'].json_value.overValue
				return value2 - value1
			}
		},
		RRXLineTai() {
			let edata = JSON.parse(JSON.stringify(this.RRX_tableData))
			if (this.RRXoldman !== 3) {
				edata.pop()
			} else {
				edata.pop()
				edata.pop()
			}

			if (this.RRX_sex != '') {
				let data = []
				edata.forEach(item => {
					if (item.sex == this.RRX_sex) {
						data.push(item)
					}
				})
				edata = data
			}
			edata = edata.sort(this.getSequence('norm_value', '抬肩测试'))

			let serum = this.$echarts.init(document.getElementById('rrxline'))
			let optionS = {
				title: {
					x: 'center',
					text: '抬肩测试',
					textStyle: {
						//标题内容的样式
						color: '#333333', //京东红
						fontStyle: 'normal', //主标题文字字体风格，默认normal，有italic(斜体),oblique(斜体)
						fontWeight: 'normal', //可选normal(正常)，bold(加粗)，bolder(加粗)，lighter(变细)，100|200|300|400|500...
						fontFamily: 'MicrosoftYaHei', //主题文字字体，默认微软雅黑
						fontSize: 16, //主题文字字体大小，默认为18px
					},
				},
				tooltip: {
					trigger: 'axis',
					axisPointer: {
						type: 'cross',
						crossStyle: {
							color: '#999',
						},
					},
				},
				grid: {
					left: '3%',
					right: '1%',
					bottom: '3%',
					top: '29%',
					containLabel: true,
				},
				color: ['#1790FF', '#EC6868', '#2FC25B'],
				legend: {
					data: ['臂长', '抬肩高度', '抬肩分数'],
					top: 50,
					left: 'center',
					icon: 'rect', //  这个字段控制形状  类型包括 circle，rect ，roundRect，triangle，diamond，pin，arrow，none
					itemWidth: 10, // 设置宽度
					itemHeight: 10, // 设置高度
					itemGap: 20, // 设置间距
					itemStyle: {},
				},
				xAxis: [
					{
						type: 'category',
						data: edata.map(item => {
							return item.staff_name
						}),
						axisPointer: {
							type: 'shadow',
						},
						axisLabel: {
							color: '#333',
						},
						axisTick: {
							show: false,
						},
						axisLine: {
							lineStyle: {
								color: '#E1E1E1',
							},
						},
					},
				],
				yAxis: [
					{
						type: 'value',
						name: '单位: cm',
						yAxisIndex: 1,
						splitLine: {
							//网格线
							show: true,
							lineStyle: {
								color: '#E1E1E1',
								type: 'dashed',
							},
						},
						axisLine: { show: false, lineStyle: { color: '#333' } },
						axisTick: {
							show: false,
						},
						nameTextStyle: {
							padding: [0, 0, 10, 0],
						},
					},
					{
						type: 'value',
						name: '单位: %',
						yAxisIndex: 0,
						splitLine: {
							//网格线
							show: false,
							lineStyle: {
								color: '#E1E1E1',
								type: 'dashed',
							},
						},
						axisLine: { show: false, lineStyle: { color: '#333' } },
						axisTick: {
							show: false,
						},
						nameTextStyle: {
							padding: [0, 0, 10, 10],
						},
					},
				],
				series: [
					{
						name: '臂长',
						type: 'bar',
						yAxisIndex: 0,
						barWidth: '16',
						label: {
							show: true,
							position: 'top',
						},
						data: edata.map(item => {
							return item.norms[this.RRX_select].json_value.leftValue
						}),
					},
					{
						name: '抬肩高度',
						type: 'bar',
						barWidth: '16',
						label: {
							show: true,
							position: 'top',
						},
						data: edata.map(item => {
							return item.norms[this.RRX_select].json_value.rightValue
						}),
					},
					{
						name: '抬肩分数',
						type: 'line',
						yAxisIndex: 1,
						data: edata.map(item => {
							return item.norms[this.RRX_select].json_value.overValue
						}),
						itemStyle: {
							normal: {
								lineStyle: {
									type: 'dashed',
								},
							},
						},
					},
				],
			}
			serum.setOption(optionS)
			this.$echartsResize(serum)
		},
	},
}
</script>

<style lang="scss" scoped>
.body_functionAdd_top {
	background: #fff;
	height: auto;
	padding: 10px 100px 30px 60px;
	border-radius: 4px;
}
// FMS
.FMS_content {
	background: #fff;
	height: auto;
	padding: 40px 100px 30px 50px;
	// margin-top: 10px;
	border-radius: 4px;
	.top_title {
		margin-left: 0;
	}
	.FMS_tableData {
		margin-top: 20px;
		/deep/ .el-table__row {
			.cell {
				padding-left: 5px;
				padding-right: 5px;
				.el-input {
					padding: 0;
				}
			}
		}
	}
	.FMS_echarts {
		display: flex;
		height: 400px;
		justify-content: space-between;
		margin-top: 40px;
		.f_e_left {
			width: 48%;
			position: relative;
			.fms_e_line {
				width: 100%;
				height: 100%;
			}
			.e_left_sele {
				position: absolute;
				top: 50px;
				right: 0%;
				z-index: 9999;
				.el-select:first-child {
					width: 120px;
					margin-right: 20px;
				}
				.el-select:last-child {
					width: 120px;
				}
			}
		}
		.f_ech_right {
			width: 40%;
			.fms_e_line {
				width: 100%;
				height: 100%;
			}
		}
	}
}

// -------------------
// MCS
.MCS_content {
	background: #fff;
	height: auto;
	padding: 40px 100px 15px 50px;
	// margin-top: 10px;
	border-radius: 4px;
	.top_title {
		margin-left: 0;
	}
	.YBT_tableData {
		margin-top: 20px;
		/deep/ .YBT_headerName {
			th {
				background: #f5f7fa;
			}
		}
		/deep/ .doubleDiffActive {
			.el-input__inner {
				background: rgba(247, 150, 69, 0.4);
			}
		}
		/deep/ .brotherDiffActive {
			.el-input__inner {
				border: 3px solid #1790ff;
			}
		}
		.s_doubleDiffActive {
			display: block;
			height: 34px;
			line-height: 34px;
			background: rgba(247, 150, 69, 0.4);
		}
		.s_brotherDiffActive {
			display: block;
			height: 34px;
			line-height: 34px;
			border: 3px solid #1790ff;
		}
	}
	.RRX_echarts {
		height: 400px;
		margin-top: 40px;
		position: relative;
		.rrx_e_line {
			width: 100%;
			height: 100%;
		}
		.e_left_sele {
			position: absolute;
			top: 50px;
			right: 0%;
			z-index: 9999;
			.el-select:first-child {
				width: 120px;
				margin-right: 20px;
			}
			.el-select:last-child {
				width: 120px;
			}
		}
	}
	.notes {
		height: 30px;
		line-height: 30px;
		font-size: 14px;
		display: flex;
		align-items: center;
		margin-top: 20px;
		padding-left: 15px;
		color: #626262;
		span:first-child {
			width: 30px;
			height: 20px;
			display: inline-block;
			background: rgba(247, 150, 69, 0.4);
			margin: 0 10px 0 15px;
		}
		span:last-child {
			width: 24px;
			height: 14px;
			display: inline-block;
			border: 3px solid #1790ff;
			margin: 0 10px 0 25px;
		}
	}
}
// -----------------
// YBT
.YBT_content {
	background: #fff;
	height: auto;
	padding: 40px 100px 30px 50px;
	// margin-top: 10px;
	border-radius: 4px;
	.top_title {
		margin-left: 0;
	}
	.YBT_tableData {
		margin-top: 20px;
		/deep/ .YBT_headerName {
			th {
				background: #f5f7fa;
			}
		}
	}
	.YBT_echarts {
		height: 400px;
		margin-top: 40px;
		position: relative;
		.ybt_e_line {
			width: 100%;
			height: 100%;
		}
		.e_left_sele {
			position: absolute;
			top: 50px;
			right: 0%;
			z-index: 9999;
			.el-select:first-child {
				width: 120px;
				margin-right: 20px;
			}
			.el-select:last-child {
				width: 120px;
			}
		}
	}
}
// ----------------
// SFMA
.SFMA_content {
	background: #fff;
	height: auto;
	padding: 30px 100px 30px 50px;
	// margin-top: 10px;
	border-radius: 4px;
	.SFMA_top {
		display: flex;
		justify-content: space-between;
		align-items: center;
		.t_title_left {
			display: flex;
			align-items: center;
			font-size: 18px;
			color: #333;
			font-weight: 500;
			font-family: PingFangSC-Medium, PingFang SC;
			span {
				display: inline-block;
				width: 6px;
				height: 20px;
				background: #0055e9;
				border-radius: 3px;
				margin-right: 10px;
			}
		}
		.t_title_right {
			span {
				padding: 10px 15px;
				display: inline-block;
				margin-left: 10px;
				border: 1px solid #ccc;
				border-radius: 5px;
				font-size: 14px;
				font-family: MicrosoftYaHei;
				&:hover {
					cursor: pointer;
				}
				&:nth-child(1) {
					border-color: #1790ff;
					color: #1790ff;
				}
				&:nth-child(2) {
					border-color: #4aca70;
					color: #4aca70;
				}
				&:nth-child(3) {
					border-color: #f79645;
					color: #f79645;
				}
				&:nth-child(4) {
					border-color: #ec4c4c;
					color: #ec4c4c;
				}
			}
		}
	}
	.top_title {
		margin-left: 0;
	}
	.SFMA_tableData {
		margin-top: 20px;
		/deep/ .YBT_headerName {
			th {
				background: #f5f7fa;
			}
		}
	}
}
// -------------------
// 柔韧性
.RRX_content {
	background: #fff;
	height: auto;
	padding: 40px 100px 30px 50px;
	// margin-top: 10px;
	border-radius: 4px;
	.top_title {
		margin-left: 0;
	}

	.YBT_down_tips {
		text-align: right;
		color: #666;
		font-size: 16px;
		height: 20px;
		display: flex;
		align-items: center;
		justify-content: end;
		i {
			font-size: 20px;
		}
		span {
			display: inline-block;
			width: 18px;
			height: 14px;
			background: #80e386;
			margin-left: 20px;
			margin-right: 5px;
			&:last-child {
				background: #ffcb00;
			}
		}
	}

	.RRX_tableData {
		margin-top: 20px;
	}
	.RRX_echarts {
		height: 400px;
		margin-top: 40px;
		position: relative;
		.rrx_e_line {
			width: 100%;
			height: 100%;
		}
		.e_left_sele {
			position: absolute;
			top: 50px;
			right: 8%;
			z-index: 9999;
			.el-select:first-child {
				width: 120px;
				margin-right: 20px;
			}
			.el-select:last-child {
				width: 120px;
			}
		}
	}
	.YBT_echartsContent {
		display: flex;
		flex-wrap: wrap;
		div {
			width: 48.7%;
			height: 350px;
			margin-top: 20px;
			border: 1px solid #ccc;
			&:nth-child(2n) {
				margin-left: 30px;
			}
		}
	}
	#YBT_downLookEcharts,
	#YBT_upLookEcharts {
		width: 100%;
		height: 400px;
		margin-top: 20px;
		border: 1px solid #ccc;
	}
	.YBT_downLookContent {
		display: flex;
		flex-wrap: wrap;
		div {
			width: 32.1%;
			height: 350px;
			margin-top: 20px;
			border: 1px solid #ccc;
			margin-right: 21px;
			&:nth-child(3n) {
				margin-right: 0px;
			}
		}
	}
}
// ------------------
// 关节角度
.GJ_content {
	background: #fff;
	height: auto;
	padding: 40px 100px 30px 50px;
	// margin-top: 10px;
	border-radius: 4px;
	.top_title {
		margin-left: 0;
	}
	.GJ_tableData {
		margin-top: 20px;
	}
}

.XGJ_content {
	background: #fff;
	height: auto;
	padding: 40px 100px 30px 50px;
	// margin-top: 10px;
	border-radius: 4px;
	.top_title {
		margin-left: 0;
	}
	.GJ_tableData {
		margin-top: 20px;
	}
	/deep/ .xgj_header {
		background: #f5f7fa;
	}
}
// ------------------
// 测试评价
.test_content {
	background: #fff;
	height: auto;
	padding: 40px 100px 30px 50px;
	margin-top: 10px;
	border-radius: 4px;
	.top_title {
		margin-left: 0;
	}
	.test_editor {
		width: 100%;
		margin-top: 40px;
		height: 400px;
	}
	.look_editor {
		overflow: auto;
		border: 1px solid #f5f7fa;
		border-radius: 5px;
		padding: 20px;
		box-sizing: border-box;
		/* table 样式 */
		table {
			border-top: 1px solid #ccc;
			border-left: 1px solid #ccc;
		}
		table td,
		table th {
			border-bottom: 1px solid #ccc;
			border-right: 1px solid #ccc;
			padding: 3px 5px;
		}
		table th {
			border-bottom: 2px solid #ccc;
			text-align: center;
		}

		/* blockquote 样式 */
		blockquote {
			display: block;
			border-left: 8px solid #d0e5f2;
			padding: 5px 10px;
			margin: 10px 0;
			line-height: 1.4;
			font-size: 100%;
			background-color: #f1f1f1;
		}

		/* code 样式 */
		code {
			display: inline-block;
			*display: inline;
			*zoom: 1;
			background-color: #f1f1f1;
			border-radius: 3px;
			padding: 3px 5px;
			margin: 0 3px;
		}
		pre code {
			display: block;
		}

		/* ul ol 样式 */
		ul,
		ol {
			margin: 10px 0 10px 20px;
		}
	}
}
// -----------------
.FN_name {
	background: #1790ff;
	color: #fff !important;
}
.FP_name {
	background: #4aca70;
	color: #fff !important;
}
.DN_name {
	background: #f79645;
	color: #fff !important;
}
.DP_name {
	background: #ec4c4c;
	color: #fff !important;
}
.FN_select_name {
	/deep/ .el-input__inner {
		background: #1790ff;
		color: #fff;
	}
}
.FP_select_name {
	/deep/ .el-input__inner {
		background: #4aca70;
		color: #fff;
	}
}
.DN_select_name {
	/deep/ .el-input__inner {
		background: #f79645;
		color: #fff;
	}
}
.DP_select_name {
	/deep/ .el-input__inner {
		background: #ec4c4c;
		color: #fff;
	}
}
.child-top {
	align-items: flex-end;
	margin-bottom: 0;
}
.operation-button {
	.fanhui {
		font-size: 16px;
		color: #666;
		display: inline-block;
		padding-top: 5px;
		margin-left: 40px;
		margin-right: 20px;
		i {
			margin-right: 5px;
			color: #000;
		}
		&:hover {
			cursor: pointer;
		}
	}
}
.top_title {
	display: flex;
	align-items: center;
	font-size: 18px;
	color: #333;
	font-weight: 500;
	font-family: PingFangSC-Medium, PingFang SC;
	margin-left: 30px;
	span {
		display: inline-block;
		width: 6px;
		height: 20px;
		background: #0055e9;
		border-radius: 3px;
		margin-right: 10px;
	}
}
.demo_ruleForm {
	.item_flex {
		display: flex;
		justify-content: space-between;
		.el-form-item {
			width: 45%;
		}
	}
	.look_opp {
		border-bottom: 2px solid #cccccc;
		color: #666;
		padding-left: 10px;
		min-height: 40px;
		line-height: 40px;
	}
}
.addressForm_dialog {
	padding: 0 20px 50px;
}
.form-bottom-button {
	padding-bottom: 30px;
}
/deep/ .bottom_btn {
	.el-input .el-input__inner {
		border-radius: 0;
		border-right-width: 0;
		border: none;
		border-bottom: 2px solid #ccc;
	}

	.el-input .el-input__inner:focus {
		border-color: #0055e9;
	}

	.el-select {
		float: left;
	}

	.el-select .el-input__inner {
		border-right-width: 0;
		border: none;
		border-radius: 0;
		border-bottom: 2px solid #ccc;
	}

	.el-select .el-input__inner:focus {
		border-color: #0055e9;
	}

	.el-select .el-input__inner:focus {
		border-right-width: 1px;
	}

	.bot_content {
		p {
			display: flex;
			align-items: center;
			width: 100%;

			i {
				font-size: 22px;
			}

			span {
				font-size: 18px;
			}
		}
	}
}
.text_demo {
	width: 99.4%;
	min-height: 35px;
	border-bottom: 2px solid #ccc;
	padding-left: 5px;
	span {
		padding: 5px 3px 5px 10px;
		border-radius: 5px;
		background-color: #f4f4f5;
		border-color: #e9e9eb;
		color: #909399;
		font-size: 14px;
		margin-right: 7px;
		margin-bottom: 3px;
		i {
			border-radius: 50%;
			background-color: #c0c4cc;
			font-size: 14px;
			color: #909399;
			margin-left: 5px;
			&:hover {
				color: #fff;
				cursor: pointer;
			}
		}
	}
	p {
		padding-left: 10px;
		color: #c2c2c2;
		font-size: 14px;
	}
}
.address_del {
	&:hover {
		color: #f00;
		cursor: pointer;
	}
}
.ModifyDoalog {
	display: flex;
	height: 400px;
	padding: 0 30px;
	justify-content: space-between;
	margin-bottom: 10px;
	.textDialog_left {
		width: 45%;
		border: 1px solid #e3e3e3;
		overflow: hidden;
		.textDialog_left_input {
			padding: 10px 20px;
		}
		h2 {
			height: 50px;
			line-height: 50px;
			background: #f5f5f5;
			border-radius: 2px 2px 0px 0px;
			border: 1px solid #e3e3e3;
			border-left: none;
			border-right: none;
			font-size: 16px;
			font-weight: 400;
			text-align: center;
			color: #333;
			margin-top: 5px;
		}
		.tree_demo {
			height: 280px;
			overflow-y: auto;
			padding: 20px;
			box-sizing: border-box;
			.el-tree {
				font-size: 16px;
				/deep/ .el-tree-node {
					margin: 5px 0;
					.el-checkbox {
						margin-right: 10px;
						.is-checked {
							.el-checkbox__inner {
								background-color: #0055e9;
								border-color: #0055e9;
							}
						}
						.is-indeterminate {
							.el-checkbox__inner {
								background-color: #0055e9;
								border-color: #0055e9;
							}
						}
					}
					.el-tree-node__label {
						color: #333;
					}
				}
			}
		}
	}
	.textDialog_right {
		width: 45%;
		border: 1px solid #e3e3e3;
		p {
			height: 50px;
			line-height: 50px;
			background: #f5f5f5;
			border-radius: 2px 2px 0px 0px;
			border-bottom: 1px solid #e3e3e3;
			font-size: 16px;
			font-weight: 400;
			text-align: center;
			color: #333;
			position: relative;
			span {
				position: absolute;
				right: 20px;
				font-size: 14px;
				font-family: MicrosoftYaHei;
				color: #999999;
				&:hover {
					cursor: pointer;
				}
			}
		}
		.tree_demo {
			height: 345px;
			overflow-y: auto;
			padding: 20px;
			box-sizing: border-box;
			.el-tree {
				font-size: 16px;
				/deep/ .el-tree-node {
					margin: 5px 0;
					.el-checkbox {
						margin-right: 10px;
						.is-checked {
							.el-checkbox__inner {
								background-color: #0055e9;
								border-color: #0055e9;
							}
						}
						.is-indeterminate {
							.el-checkbox__inner {
								background-color: #0055e9;
								border-color: #0055e9;
							}
						}
					}
					.el-tree-node__label {
						color: #333;
					}
				}

				.custom-tree-node {
					flex: 1;
					display: flex;
					align-items: center;
					justify-content: space-between;
					font-size: 14px;
					padding-right: 8px;
					i {
						&:hover {
							color: #f00;
						}
					}
				}
			}
		}
	}
}
.bz_content {
	margin-bottom: 40px;
	padding-left: 30px;
	line-height: 30px;
	color: #f00;
}

.text_cname {
	margin-top: 20px;
	/deep/ .el-tabs__header {
		padding: 20px 50px 0;
		background: #fff;
		margin: 0;
	}
}

.YBT_tableDataLook {
	/deep/ tbody > tr > td {
		padding: 0 !important;
	}
	/deep/ .cell {
		padding: 0 !important;
	}
	.YBTValueContent {
		padding: 10px 8px;
	}
}
</style>

<style scoped>
.el-input {
	margin-bottom: 1px;
	box-sizing: border-box;
}
/* .el-table__cell{
  overflow: hidden;
} */
/* table 样式 */
table {
	border-top: 1px solid #ccc;
	border-left: 1px solid #ccc;
}
table td,
table th {
	border-bottom: 1px solid #ccc;
	border-right: 1px solid #ccc;
	padding: 3px 5px;
}
table th {
	border-bottom: 2px solid #ccc;
	text-align: center;
}

/* blockquote 样式 */
blockquote {
	display: block;
	border-left: 8px solid #d0e5f2;
	padding: 5px 10px;
	margin: 10px 0;
	line-height: 1.4;
	font-size: 100%;
	background-color: #f1f1f1;
}

/* code 样式 */
code {
	display: inline-block;
	*display: inline;
	*zoom: 1;
	background-color: #f1f1f1;
	border-radius: 3px;
	padding: 3px 5px;
	margin: 0 3px;
}
pre code {
	display: block;
}

/* ul ol 样式 */
ul,
ol {
	margin: 10px 0 10px 20px;
}
</style>
